@import "../variables";

.top-banner_how-it-works {
  background-image: url("../../../assets/img/top-banner-renoideas.jpg");
  .feedback {
    margin: 3rem auto 0;
    background-color: rgba($white, .9);
    font-size: 1.05rem;
    img {
      width: 35px;
      vertical-align: bottom;
    }
  }
}

.how-it-works {
  &_mobile-blocks {
    display: none;
    .mobile-block {
      display: none;
    }
  }
  .mobile-content {
    display: none;
  }

  .step-block {
    h3:not(.title) {
      margin: 2rem 0 1.5rem;
      color: $green;
      text-align: center;
    }
    .gray-block {
      padding: 1.5rem 2rem;
      background-color: lighten($gray, 10%);
      border: 1px solid $green;
    }
    .list {
      margin: 0;
      padding: 0 0 0 1rem;
      list-style: disc;
      color: $gray-black;
      font-size: 1.3rem;
      li {
        margin-bottom: .3rem;
      }
    }
    .content {
      margin-top: 2rem;
      pointer-events: none;
      cursor: default;
    }
    &.step-define {
      .content {
        display: flex;
        flex-direction: row;
        .step {
          display: flex;
          flex-direction: column;
          width: 35%;
          margin: 0 1%;
          h6 {
            margin-bottom: 1rem;
            font-size: 1.2rem;
            line-height: 1.4;
            font-weight: $F-regular;
            text-align: center;
          }
          &-first {
            width: 24%;
            .wrap {
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              padding: .7rem;
              background-color: lighten($blue-cream, 4%);
            }
            .btn-like-dropdown {
              margin-bottom: 1rem;
              background-color: $white;
              font-size: 1.1rem;
              border-width: 2px;
              &:hover {
                opacity: 1;
              }
            }
            .work-details {
              .wrap-form-check:last-of-type {
                margin-bottom: 0;
              }
              .form-check {
                min-height: 42px;
                padding-left: .4rem;
                padding-right: .4rem;
                label {
                  width: 100%;
                  img {
                    width: 30px;
                  }
                }
                .btn {
                  min-width: 50px;
                  padding-left: .3rem;
                  padding-right: .3rem;
                  font-size: .9rem;
                }
                &.active {
                  label {
                    width: calc(100% - 65px);
                  }
                }
              }
            }
          }
          &-second,
          &-third {
            display: flex;
            flex-direction: column;
          }
          .edit-block {
            display: flex;
            flex-grow: 1;
            padding-top: 0;
            .list-block {
              margin-bottom: 0;
              background-color: $white;
              border: 1px solid $gray;
              h6 {
                margin-bottom: 0;
                padding: .2rem 0;
                background-color: $yellow-dark;
                font-size: 1.1rem;
                font-weight: $F-bold;
              }
              ul {
                padding: 0 1rem;
                list-style: none;
                li {
                  margin-bottom: 0;
                  padding: .6rem 0;
                  font-size: .9rem;
                  line-height: 1.3;
                  border-bottom: 1px dotted $gray;
                  &:last-of-type {
                    border-bottom: none;
                  }
                  .quantity {
                    min-width: 70px;
                    padding-left: 1rem;
                  }
                }
              }
              .option-labels {
                margin: 1px 0;
                .label {
                  font-size: .8rem;
                }
              }
            }
          }
          [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
            margin-top: .7rem;
            &:before {
              border: 1px solid $black;
            }
            &:after {
              height: 16px;
              width: 16px;
              left: 4px;
              top: -2px;
              background-color: $black;
            }
          }
          &-third {
            .edit-block .list-block ul li {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              .quantity {
                display: inline-block;
                margin: 1.3rem 0;
                padding: 0;
                span {
                  margin-right: .2rem;
                  padding: .2rem 1rem;
                  font-size: 1.1em;
                  border: 1px solid darken($gray, 8%);
                }
              }
            }
          }
        }
      }
    }
    &.step-compare {
      .info.info-new .info-part {
        margin-bottom: 1rem;
      }
    }
    &.step-offers {
      table {
        background-color: $white;
        thead {
          th {
            .wrap-links {
              display: flex;
              flex-direction: row;
              a {
                display: flex;
                flex-direction: row;
                align-items: center;
                img {
                  margin-right: .5rem;
                }
              }
            }
          }
        }
      }
      .download-links .download-link:first-of-type {
        margin-right: 1rem;
      }
    }
  }
}

.faq-block {
  margin: 2.5rem 0 5rem;
  .questions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    .wrap-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      .wrap-img {
        img {
          width: 110px;
        }
      }
      ul {
        margin-bottom: 0;
        padding-left: 0;
        li {
          margin-bottom: .3rem;
          a {
            color: $gray-black;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  .wrap-img {
    width: 200px;
    flex: 0 0 200px;
    padding: 0 2.5rem;
    text-align: right;

  }
  .feedback-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    .wrap-img {
      img {
        width: 70px;
        margin-right: .5rem;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 1.3rem;
      line-height: 1.3;
      small {
        padding-left: .5rem;
        font-size: 70%;
      }
    }

  }
}

.faq-block .btn,
.see-examples .btn {
  padding-top: .4rem;
  padding-bottom: .4rem;
  font-weight: $F-regular;
  line-height: 1.3;
  border-radius: 3rem;
}

@media (max-width: 1199px) {

  .how-it-works {
    .step-block.step-define .content {
      .step {
        h6 {
          font-size: 1rem;
        }
      }
      .step-first .work-details .form-check label img {
        width: 26px;
        min-width: 26px;
        height: 22px;
      }
    }
  }
}

@media (min-width: 992px) {
  .how-it-works:lang(it) {
    .prov-details .tabs-menu .tab-title {
      font-size: 15px;
    }
    .info.info-new .characteristic .tile-title .wrap-line h5 {
      font-size: 1rem;
    }
  }
}

@media (max-width: 991px) {

  .top-banner_how-it-works .feedback {
    margin-top: 2rem;
  }

  .how-it-works {
    .wrap-link {
      color: $green;
      font-weight: $F-bold;
      text-align: center;
      a {
        color: $green;
      }
    }

    .step-block {
      h3:not(.title) {
        margin: 1rem 0;
      }
      .list {
        font-size: 1.1rem;
      }
      &.step-define {
        .content {
          flex-direction: column;
          .step {
            width: 70%;
            margin: .7rem auto;
            h6 {
              margin-bottom: .5rem;
            }
            &-first {
              .work-details .form-check label {
                font-size: 1rem !important;
                img {
                  margin: 0 .6rem 0 .1rem;
                }
              }
              .btn-like-dropdown,
              .work-details {
                width: 80%;
                margin: .5rem auto;
              }
            }
          }
        }
      }
    }
  }

  .faq-block {
    margin-bottom: 4rem;
    .questions {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      .wrap-links {
        margin-bottom: .5rem;
        ul {
          text-align: left;
          li a {
            font-size: 1.2rem;
          }
        }
        .wrap-img img {
          width: 85px;
        }
      }
    }
    .wrap-img {
      width: 170px;
      flex: 0 0 170px;
    }
    .feedback-line {
      p small {
        display: block;
        padding: .1rem 0 0;
      }
      .wrap-img img {
        width: 55px;
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 767px) {

  .how-it-works .step-block {
    &.step-define .content .step {
      width: 90%;
    }
  }

  .faq-block {
    .questions {
      .wrap-links {
        align-items: flex-start;
        ul li a {
          font-size: 1.1rem;
        }
        .wrap-img img {
          width: 60px;
        }
      }
      .btn {
        font-weight: $F-bold;
      }
    }
    .wrap-img {
      width: 120px;
      flex: 0 0 120px;
      padding: 0 2rem 0 1.5rem;
    }
    .feedback-line {
      .wrap-img img {
        width: 40px;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
}

@media (max-width: 575px) {

  .how-it-works {
    .mobile-content {
      display: block;
      .wrap-btn {
        text-align: left;
        margin-left: 1rem;
      }
    }

    .step-block {
      .gray-block {
        margin-bottom: 3rem;
        padding: 1.5rem;
      }
      h3 {
        font-size: 1.2rem;
      }
      .list {
        font-size: 1rem;
      }
    }
  }

  .faq-block {
    margin: 1.5rem 0 0;
    .feedback-line {
      .wrap-img img {
        width: 28px;
      }
      p {
        font-size: 1rem;
      }
    }
    .wrap-btn {
      margin-top: .7rem;
    }
    .questions {
      margin-bottom: 1.2rem;
      .wrap-links {
        .wrap-img img {
          width: 35px;
        }
        ul li a {
          font-size: .9rem;
        }
      }
    }
  }

  .how-it-works_general .step-block .content {
    display: none !important;
  }

  .see-examples {
    display: flex;
    flex-direction: row;
    margin: 1.5rem 0 2.5rem;
    align-items: center;
    .wrap-img img {
      width: 38px;
    }
  }

  .faq-block .wrap-img,
  .see-examples .wrap-img {
    width: 90px;
    flex: 0 0 90px;
    padding: 0 2rem 0 1rem;
    text-align: right;
  }

  .faq-block .btn,
  .see-examples .btn {
    min-width: 160px;
    padding-top: .3rem;
    padding-bottom: .3rem;
  }

  .how-it-works_mobile-blocks {
    .step-block .content {
      margin-top: 0;
    }
    .mobile-block {
      pointer-events: none;
      cursor: default;
      &.first-block {
        padding: .1rem 0 1rem;
        background-color: $blue-gray-light;
      }
      &.second-block {
        .prov-details .info .characteristic .tile {
          max-width: 210px;
        }
      }
    }

    .step-block.step-define .content {
      .step {
        width: 100%;
        margin-bottom: 0;
        h6 {
          margin: 1rem 0;
          font-weight: $F-bold;
        }
        .edit-block .list-block h6 {
          margin-top: 0;
        }
        &.step-first {
          h6 {
            margin: .7rem 0;
          }
          .wrap {
            width: 300px;
            margin: 0 auto;
            padding: 0;
            background-color: transparent;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {

  .faq-block .wrap-img, .see-examples .wrap-img {
    width: 60px;
    flex: 0 0 60px;
    padding: 0 1rem 0 0;
  }
}
