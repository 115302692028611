import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { L10nService } from './l10n/l10n.service';

@Injectable()
export class RenoRouter extends Router {
    private lang: string;
    constructor(private l10nService: L10nService) {
        super();
        this.lang = l10nService.getCurrentLang();
    }

    public gotoOfferDetails(providerId: string): void {
        super.navigateByUrl(`/${this.lang}/offer/${providerId}`);
    }

    public override navigateByUrl(url: string): Promise<boolean> {
        console.log('RenoRouter.navigateByUrl to ' + url);
        return super.navigateByUrl(this.addLang(url));
    }

    public override navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        if (commands && commands.length > 0) {
            commands[0] = this.addLang(commands[0]);
        }
        console.log('RenoRouter.navigate to:' + commands);
        console.log(commands);
        console.log(extras);
        return super.navigate(commands, extras);
    }

    private addLang(url: any) :any {
        if (!url || typeof url !== 'string') {
            return url;
        }
        if (url.startsWith('/' + this.lang)) {
            return url;
        }
        return `${this.lang}${url.startsWith('/') ? '' : '/'}${url}`;
    }
}