@import "variables";

$F_light: 300;
$F_regular: 400;
$F_medium: 500;
$F_bold: 700;
$F_black: 900;


@media (max-width: 1199px) {

  .title-green.top {
    font-size: 1.8rem;
    &.font-small {
      font-size: 1.1rem;
    }
  }

  /* Header */

  header #navbarNav .links li {
    margin: 0 0.3rem;
  }

  /* -Header */

  /* Article, Search */

  .general-slider {
    .wrap-slider {
      margin: 0 .3rem 2rem;
    }
  }

  /* -Article, Search */

  /* Faq, How it works */

  .steps-line {
    .step {
      padding: .35rem 1.3rem;
    }
    .step-active {
      padding: .4rem 1.5rem;
      font-size: 1.4rem;
    }
    .btn {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      font-size: 1.3rem;
    }
  }

  /* -Faq, How it works */

  /* How it works, Workarea select */

  .work-details {
    .wrap-form-check .form-check {
      padding: .3rem;
      &.main {
        label {
          width: calc(100% - 65px);
        }
      }
      label {
        font-size: .85rem !important;
        img {
          width: 28px;
          min-width: 28px;
          max-height: 26px;
          margin: 0 .5rem 0 0;
        }
      }
      .btn {
        min-width: 60px;
        padding-left: .3rem;
        padding-right: .3rem;
        font-size: .9rem;
      }
    }

    .card.card-block {
      p {
        font-size: .75rem;
      }
    }
  }

  /* -How it works, Workarea select */

  /* Workarea select */

  .sqm- {
    &block {
      padding: 0;
    }
    &form {
      #sqm-input {
        height: 45px;
      }
      span {
        top: 10px;
      }
    }
    &values {
      div a {
        height: 45px;
        width: 45px;
      }
    }
  }

  /* -Workarea select */

  /* Search result, Join */

  .search-result-list {
    .item {
      &-photo {
        width: 45%;
      }
    }


    .item {
      &-stars {
        padding-left: .6rem;
        h6 {
          font-size: .8rem;
        }
      }
    }
  }

  /* -Search result, Join */
}

@media (max-width: 991px) {

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.7rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  .top-banner .top-part {
    padding: 2rem 0;
  }

  .title-green.top {
    padding: 1rem;
    font-size: 1.5rem;
    &.font-small {
      font-size: 1rem;
      &.feedback-title {
        min-height: 75px;
      }
    }
  }

  /* Header */

  header {
    .navbar {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      min-height: 52px;
      padding: 0;
      &.open {
        padding-bottom: 1rem;
      }
      &-toggler {
        margin: 0.5rem 0;
        padding: 0.3rem 0.5rem;
        cursor: pointer;
        color: $white;
        font-size: 1.5rem;
        &:focus {
          outline: none;
        }
        &:not(.collapsed) {
          border-color: transparent;
        }
      }
      &-brand {
        img {
          height: 32px;
        }
      }
      .page_links {
        position: absolute;
        left: 190px;
      }
      .links {
        padding-left: 0;
      }
      .nav-link {
        color: $white;
      }
      .icon {
        position: absolute;
        right: 3.2rem;
      }
      #navbarNav {
        z-index: 3;
        background: $blue-gray-light;
        h6 {
          margin: 1rem 0 .7rem;
          padding-left: 15px;
          color: $blue-gray-dark;
          font-size: 0.9rem;
          text-transform: uppercase;
        }
        .bottom-gray-line {
          margin: 0 0 1px;
          background: $white;
          box-shadow: 0 2px 4px 0 rgba($gray-dark, 0.2);
        }
        .nav-item {
          @extend .bottom-gray-line;
          &:last-of-type {
            margin-bottom: 0;
          }
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0.8rem 25px;
            color: $black;
            text-decoration: none;
          }
          &.account {
            .dropdown {
              width: 100%;
              .dropdown-toggle {
                display: none;
              }
              .dropdown-menu {
                display: block;
                z-index: 0;
                width: 100%;
                margin: 0;
                padding: 0;
                border: none;
                border-radius: 0;
                .dropdown-item {
                  @extend .bottom-gray-line;
                }
              }
            }
          }
          .btn {
            background: $white;
            font-weight: $F_regular;
            line-height: 1.15;
            box-shadow: none;
          }
        }
      }
      &.open {
        .navbar-brand {
          display: none;
        }
        .mobile-menu-title {
          display: inline-block;
        }
        .navbar-toggler i.fa:before {
          content: "\f053";
        }
      }
    }
  }

  /* -Header */

  /* Footer */

  footer {
    .top-part {
      padding: 2rem 0;
      .nav {
        flex-direction: column;
        .nav-item {
          text-align: center;
          .nav-link {
            display: inline-block;
            font-size: 1.2rem;
          }
          &:first-of-type {
            .nav-link {
              padding-left: 1.5em;
            }
          }
        }
      }
    }
  }

  /* -Footer */

  /* Join, Search */

  .features {
    padding: 1rem 0;
    .feature {
      padding: 1rem 15px;
      .wrap {
        height: auto;
        max-width: 350px;
        margin: 0 auto;
        &-text {
          justify-content: center;
        }
      }
    }
  }

  /* -Join, Search */

  /* Faq, How it works */

  .steps-line {
    justify-content: center;
    margin: 1.5rem auto 1rem;
    .step {
      display: none;
      &-active {
        display: inline-block;
        min-width: 280px;
        padding: .3rem 1.5rem;
        text-align: center;
      }
    }
    .btn {
      display: none;
    }
  }

  /* -Faq, How it works */

  /* How it works, Provider details, Search result */

  .edit-block {
    .actions {
      flex-direction: column;
      margin-top: 1rem;
      .btn {
        margin: .6rem 0;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
        &:last-of-type {
          margin: .6rem 0 .4rem;
        }
      }
      span {
        padding: 0;
        text-align: center;
      }
    }
  }

  /* -How it works, Provider details, Search result */

  /* Comparison table, How it works, (.table-comparison) for feedback-project-status too */

  .download-links .download-link {
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-right: 0;
    }
    span {
      padding-left: .7rem;
    }
  }

  .download-links-mobile {
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
    .download-link {
      padding: 0 .5rem;
    }
  }

  .table-comparison {
    .download-links {
      width: 30%;
    }
  }

  .table-comparison-mobile {
    font-size: 0.9rem;
    tr > *:nth-child(2) {
      display: none;
    }
    td, th {
      padding: .3rem;
    }
    td b {
      font-size: 1.1em;
    }
    .download-links,
    .description {
      width: 40%;
      padding-left: .5rem;
    }
    .download-links {
      .download-link {
        display: none;
      }
    }
    .company-names {
      margin: 0;
      padding: .5rem;
      color: $blue-dark;
      font-weight: $F-bold;
      li {
        margin-bottom: .1rem;
      }
      a {
        color: $blue-dark;
      }
    }

    .number-in-circle {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: .1rem;
      background: $white;
      font-size: 17px;
      line-height: 17px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $blue-dark;
      letter-spacing: -.5px;
    }

    .table-flex {
      .item {
        margin-left: .5rem;
        padding: .1rem 0 .2rem .5rem;
        background-image: linear-gradient(to right, #111a2f 20%, #fff 10%);
        background-position: bottom;
        background-size: 6px 1px;
        background-repeat: repeat-x;
        &:last-of-type {
          background: none;
        }
      }
      .table-row {
        display: flex;
        margin: .3rem 0;
        &.flesh-row {
          padding: .3rem 1rem;
        }
        &.description {
          width: 100%;
          padding-left: 0;
          padding-right: .3rem;
        }
        &.row-detailed-prices {
          align-items: center;
          b {
            font-size: 1.1em;
          }
        }
      }
      .table-cell {
        width: 24%;
        text-align: center;
        &:first-of-type {
          width: 28%;
        }
        &.empty-first-cell {
          margin-left: 1rem;
        }
        &.type {
          padding-bottom: .5rem;
          text-align: left;
          h6 {
            margin-bottom: .2rem;
          }
        }
        .price {
          display: inline-block;
          margin-top: .5rem;
        }
        .number-in-circle {
          margin-right: 0;
        }
      }
    }
  }

  /* -Comparison table, How it works, (.table-comparison) for feedback-project-status too */

  /* How it works, Workarea select */

  .work-details {
    .wrap-form-check .form-check {
      label {
        font-size: .75rem !important;
        img {
          width: 24px;
          min-width: 24px;
          max-height: 21px;
          margin: 0 .5rem 0 0;
        }
      }
      .btn {
        font-size: .85rem;
      }
    }

    .card.card-block {
      p {
        font-size: .7rem;
      }
    }
  }

  /* -How it works, Workarea select */

  /* Search result, Join */

  .search-result-list {
    .item {
      .paddings {
        grid-template-columns: 166px 1fr;
        grid-template-rows: 166px auto auto;
        background: linear-gradient(to top, #f6f7f8 80%, $white);
      }
      &-envelope {
        grid-row: 1/1;
        grid-column: 1/1;
        height: auto;
        margin-top: 0;
      }
      &-gallery {
        grid-column: 1/3;
        grid-row: 3/3;
        width: calc(100% + 1rem);
        height: 220px;
        margin-left: -.5rem;
        border: none;
      }


      &-stars {
        grid-column: 1/3;
        grid-row: 2/2;
        flex-direction: row !important;
        justify-content: space-evenly;
        .item {
          &-prices,
          &-reviews,
          &-services {
            margin: .6rem 0;
            line-height: 1;
            text-align: center;
          }
        }
        h6 {
          font-size: .9rem;
          line-height: 1;
        }
      }
      &-provider {
        grid-column: 2/2;
        grid-row: 1/1;
        z-index: 3;
        margin-left: .8rem;
      }
    }
  }

  /* -Search result, Join */

}

@media (max-width: 767px) {

  h1 {
    font-size: 2.3rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  .icon-info.medium {
    width: 16px;
    height: 16px;
  }

  .title-green,
  .title-flesh {
    margin: 1rem;
    font-size: .9rem;
    .subtitle {
      font-size: 1.1em;
    }
  }

  /* GPDR-fixed */

  .gpdr-fixed {
    background: linear-gradient(to bottom, rgba(238, 239, 243, .8), #eeeff3);
    .container {
      position: relative;
      flex-direction: column-reverse;
      padding-top: .9rem;
      padding-bottom: .9rem;
    }
    .text {
      p {
        padding-right: 0;
        font-size: .7rem;
        line-height: 1.4;
        text-align: justify;
      }
    }
    .actions {
      position: relative;
      justify-content: center;
      margin-bottom: .7rem;
      a {
        text-decoration: none;
        &.btn {
          flex-grow: 0;
        }
        &.close {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          margin-top: 0;
          font-size: 1.1rem;
        }
      }
    }
  }

  /* -GPDR-fixed */

  /* check */
  .wrap-bg {
    padding: .1px 0;
    background: $white;
    & > .container {
      padding: 0;
    }
  }

  /* Header */

  header .navbar {
    min-height: 48px;
    .navbar-toggler {
      margin: 6px .5rem;
    }
    &.open {
      .back-arrow {
        display: none !important;
      }
      #navbarNav .nav-item.join {
        margin: 1.2rem 0 0;
      }
    }
  }

  /* -Header */

  /* Footer */

  footer {
    .top-part .nav .nav-item .nav-link {
      padding: .4rem 1.5rem;
    }
    .bottom-part {
      .bottom-nav-column {
        width: 50%;
        padding-right: .7rem;
        &:nth-of-type(2) {
          display: none;
        }
        &:last-of-type {
          padding-right: .7rem;
        }
        &-list {
          li {
            padding-bottom: 4px;
          }
        }
      }
    }
  }

  /* -Footer */

  /* Join, Search */

  .features {
    .feature {
      padding: .5rem 15px;
      .wrap-img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 120px;
        }
      }
      .wrap-text {
        h5 {
          margin-bottom: .8rem;
        }
      }
    }
  }

  /* -Join, Search */

  /* Search result, Join */

  .search-result-list {
    margin-top: .5rem;
  }

  /* -Search result, Join */

  /* Article, Search */

  .general-slider {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    .wrap-slider {
      margin-bottom: 1.5rem;
    }
    .wrap {
      background: $white;
    }
    .top-part,
    .top-part.over-content {
      a {
        font-size: 1.1rem;
      }
      .price {
        font-size: 1.3rem;
      }

    }
    .ngx-gallery-layout {
      float: none !important;
    }
    .bottom-part {
      display: none;
    }
  }

  /* -Article, Search */

  /* How it works, Provider details, Search result */

  .edit-block {
    .add-line {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  /* -How it works, Provider details, Search result */

  /* How it works, Workarea select */

  .work-details {
    .wrap-form-check .form-check {
      label {
        font-size: 1.1rem !important;
        img {
          width: 27px;
          min-width: 27px;
          max-height: 23px;
        }
      }
    }

    .card.card-block {
      p {
        font-size: .9rem;
      }
    }
  }

  /* -How it works, Workarea select */

  /* Workarea select */

  .sqm-block {
    padding: 0 0.5rem;
    .sqm-form #sqm-input {
      height: 50px;
      span {
        top: 13px;
      }
    }
    .sqm-values div a {
      height: 50px;
      width: 50px;
    }
  }

  /* -Workarea select */

  /* Join, Feedback provider */

  .wrap-back-link {
    font-size: .8rem;
    text-align: center;
    a {
      color: $blue-link !important;
    }
  }

  /* Join, Feedback provider */

  /* Search result, Provider details */

  .provider-docs {
    .modal-content {
      padding: 0;
      .container {
        padding: 0 15px;
      }
      .modal-body {
        margin-top: 15px;
      }
    }
  }

  .offices-docs {
    .services-row .service-row.free:before {
      left: 0;
      top: 40px;
    }
  }

  /* -Search result, Provider details */

  /* Offer preview */

  .offer-preview {
    .block-green {
      padding-left: .5rem;
      padding-right: .5rem;
      background-color: $white;
      color: $green;
    }
    .mail-part,
    .table-part {
      margin: 1rem 0;
    }
    .mail-part {
      padding: 0 1rem;
    }
    .table-part {
      padding: 0 .2rem;
      h4 {
        padding-left: 1rem;
      }
    }
  }

  .table-offer {
    margin-top: 0;
    .Ftable-header {
      text-align: right;
      span {
        display: inline;
      }
    }
    .Ftable-row {
      flex-wrap: wrap;
      padding-left: 1rem;
      &:before {
        top: -3px;
        left: 1rem;
        width: calc(100% - 1rem);
      }
    }
    .Ftable-cell {
      &:first-of-type {
        width: calc(100% - 155px);
      }
      &.price {
        width: 155px;
      }
    }
    .price {
      .red {
        font-size: .9em;
      }
    }
    .description {
      h6 {
        margin-bottom: .2rem;
      }
      span {
        display: none;
      }
      .text-badge {
        display: inline;
      }
    }
    .description-mobile {
      display: block;
      width: 100% !important;
      padding-left: 0;
      p {
        margin-bottom: .7rem;
      }
    }
    .Ftable-cell:nth-of-type(2) {
      display: none;
    }
  }

  /* -Offer preview */
}

@media (max-width: 575px) {

  h1 {
    font-size: 2.1rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  .btn {
    font-size: 1rem;
    &-small {
      padding: 0.1875rem 1rem;
      font-size: 0.9rem;
    }
    &-middle {
      padding: 0.25rem 1.5rem;
    }
    &-large-extra {
      padding: 1rem 3rem;
      font-size: 1.4rem;
    }
  }

  .title-strip-yellow {
    background-color: rgba($yellow-dark, .9);
    h3 {
      font-size: 1.4rem;
    }
  }

  .stars {
    .star-small {
      font-size: .75rem;
    }
    .star-medium {
      font-size: 1.1rem;
    }
  }

  .check-list li {
    margin-bottom: .2rem;
    .icon {
      position: relative;
      font-size: 1.1rem;
    }
  }

  .icon-link {
    img {
      height: 22px;
    }
    span {
      font-size: .7rem;
    }
    &.white {
      top: 0;
      img {
        height: 20px;
      }
    }
  }

  .title-green.top {
    padding: .5rem 1rem;
    &.font-small {
      font-size: .8rem;
    }
  }

  .avoidingContainerPaddings {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @for $i from 1 to 4 {
    .length-#{$i} {
      width: 20px * $i
    }
  }

  /* Header */

  header {
    .container {
      padding: 0;
    }
    .navbar {
      padding: 0;
      &.open {
        padding-bottom: 0;
      }
      a, b {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  /* -Header */

  /* Checkbox */

  [type="checkbox"]:not(:checked) + .checkbox-icon,
  [type="checkbox"]:checked + .checkbox-icon,
  [type="checkbox"]:not(:checked) + .checkbox-icon,
  [type="checkbox"]:checked + .checkbox-icon {
    width: 16px;
    height: 16px;
  }

  [type="checkbox"] + .checkbox-icon:before {
    width: 16px;
    height: 16px;
  }

  [type="checkbox"]:not(:checked) + .checkbox-icon:after,
  [type="checkbox"]:checked + .checkbox-icon:after {
    left: 2px;
    top: 2px;
    font-size: 0.75rem;
  }

  /* -Checkbox */

  /* Modals */

  .modal-green {
    .modal-content {
      padding: 0;
      background-color: transparent;
      border: none;
      .modal-header {
        padding: .8rem .8rem 0;
        background-color: #83d29b;
        font-size: .9rem;
        text-align: center;
        border-radius: .3rem .3rem 0 0;
        h4 {
          line-height: 1.2;
        }
        p {
          margin-bottom: 0;
        }
      }
      .modal-body {
        padding: .5rem .8rem .8rem;
        background-color: #83d29b;
        border-radius: 0 0 .3rem .3rem;
      }
      .form-group {
        margin-bottom: .1rem;
        padding-left: .8rem;
        padding-right: .8rem;
        .col-form-input,
        .col-form-label {
          max-width: none;
          padding-left: 0;
          padding-right: 0;
        }
        .col-form-input {
          flex: 1 0 100%;
        }
        .col-form-label {
          flex: 0 0 auto;
          padding-top: .3rem;
          padding-bottom: .2rem;
        }
        .term-cond {
          margin-bottom: 0;
        }
        &.input-padding {
          margin-top: 1rem;
        }
      }
      .modal-footer {
        .input-padding {
          margin-top: .8rem;
        }
      }
    }
    .divider {
      width: 70%;
      margin: 1.3rem auto .2rem;
    }
    .btn {
      font-size: 1.2rem;
    }
    .form-check {
      margin-bottom: 0;
    }
  }

  /* -Modals */

  /* Join, Search */

  .features {
    background-color: #f4fcf5;
    .feature {
      .wrap-text {
        padding: 1rem .5rem 1rem 0;
      }
    }
  }

  /* -Join, Search */

  /* Search result, Join */

  .search-result-list {
    padding: 0 .5rem;
    .item-stars .stars .star {
      font-size: 13px;
    }
  }

  /* -Search result, Join */

  /* Article, Search */

  .general-slider {
    margin-bottom: .5rem;
    .wrap-slider {
      margin-bottom: 1rem;
    }
    .top-part.over-content {
      padding: .4rem .7rem 1.3rem;
      a {
        font-size: 1.05rem;
      }
      .price {
        padding-right: 0;
      }
    }
  }

  /* -Article, Search */

  /* Faq, How it works */

  .steps-line {
    margin-top: 2rem;
  }

  /* -Faq, How it works */

  /* How it works, Provider details, Search result */

  .edit-block {
    padding: 1rem 1rem 0;
    h4 {
      font-size: 1.3rem;
    }
    .list-block {
      margin-bottom: 0;
      padding: 0;
      border: none;
      h6 {
        padding-bottom: 0;
        padding-left: 0;
        font-size: 1rem;
      }
      ul {
        margin-bottom: .5rem;
        li {
          margin-bottom: 0;
          padding: .4rem 0 .4rem 3px;
          font-size: .9rem;
          line-height: 1.4;
        }
      }
    }
    .actions {
      margin-top: 0;
    }
  }

  /* -How it works, Provider details, Search result */

  /* Comparison table, How it works, (.table-comparison) for feedback-project-status too */

  .table-comparison-mobile {
    font-size: .8rem;
    .company-names a {
      font-size: 1.1em;
    }
  }

  /* -Comparison table, How it works, (.table-comparison) for feedback-project-status too */

  /* Workarea select */

  .sqm-block {
    .sqm-form #sqm-input {
      height: 48px;
      span {
        top: 11px;
      }
    }
    .sqm-values div a {
      height: 48px;
      width: 48px;
    }
  }

  /* -Workarea select */

  /* How it works, Provider details, Join, Search result */

  .advantages.adv-column .advantage i {
    margin-right: 0.4rem;
  }

  /* -How it works, Provider details, Join, Search result */

  /* Search result, Provider details */

  .offices-docs .services-row .service-row {
    flex-direction: column;
    align-items: flex-start;
    div:first-of-type {
      margin-bottom: 0.3rem;
    }
  }

  /* -Search result, Provider details */

  /* Workitems-view */

  edit-projectworkitemoption-modal {
    .actions {
      margin: 1.5rem 0 .5rem;
    }

    .modal-footer {
      p {
        padding: 0 .5rem;
      }
      .locked-field {
        flex-direction: column;
        width: 120px;
        b {
          margin: 0 0 .2rem;
        }
      }
    }
  }

  /* -Workitems-view */

  /* Offer preview */

  .offer-preview {
    border: none;
    .block-green .advantages-check_inline {
      line-height: 1;
      li {
        display: inline;
        margin-right: .3rem;
        font-size: .7rem;
      }
    }
    .top-title {
      line-height: 1.2;
      span {
        font-size: .9rem;
      }
    }
    .mail-part .alert-message {
      line-height: 1.2;
    }
  }

  /* -Offer preview */
}

@media (max-width: 450px) {

  /* How it works, Provider details, Search result */

  .edit-block {
    .list-block {
      .top-part {
        flex-direction: column;
        margin-bottom: 3px;
      }
      p {
        line-height: 1.2;
      }
      a {
        font-size: .85rem;
      }
    }

    .option-labels {
      margin-bottom: 3px;
    }
  }

  /* -How it works, Provider details, Search result */

  /* How it works, Workarea select */

  .work-details {
    .wrap-form-check .form-check {
      label {
        font-size: .8rem !important;
        img {
          width: 24px;
          min-width: 24px;
          max-height: 21px;
        }
      }
    }

    .card.card-block {
      p {
        font-size: .7rem;
      }
    }
  }

  /* -How it works, Workarea select */

  /* Search result, Join */

  .search-result-list {
    .item {
      &-prices {
        .personalize-mobile .btn {
          margin-left: -.4rem;
          padding: 1px .35rem;
        }
      }

      &-provider {
        margin-left: .5rem;
        padding: .6rem .1rem .6rem .5rem;
      }
    }
  }

  /* -Search result, Join */

  /* Footer */

  footer {
    .bottom-part {
      .bottom-nav-column {
        &-list {
          li {
            a {
              font-size: .8rem;
            }
          }
        }
      }
    }
  }

  /* -Footer */

  /* Offer preview */

  .offer-preview .block-green .advantages-check_inline li {
    font-size: 10px;
  }

  /* -Offer preview */
}

@media (max-width: 375px) {

  .btn {
    font-size: 0.9rem;
    &-large-extra {
      font-size: 1.2rem;
    }
  }

  @for $i from 1 to 4 {
    .length-#{$i} {
      width: 15px * $i;
    }
  }

  /* Header */

  .navbar {
    .navbar-brand {
      margin-right: 0;
      img {
        height: 30px;
      }
    }
  }

  /* -Header */

  /* Modals */

  .modal-blue {
    .quantity-block .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .radio-block-row .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
      .form-check {
        margin-bottom: 0;
      }
      i {
        padding-left: 35px;
      }
    }

    .modal-footer .actions .btn {
      margin: 0 0.2rem;
    }
  }

  /* -Modals */

  /* Join, Search */

  .features .feature .wrap-text h5 {
    font-size: 1.1rem;
  }

  /* -Join, Search */

  /* Article, Search */

  .general-slider {
    .top-part.over-content {
      align-items: flex-start;
    }
  }

  /* -Article, Search */

  /* Search result, Provider details */

  .provider-docs .modal-content .modal-header i {
    display: block;
    padding: 0;
  }

  /* -Search result, Provider details */

  /* Search result, Join */

  .search-result-list {
    .item {
      &-provider {
        .provider {
          &-verifications {
            ul {
              li {
                font-size: 8px;
              }
              .check {
                font-size: .8rem;
              }
            }
          }
        }
      }
      &-stars {
          h6 {
            font-size: .85rem;
          }
      }

      .stars {
        .star-small-extra {
          font-size: .7rem;
        }
      }
    }
  }

  .provider {
    &-info {
      a {
        font-size: .9rem;
      }
    }
    &-verified {
      span {
        font-size: .8rem;
      }
    }
  }

  /* -Search result, Join */
}

@media (max-width: 340px) {

  @for $i from 1 to 4 {
    .length-#{$i} {
      width: 12px * $i;
    }
  }

  .title-green,
  .title-flesh {
    font-size: .8rem;
  }

  /* How it works, Workarea select */

  .work-details {
    .wrap-form-check .form-check {
      label {
        font-size: .7rem !important;
      }
    }

    .card.card-block {
      p {
        font-size: .6rem;
      }
    }
  }

  /* -How it works, Workarea select */

  /* Footer */

  footer {
    .bottom-part {
      .bottom-nav-column {
        &-list {
          li {
            padding-bottom: 2px;
            a {
              font-size: .7rem;
            }
          }
        }
      }
    }
  }

  /* -Footer */

  /* -Search result, Join */

  .search-result-list {
    .item {
      .paddings {
        grid-template-columns: 140px 1fr;
        &.project-listing {
          .item-envelope .personalize-text {
            bottom: 32px;
            a {
              margin-left: 22px;
              font-size: 10px;
            }
          }
        }
        &.standard-listing {
          .standard-text {
            top: -2px;
            font-size: 1.4rem;
          }
        }
        &.landing {
          .landing-text {
            bottom: 29px;
            left: -5px;
            width: 150px;
            span {
              font-size: 12px;
            }
          }
        }
      }
      &-envelope {
        .part {
          &-top {
            margin-top: 26px;
            height: 49px;
            .price {
              font-size: 1.6rem;
            }

            .price-less-text {
              font-size: 1.4rem;
            }
          }
          &-middle {
            .btn {
              padding: 0 8px;
              font-size: 10px;
            }
          }
          &-bottom {
            position: absolute;
            bottom: 14px;
            left: 0;
            width: 100%;
            a {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  /* -Search result, Join */

  /* Offer preview */

  .offer-preview {
    .block-green .advantages-check_inline {
      padding-bottom: 2px;
      line-height: 1;
      li {
        font-size: 9px;
      }
    }
    .top-title span {
      font-size: .7rem;
    }
  }

  /* -Offer preview */
}

