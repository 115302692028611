@import "../variables";

.join-as-prof {
  .title-green {
    .container {
      position: relative;
    }
    .back-link {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: $white;
      font-size: 1.3rem;
    }
    h1 {
      margin: 0;
      font-size: inherit;
    }
  }
  h3 {
    margin: 1.2rem 0;
    color: $green;
    text-align: center;
  }

  .partner-with-us {
    .action {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding: 2.5rem 0;
      text-align: center;
      .form-check {
        margin-bottom: 0;
      }
      .term-cond {
        position: absolute;
        left: 0;
        top: -25px;
        width: 100%;
        color: $green;
        font-size: .9rem;
        a {
          color: $green;
        }
      }
      .btn-large-extra {
        width: 370px;
        padding: 1rem;
      }
    }
  }

  .green-block {
    .wrap-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.5rem;
      color: #29417a;
      .image {
        margin-right: 3rem;
        img {
          max-width: 220px;
        }
      }
      h5 {
        margin-bottom: .7rem;
        padding-left: .5rem;
      }
      .check-list {
        li {
          margin-bottom: .5rem;
          .icon {
            font-size: 1.1rem;
          }
        }
      }
      .form-group {
        .col-form-label {
          padding-top: 5px;
          padding-bottom: 5px;
          color: $green;
          font-size: .9rem;
          line-height: 1.4;
        }
        .form-control {
          height: 50px;
          border-color: $gray-medium;
        }
      }
    }
  }
  .wrap_icon-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1.5rem 0;
    .icon-link {
      margin: 0 1.2rem;
      img {
        height: 40px;
      }
      span {
        padding: .5rem 1.3rem;
      }
    }
  }
  .features {
    padding: 1.5rem 0;
    h3 {
      margin: 1rem 0;
      color: $green-bright-medium;
    }
  }
}

@media (max-width: 991px) {

  .join-as-prof {
    h3 {
      font-size: 1.3rem;
    }

    .title-green a {
      font-size: 1rem;
    }

    .green-block .wrap-content {
      padding: 1rem .5rem;
      .image {
        margin-right: 2rem;
      }
      .check-list li {
        align-items: flex-start;
      }
    }

    .wrap_icon-links .icon-link {
      margin: 0 1rem;
      span {
        padding-left: .8rem;
        padding-right: .8rem;
      }
    }

    .features {
      padding: 1rem 0 2rem;
    }
  }
}

@media (max-width: 767px) {

  .join-as-prof {
    .partner-with-us {
      margin-bottom: 0;
      .action {
        padding: 1.5rem 0;
        .term-cond {
          position: static;
        }
      }
    }

    .green-block .wrap-content {
      flex-direction: column;
      padding: .7rem .5rem;
      .image {
        margin: .5rem 0 1rem;
      }
      .form-group {
        margin: 0 0 .7rem;
        padding: 0;
        .col-form-label {
          width: auto;
        }
        .form-control {
          height: 44px;
        }
      }
    }

    .wrap_icon-links {
      flex-direction: column;
      align-items: center;
      .icon-link {
        margin-bottom: .8rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 575px) {

  .join-as-prof {
    .partner-with-us {
      .action {
        padding: 1rem 0;
        .btn-large-extra {
          width: 100%;
          padding-left: 1rem;
          padding-right: 1rem;
        }
        &-top {
          padding: 0;
          .form-check {
            margin: 1rem 0 0;
          }
        }
      }
    }

    .features {
      background-color: transparent;
    }

    .green-block {
      &.mobile-transparent {
        background-color: transparent;
        border: none;
      }
      .wrap-content {
        padding: 0 7px;
        .check-list li {
          align-items: flex-start;
        }
        .form-group {
          margin-bottom: .4rem;
          .col-form-label {
            padding-top: 3px;
            padding-bottom: 3px;
          }
          .form-control {
            height: 42px;
          }
        }
      }
    }

    .search-result-list {
      width: calc(100% + 44px);
      margin-left: -22px;
      padding: 1.2rem 15px;
      .item {
        margin-bottom: 0;
        &-stars {
          .item-prices,
          .item-services,
          .item-reviews {
            margin-bottom: .1rem;
          }
        }
      }
    }
  }
}
