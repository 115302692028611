@import "../variables";

.search {
  .scroll-block {
    display: none;
  }
  &-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;
  }
  &-filter {
    flex: 0 0 335px;
    max-width: 335px;
    background: $yellow-dark;
    .bottom-line {
      border-bottom: 1px solid rgba($white, 0.6);
    }
    .paddings {
      padding: 1.3rem 1rem;
    }
    .condensed-mobile-block {
      display: none;
    }
    .title-top {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.7rem 0 0.2rem;
      color: #fee5ae;
      text-align: center;
      border-bottom: none;
      h6 {
        padding: 0;
      }
      .fa {
        margin-right: 0.5rem;
        font-size: 0.8rem;
      }
    }
    .btn-like-dropdown {
      text-align: center;
    }
    .title {
      @extend .paddings;
      h5,
      p {
        margin-bottom: 0.4rem;
      }
    }
    .step {
      &-first {
        padding-bottom: 1.5rem;
        &-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem 1.4rem 0.5rem 0.7rem;
          &:not(:first-child) {
            padding-top: 0;
          }
          &.bottom-line {
            margin-bottom: 0.8rem;
            padding-bottom: 0.8rem;
          }
          .number {
            width: 26px;
            min-width: 26px;
            img {
              width: auto;
              height: 21px;
            }
          }
          .icons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 0.1rem;
            img {
              margin: 0 0.7rem;
              max-width: 35px;
              height: 30px;
            }
            &.icons-opacity {
              img {
                opacity: 0.4;
              }
            }
          }
          &.with-preview {
            align-items: baseline;
            .btn-like-dropdown {
              background-color: $yellow-dark;
            }
            .wrap-preview {
              width: 100%;
            }
            .preview-block {
              margin-top: -1.4rem;
              padding: 0 1rem 1rem;
              color: $bronze;
              border: 1px solid #e5ac19;
              h6 {
                margin-top: 2.5rem;
                text-align: center;
              }
              ul {
                padding-left: 2rem;
                font-size: 0.85rem;
                list-style: disc;
                li {
                  padding-left: 0.5rem;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
                a {
                  color: $bronze;
                }
              }
            }
          }
        }
      }
      &-second {
        [class*="block-"] {
          display: none;
        }
        .title {
          h5 {
            img {
              position: relative;
              top: -2px;
              width: 13px;
              margin-right: 0.5rem;
            }
          }
        }
        .block-active {
          display: block;
        }
        .google-maps-autocomplete-block {
          .mat-mdc-text-field-wrapper {
            padding: 0;
          }

          .mdc-line-ripple {
            display: none;
          }

          .mdc-floating-label {
            display: none;
          }

          .mat-mdc-form-field-subscript-wrapper {
            display: none;
          }

          .mdc-text-field {
            overflow: visible;
          }

          .mat-mdc-input-element {
            height: 48px;
            padding: 0.375rem 0.75rem;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            &::placeholder {
              opacity: 1;
              color: #bfbfbf;
            }
            &:focus {
              border-color: #80bdff;
              outline: 0;
              box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
          }
        }
        .active-block {
          @extend .paddings;
          background: $blue-gray-light;
          .actions {
            margin-bottom: 1.3rem;
            color: $blue;
            .next-step {
              display: flex;
              flex-direction: row;
              max-width: 300px;
              margin: 0 auto 0.5rem;
              .btn {
                padding: 0.2rem 1.3rem;
                font-size: 1.2rem;
                z-index: 3;
              }
              &_text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1 1;
                margin-left: -6px;
                padding: 0.2rem 10px 0.2rem 16px;
                background-color: $white;
                font-size: 0.8rem;
                line-height: 1.2;
                text-align: left;
                div {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                }
                img {
                  margin-right: 0.2rem;
                  width: 12px;
                }
              }
            }
            .back-search-result {
              font-size: 0.75rem;
              text-align: center;
              a {
                color: $blue;
              }
            }
          }
        }
      }
    }
    .map {
      margin-top: 0.5rem;
      overflow: hidden;
      border: 1px solid #d5d9df;
      border-radius: 6px;
    }
    .sqm-block {
      max-width: 320px;
      padding: 0;
      div {
        margin-top: 8px;
        a {
          height: 55px;
          width: 55px;
        }
      }
    }
    input[type="text"] {
      height: 48px;
    }
  }

  &-result {
    flex: 0 0 calc(100% - 355px);
    max-width: calc(100% - 355px);
    &-filter {
      .nav {
        justify-content: space-evenly;
        &-item {
          flex: 0 0 23%;
          white-space: nowrap;
          .nav-link {
            padding: 1px 1rem;
            background: $white;
            color: #b3b5b3;
            font-size: 0.9rem;
            border: 1px solid #c9c9c9;
            border-radius: 25px;
            text-decoration: none;
            &.active,
            &:hover {
              background: #ebf8ee;
              color: $green;
            }
            &.active {
              font-weight: $F-bold;
            }
          }
        }
      }
      h5 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.2rem;
        padding: 0 0.7rem;
        color: $green;
        font-weight: $F-medium;
      }
    }
  }
  .top-banner {
    position: relative;
    margin-bottom: 1.2rem;
    padding: 14px 1.2rem 26px;
    color: $blue-dark;
    border: 1px solid #e4e4e4;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 12px;
      background: linear-gradient(to bottom, #fff, #dfe1e3);
    }

    h4 {
      margin-bottom: 14px;
    }

    .blocks {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .block {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 1.3rem;

        &-first {
          color: #eba701;
        }
        &-second {
          color: #3076c6;
        }
        &-third {
          padding-right: 0;
          color: #0f4988;
        }
      }

      img {
        height: 40px;
        margin-right: 0.8rem;
      }
      b {
        font-size: 0.9rem;
        line-height: 1.4;
        text-align: left;
      }
    }
  }
  .edit-block {
    .list-block,
    .actions {
      padding-left: 2rem;
      padding-right: 1rem;
    }
  }
  .title-strip-yellow {
    margin: 1.5rem 0 2rem;
    padding: 0.7rem 0;
    color: $black;
  }
}

/*
 * Variables
 */

:root {
  --envelope-card-padding: 8px;
  --envelope-card-height: 184px;
  --envelope-card-skeleton: linear-gradient(
    white var(--envelope-card-height),
    transparent 0
  );
  --envelope-block-skeleton: linear-gradient(
    #bfbfbf var(--fourth-col-height),
    transparent 0
  );

  --top-extra-padding: 15px;

  --first-col-height: 166px;
  --first-col-width: 166px;
  --first-col-position: var(--envelope-card-padding)
    var(--envelope-card-padding);

  --second-col-height: 16px;
  --second-col-width: 75px;
  --second-col-long-width: 101px;
  --second-col-left: 190px;

  --second-col-row1-position: var(--second-col-left) var(--top-extra-padding);
  --second-col-row2-position: var(--second-col-left) 34px;
  --second-col-row3-position: var(--second-col-left) 70px;
  --second-col-row4-position: var(--second-col-left) 89px;
  --second-col-row5-position: var(--second-col-left) 133px;
  --second-col-row6-position: var(--second-col-left) 152px;

  --third-col-width: 150px;
  --third-col-left: 307px;

  --third-col-row1-height: 14px;
  --third-col-row1-position: var(--third-col-left) var(--top-extra-padding);

  --third-col-row2-height: 54px;
  --third-col-row2-position: var(--third-col-left) 34px;

  --third-col-row3-height: 80px;
  --third-col-row3-position: var(--third-col-left) 94px;

  --fourth-col-height: 166px;
  --fourth-col-width: 278px;
  --fourth-col-left: 467px;
  --fourth-col-position: var(--fourth-col-left) var(--envelope-card-padding);

  --envelope-blur-width: 250px;
  --envelope-blur-size: var(--envelope-blur-width) var(--envelope-card-height);
}

/*
 * Card Skeleton for Loading
 */

.skeleton-card-envelope-version {
  width: 100%;
  height: var(--envelope-card-height);

  &:empty::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #a5a5a5;

    background-image: linear-gradient(
        90deg,
        rgba(white, 0) 0,
        rgba(white, 0.5) 50%,
        rgba(white, 0) 100%
      ),
      var(--envelope-block-skeleton), var(--envelope-block-skeleton),
      var(--envelope-block-skeleton), var(--envelope-block-skeleton),
      var(--envelope-block-skeleton), var(--envelope-block-skeleton),
      var(--envelope-block-skeleton), var(--envelope-block-skeleton),
      var(--envelope-block-skeleton), var(--envelope-block-skeleton),
      var(--envelope-block-skeleton), var(--envelope-card-skeleton);

    background-size: var(--envelope-blur-size),
      var(--first-col-width) var(--first-col-height),
      var(--second-col-width) var(--second-col-height),
      var(--second-col-long-width) var(--second-col-height),
      var(--second-col-width) var(--second-col-height),
      var(--second-col-long-width) var(--second-col-height),
      var(--second-col-width) var(--second-col-height),
      var(--second-col-long-width) var(--second-col-height),
      var(--third-col-width) var(--third-col-row1-height),
      var(--third-col-width) var(--third-col-row2-height),
      var(--third-col-width) var(--third-col-row3-height),
      var(--fourth-col-width) var(--fourth-col-height), 100% 100%;

    background-position: -150% 0, var(--first-col-position),
      var(--second-col-row1-position), var(--second-col-row2-position),
      var(--second-col-row3-position), var(--second-col-row4-position),
      var(--second-col-row5-position), var(--second-col-row6-position),
      var(--third-col-row1-position), var(--third-col-row2-position),
      var(--third-col-row3-position), var(--fourth-col-position), 0 0;

    background-repeat: no-repeat;
    animation: loading-envelope 2.5s linear infinite;
  }
}

@keyframes loading-envelope {
  to {
    background-position: 350% 0, var(--first-col-position),
      var(--second-col-row1-position), var(--second-col-row2-position),
      var(--second-col-row3-position), var(--second-col-row4-position),
      var(--second-col-row5-position), var(--second-col-row6-position),
      var(--third-col-row1-position), var(--third-col-row2-position),
      var(--third-col-row3-position), var(--fourth-col-position), 0 0;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .search-filter
    .step-second
    .active-block
    .actions
    .next-step_text
    span:lang(it) {
    font-size: 11px;
  }
}

@media (max-width: 1199px) {
  .search {
    &-filter {
      flex: 0 0 280px;
      max-width: 280px;
      font-size: 1.1rem;
      .step-first-block.with-preview .preview-block ul {
        padding-left: 1rem;
      }

      .sqm-block div a {
        height: 47px;
        width: 47px;
      }
    }
    &-result {
      flex: 0 0 calc(100% - 305px);
      max-width: calc(100% - 305px);
      &-filter {
        h5 {
          padding: 0.5rem;
          font-size: 1.2rem;
          .additional-info {
            font-size: 0.75rem;
          }
        }
      }
    }
    .top-banner .blocks {
      .block {
        flex-direction: column;
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      img {
        height: 30px;
        margin: 0 0 8px;
      }
      b {
        line-height: 1.2;
        text-align: center;
      }
    }
  }

  :root {
    --second-col-width: 50px;
    --second-col-long-width: 74px;
    --second-col-left: 184px;

    --third-col-left: 273px;

    --fourth-col-width: 182px;
    --fourth-col-left: 433px;
  }
}

@media (max-width: 991px) {
  .search {
    &-filter {
      flex: 0 0 250px;
      max-width: 250px;
      .step-first-block {
        padding-right: 0.7rem;
      }
    }
    &-result {
      flex: 0 0 calc(100% - 270px);
      max-width: calc(100% - 270px);
      &-filter {
        .nav {
          justify-content: center;
        }
        .nav-item {
          position: relative;
          flex: 0 0 auto;
          padding: 0 1rem;
          &:after {
            content: "";
            position: absolute;
            top: 15%;
            right: 0;
            height: 80%;
            width: 1px;
            background-color: #7ea6c7;
          }
          &:last-of-type {
            &:after {
              display: none;
            }
          }
          .nav-link {
            padding: 0;
            background: none;
            color: #7ea6c7;
            font-size: 12px;
            border: none;
            text-decoration: underline;
            &.active,
            &:hover {
              background: none;
              color: #4c708e;
              text-decoration: none;
            }
          }
        }
      }
    }

    .block-green.shopping-cart .btn.btn-green {
      width: auto;
      min-width: auto;
      padding: 0.2rem 1rem;
      font-size: 1.1rem;
    }

    .top-banner {
      padding: 10px 8px 22px;
      .blocks {
        .block {
          padding-right: 1rem;
          &-third {
            padding-right: 0;
          }
        }

        img {
          height: 26px;
        }
        b {
          font-size: 12px;
          font-weight: $F_regular;
        }
      }
    }
  }

  :root {
    --envelope-card-height: 453px;

    --second-col-row1-top: 182px;
    --second-col-row2-top: 200px;

    --second-col-width: 14%;
    --second-col-long-width: 24%;
    --second-col-row1-position: 12% var(--second-col-row1-top);
    --second-col-row2-position: 7% var(--second-col-row2-top);
    --second-col-row3-position: 50% var(--second-col-row1-top);
    --second-col-row4-position: 50% var(--second-col-row2-top);
    --second-col-row5-position: 88% var(--second-col-row1-top);
    --second-col-row6-position: 93% var(--second-col-row2-top);

    --third-col-width: calc(100% - 194px);
    --third-col-left: 186px;
    --third-col-row1-position: var(--third-col-left) var(--top-extra-padding);

    --third-col-row2-height: 50px;
    --third-col-row2-position: var(--third-col-left) 35px;

    --third-col-row3-height: 72px;
    --third-col-row3-position: var(--third-col-left) 91px;

    --fourth-col-width: calc(100% - var(--envelope-card-padding) * 2);
    --fourth-col-height: 220px;
    --fourth-col-position: var(--envelope-card-padding) 223px;

    --envelope-blur-width: 200px;
  }

  .skeleton-card-envelope-version:empty::after {
    animation: loading-envelope 2.5s ease-out infinite;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .search-filter {
    .step-second .active-block .actions .next-step {
      .btn {
        padding: 0.2rem 1rem;
        font-size: 1.1rem;
      }
      &_text {
        font-size: 0.7rem;
      }
    }
  }
}

@media (max-width: 767px) {
  $top-part-space: 1rem;
  .search {
    padding-top: 0;
    .block-green.shopping-cart {
      margin-top: -12px;
      padding: 0.9rem 0.7rem 0.3rem;
      ul {
        margin-top: 1rem;
        li {
          font-size: 0.8rem;
        }
      }
    }
    &.condensed-mobile {
      .search-filter {
        margin-top: $top-part-space;
        background-color: transparent;
        .condensed-mobile-block {
          display: block;
          .btn {
            width: auto;
            padding-right: 35px;
          }
        }
        .step-first {
          display: none;
        }
      }
    }
    &-filter {
      position: relative;
      overflow: hidden;
      .icon-compact {
        position: absolute;
        top: 0;
        right: 10px;
      }
      .title.title-top {
        padding: 0;
        margin: 1rem 0;
      }
      .step-first-block,
      .step-first-block:not(:first-child) {
        padding: 0.3rem 0;
        &.with-preview {
          align-items: center;
          .preview-block {
            display: none;
          }
        }
        &.bottom-line {
          position: relative;
          margin-bottom: 1.3rem;
          &:after {
            content: "";
            position: absolute;
            bottom: -0.8rem;
            left: -55px;
            width: 510px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.6);
          }
          .btn-like-dropdown {
            justify-content: center;
          }
        }
        .number {
          img {
            height: 18px;
          }
        }
        .icons {
          margin-top: 0;
          img {
            height: 27px;
            max-width: 31px;
            margin: 0 0.5rem 0 0;
          }
        }
      }
      .step-second {
        .title {
          background-color: $yellow-dark;
        }
      }
      .btn-like-dropdown {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 35px;
        padding-left: 7px;
        .text {
          margin-left: 0.5rem;
        }
      }
      .condensed-mobile-block {
        .btn-like-dropdown {
          justify-content: center;
          max-width: 80%;
          margin: 0 auto;
          padding-left: 35px;
          font-weight: $F-regular;
        }
      }
      .sqm-block div a {
        height: 55px;
        width: 55px;
      }
    }
    &-result {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      &-filter {
        h5,
        .green {
          justify-content: center;
          margin-bottom: 0;
          padding: 0.5rem;
          background-color: transparent;
          color: $blue-dark-gray;
          font-size: 0.9rem;
          font-weight: $F-medium;
        }
        .nav {
          margin: $top-part-space 0;
          line-height: 1;
          &-item {
            border-color: $blue-bg-mobile;
          }
        }
      }
    }
    &-block {
      flex-direction: column;
      align-items: inherit;
      margin-top: 0;
      padding-top: 0;
      .search-filter {
        flex: 0 0 100%;
        max-width: none;
        .step-first {
          max-width: 400px;
          margin: 0 auto;
          padding-top: 0.5rem;
          .bottom-line {
            padding-bottom: 0;
            border-bottom: none;
          }
          &-block {
            border: none;
          }
        }
      }
    }
    .top-banner {
      margin-bottom: 0;
      h4 {
        font-size: 1.2rem;
      }
    }
  }

  .skeleton-card-envelope-version:empty::after {
    animation: loading-envelope 2.5s linear infinite;
  }
}

@media (max-width: 575px) {
  .search {
    .edit-block {
      padding: 0;
      .title-strip-yellow {
        margin-top: 0;
        margin-bottom: 0.7rem;
      }
      .list-block,
      .actions {
        padding-left: 1rem;
        padding-right: 0;
      }
      .list-block {
        margin: 1rem 0;
      }
    }
    .search-block,
    .title-strip-yellow {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
    &-filter {
      .condensed-mobile-block,
      .step-first {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
      }

      .step-first-block.bottom-line:after {
        left: -90px;
        width: 600px;
      }
    }
  }

  .skeleton-card-envelope-version:empty::after {
    animation: loading-envelope 3s ease-out infinite;
  }
}

@media (max-width: 450px) {
  .search {
    .search-filter {
      .icon-compact {
        right: 5px;
      }

      .step-first-block.bottom-line:after {
        left: -30px;
        width: 470px;
      }
      .step-second .active-block .actions .next-step {
        max-width: none;
      }
      .condensed-mobile-block .btn-like-dropdown {
        max-width: 100%;
        font-size: 0.9rem;
      }
    }

    .top-banner {
      h4 {
        font-size: 1rem;
      }

      .blocks .block {
        padding-right: 8px;
      }
    }
  }

  .skeleton-card-envelope-version:empty::after {
    animation: loading-envelope 2s ease-out infinite;
  }
}

@media (max-width: 375px) {
  .search .search-filter {
    .condensed-mobile-block .btn-like-dropdown {
      padding-left: 10px;
      font-size: 0.8rem;
    }
    .step-second .active-block {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  .search-result {
    &-filter {
      .nav {
        &-item {
          &:first-of-type {
            border-left: none;
          }
          &:last-of-type {
            border-right: none;
          }
        }
        &-link {
          font-size: 0.8rem;
        }
      }
    }
  }

  .skeleton-card-envelope-version:empty::after {
    animation: loading-envelope 2s infinite;
  }
}

@media (max-width: 340px) {
  .search .search-filter {
    .step-second .active-block .actions {
      .next-step {
        .btn {
          padding: 0.2rem 1rem;
          font-size: 1rem;
        }
        &_text {
          font-size: 0.7rem;
        }
      }
      .back-search-result {
        font-size: 0.7rem;
      }
    }
    .sqm-block div a {
      height: 50px;
      width: 50px;
    }
  }
}
