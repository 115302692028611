﻿//@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";
@import "../../node_modules/bootstrap/scss/popover";



@import "../../node_modules/ngx-toastr/toastr.css";


@import "./scss/variables.scss";
@import "./scss/flags.scss";
@import "./scss/buttons.scss";
@import "./scss/general.scss";
@import "./scss/more.scss";
@import "./scss/pages/article";
@import "./scss/pages/articles-search";
@import "./scss/pages/authorization";
@import "./scss/pages/faq_and_service-use";
@import "./scss/pages/feedback";
@import "./scss/pages/how-it-works";
@import "./scss/pages/join-as-prof";
@import "./scss/pages/my-projects";
@import "./scss/pages/provider-details_and_how-it-works";
@import "./scss/pages/registration";
@import "./scss/pages/search";
@import "./scss/pages/search-result";
@import "./scss/pages/unsubscribe";
@import "scss/general-responsive";






