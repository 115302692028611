@import "../variables";

.projects-table {
  .Ftable-row {
    &.Ftable-header {
      background-color: #e9eff7;
      .Ftable-cell {
        padding-top: .5rem;
        padding-bottom: .5rem;
        font-size: 1rem;
        font-weight: $F-bold;
      }
    }
    &:nth-of-type(even) {
      background-color: #f7fffb;
    }
    .Ftable-cell {
      padding-top: 1rem;
      padding-bottom: 1rem;
      &:first-of-type {
        padding-left: .3rem;
        text-align: left;
      }
    }
  }

  &.project-active {
    b {
      margin-right: .6rem;
    }
    .Ftable-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &.project-saved {
    margin: 1.5rem 0;
    a {
      font-size: .75rem;
    }
    .Ftable-cell {
      width: 15%;
      font-size: .9rem;
      a {
        margin-left: 0;
        margin-right: .4rem;
        &.text-badge {
          margin-right: .5rem;
          padding-left: .6rem;
          padding-right: .6rem;
        }
        &.red {
          color: #ffaba8;
        }
        &.blue {
          color: #7ea5c7;
        }
      }
    }
  }
}

@media (max-width: 767px) {

  .wrap_projects-table {
    margin: 0 1rem;
  }

  .projects-table {
    .Ftable-row {
      &.Ftable-header {
        background-color: $white;
      }
      .Ftable-cell {
        &:first-of-type {
          font-size: 1.1rem;
        }
      }
    }
    &.project-active {
      .Ftable-row {
        &.Ftable-header {
          .Ftable-cell {
            padding-bottom: .2rem;
          }
        }
        &:nth-of-type(even) {
          background-color: $white;
        }
        &:not(.Ftable-header) {
          .Ftable-cell {
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding-top: 0;
          }
        }
        .Ftable-cell {
          .btn {
            margin-right: .5rem;
          }
          b {
            margin-top: 2px;
            margin-right: 0;
            font-size: .9rem;
            font-weight: $F-regular;
          }
        }
      }
    }
    &.project-saved {
      margin-top: 0;
      .Ftable-header {
        display: none;
      }
      .Ftable-row {
        flex-wrap: wrap;
        margin: .8rem 0;
      }
      .Ftable-cell {
        width: auto;
        padding-top: .5rem;
        padding-bottom: .5rem;
        text-align: left;
      }
      .cell-object {
        width: 100%;
      }
    }
  }
}

@media (max-width: 575px) {

  .projects-table {
    &.project-saved {
      .cell-last_saved {
        width: 100%;
      }
    }
  }
}
