.steps-line .step {
    cursor:pointer;
}

.tile-search_tips li a:not([href]):hover {
    color: #114989!important;
}

.shopping-cart ul a:not([href]) {
    color: #00b050;
}

a.btn.btn-like-dropdown.btn-yellow-bronze {
    height: 32px!important;
}

.cursor-pointer {
    cursor: pointer;
}

.navigation-links {
    a, a:not([href]):not([tabindex]) {
        color: #0177cc;
    }
}
