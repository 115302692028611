<div [ngClass]="{ 'odp-standard': !projectPending, 'prov-details': true, 'odp': true }">
    <div class="odp-top">
        <div class="container">
            <div class="wrap">
                <div *ngIf="!priceItem.selected" class="left-part">
                    <div class="wrap-img">
                        <b>{{ 'search_results.offerCap' | translate }}</b>
                        <img src="assets/img/envelope-green-white.svg" alt="">
                    </div>
                    <div class="wrap-content">
                        <a class="btn btn-green" (click)="selectProvider()">
                            {{ 'offer_details.btn_select_offer' | translate }}
                        </a>
                        <br>
                        <div>
                            {{ 'offer_details.slogan1' | translate }}
                        </div>
                    </div>
                </div>
                <div *ngIf="priceItem.selected" class="left-part">
                    <div class="wrap-img selected">
                        <b>{{ 'search_results.provider_selected' | translate }}</b>
                        <img src="assets/img/envelope-gray-selected.svg" alt="">
                        <div class="wrap-btn">
                            <a class="btn btn-white-red_orange"
                               (click)="unselectProvider()">{{ 'offer_details.unselect_provider' | translate }}</a>
                        </div>
                    </div>
                    <div class="wrap-content">
                        <a *ngIf="selectedProviders.length === 3" langRouterLink routerLink="/register" class="btn btn-green">
                            {{ 'offer_details.btn_get_offers' | translate }}
                        </a>
                        <a *ngIf="selectedProviders.length !== 3" langRouterLink routerLink="/search-results" class="btn btn-green">
                            {{ 'offer_details.go_select_more' | translate }}
                        </a>
                        <br>
                        <div *ngIf="selectedProviders.length < 3">
                            {{ 'offer_details.slogan2' | translate }} <a *ngIf="selectedProviders.length === 1"
                                                                         langRouterLink routerLink="/register">{{ 'offer_details.1_selected_offer' | translate }}</a><a
                                *ngIf="selectedProviders.length !== 1"
                                langRouterLink routerLink="/register">{{ 'offer_details.n_selected_offers' | translate : {selectedOffersNum: selectedProviders.length} }}</a>
                        </div>
                        <div *ngIf="selectedProviders.length >= 3">
                            {{ 'offer_details.slogan3' | translate }}
                        </div>
                    </div>
                </div>
                <div class="right-part">
                    <div class="wrap-content">
                        <div class="provider-verified">
                            <img src="assets/img/icon-shield.svg" width="14px" alt="">
                            <span>
                            {{ 'search_results.verified_provider' | translate }}
                        </span>
                        </div>
                        <div class="provider-info">
                            <a [routerLink]="'/provider/' + priceItem.providerId">{{priceItem.providerName}}</a>
                            <div class="stars">
                                <div class="star star-orange star-small-extra"
                                     *ngFor="let star of mainRatingStars"></div>
                            </div>
                        </div>
                        <div class="provider-extra_info">
                            <div class="provider-rating">
                                <div class="provider-rating_row">
                                    <b>
                                        {{ 'search_results.services_stars_header' | translate }}
                                    </b>
                                    <small-stars class="stars" [num]="priceItem.stats.services.starsNum"
                                                 color="blue-dark">
                                    </small-stars>
                                </div>
                                <div class="provider-rating_row">
                                    <b>
                                        {{ 'search_results.prices_stars_header' | translate }}
                                    </b>
                                    <small-stars class="stars" [num]="priceItem.stats.prices.starsNum"
                                                 color="blue-dark">
                                    </small-stars>
                                </div>
                            </div>
                            <ul class="advantages-check">
                                <li *ngIf="priceItem.guarantee.datesInContract != ProjectGuaranteeDatesInContract.none">
                                    <span class="check">✓</span><b>{{ 'offer_details.on_time_guarantee' | translate }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="priceItem.leadingPictureUrl" class="wrap-img">
                        <img src="{{priceItem.leadingPictureUrl}}" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="odp-tabs container">
        <div class="part-left" *ngIf="priceItem">
            <a class="btn btn-yellow" [ngClass]="{ 'with-icon': projectPending }"
               [routerLink]="'/offer-preview/' + priceItem.providerId">
                <img *ngIf="projectPending" src="assets/img/icon-heart-house-white.svg" alt="">
                <span>{{ 'provider_details.preview_offer' | translate }}</span>
            </a>
            <div class="price">
                {{priceItem.currencySign}}&nbsp;{{priceItem.totalPrice | number:'1.0-0'}}
            </div>
        </div>
        <div class="part-right">
            <span class="preview-text">
                {{ 'offer_details.for_your_proj' | translate }}
            </span>
            <project-tabs [activeTab]="activeTab">
            </project-tabs>
            <div *ngIf="projectPending">
                <div *ngIf="priceItem && priceItem.workItems && priceItem.workItems.length"
                     class="project-details-list">
                    <div class="container">
                        <ul class="list-with-disc">
                            <li>
                                <b>{{ workItem1Name }}</b>
                                <p>{{ workItem1Desc }}</p>
                            </li>
                            <li>
                                <b>{{ workItem2Name }}</b>
                                <p>{{ workItem2Desc }}</p>
                            </li>
                            <li *ngIf="priceItem.workItems.length > 2">
                                <b>
                                    <a langRouterLink routerLink="/edit-project">
                                        {{ 'WORKAREAS_SELECTOR.workitems_details_link' | translate:{workItemsCount: (priceItem.workItems.length - 2)} }}
                                    </a>
                                </b>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="provider-block container">
        <div>
            <div class="info info-new">
                <div class="info-part">
                    <div class="container" *ngIf="priceItem">
                        <div class="provider-line">
                            <div class="info-verified">
                                <img src="assets/img/icon-reno.svg" alt="">
                                <span>
                                    <b>Review verified provider highlights</b><span class="d-md-inline d-none">, as well as 23 pictures from previous projects</span>
                                </span>
                            </div>
                            <div class="info-provider">
                                <a class="provider-name">
                                    <span *ngIf="projectPending">{{ 'search_results.provider' | translate }} </span>{{priceItem.providerName}}
                                </a>
                                <div class="stars">
                                    <div class="star star-orange star-small"
                                         *ngFor="let star of mainRatingStars"></div>
                                </div>
                            </div>
                            <div class="advantages adv-column adv-row small-extra"
                                 (click)="priceItem && showDocsModal(priceItem.providerId)">
                                <div class="advantage" (click)="priceItem && showDocsModal(priceItem.providerId)"
                                     *ngFor="let verifiedThing of priceItem.verifiedThings">
                                     <i class="fa-regular fa-square-check"></i>
                                    <span>
                                    <a>{{ verifiedThing }}</a>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div class="description" *ngIf="providerDescription">
                            {{providerDescription}}
                            <div *ngIf="priceItem">
                                <a *ngIf="providerDecsriptionShortened" (click)="showAllDesc()"
                                   class="text-badge gray">{{ 'provider_details.read_more' | translate }}</a>
                                <a class="text-badge" *ngIf="displayOfficeDetailsButton"
                                   (click)="showDocsModal(priceItem.providerId)">{{ 'provider_details.office_details' | translate }}</a>
                                <a class="text-badge" *ngIf="numOfDocs == 1"
                                   (click)="showDocsModal(priceItem.providerId)">{{ 'provider_details.doc_1' | translate }}</a>
                                <a class="text-badge" *ngIf="numOfDocs > 1"
                                   (click)="showDocsModal(priceItem.providerId)">{{ 'provider_details.docs_num' | translate:{numOfDocs: numOfDocs} }}</a>
                            </div>
                        </div>
                        <div class="characteristic" *ngIf="priceItem">
                            <div *ngIf="reviews.length" class="tile tile-reviews">
                                <div class="tile-title">
                                    <div class="wrap-line">
                                        <h5>
                                            {{ 'provider_details.reviews' | translate }}
                                        </h5>
                                        <div stars [num]="priceItem.stats.reviews.starsNum"
                                             *ngIf="priceItem.stats.reviews.num" class="stars">
                                        </div>
                                    </div>
                                    <div class="wrap-link">
                                        <a *ngIf="reviews.length"
                                           (click)="showReviews()">{{ 'provider_details.show_reviews' | translate:{reviewsCount: reviews.length} }}</a>
                                    </div>
                                </div>
                                <div class="tile-content tile-indicator_bar" *ngIf="priceItem.stats.reviews.num">
                                    <div class="wrap-indicator">
                                <span>
                                    4-5 {{ 'provider_details.stars' | translate }}:
                                </span>
                                        <div [ngClass]="numOf45StarReviewsBarClasses" *ngIf="priceItem.stats"></div>
                                        <b>{{priceItem.stats.numOf45Reviews}}</b>
                                    </div>
                                    <div class="wrap-indicator">
                                <span>
                                    3 {{ 'provider_details.stars' | translate }}:
                                </span>
                                        <div [ngClass]="numOf3StarReviewsBarClasses" *ngIf="priceItem.stats"></div>
                                        <b>{{priceItem.stats.numOf3Reviews}}</b>
                                    </div>
                                    <div class="wrap-indicator">
                                <span>
                                    1-2 {{ 'provider_details.stars' | translate }}:
                                </span>
                                        <div [ngClass]="numOf12StarReviewsBarClasses" *ngIf="priceItem.stats"></div>
                                        <b>{{priceItem.stats.numOf12Reviews}}</b>
                                    </div>
                                </div>
                                <div class="tile-content tile-indicator_bar" *ngIf="!priceItem.stats.reviews.num">
                                    <ul class="check-list" *ngIf="priceItem">
                                        <li>
                                            <span class="icon red">&#x2718;</span>
                                            {{ 'provider_details.no_reviews' | translate }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tile tile-prices">
                                <div class="tile-title">
                                    <div class="wrap-line">
                                        <h5>
                                            {{ 'provider_details.prices_header' | translate }}
                                        </h5>
                                        <div stars [num]="priceItem.stats.prices.starsNum" class="stars">
                                        </div>
                                    </div>
                                    <div class="wrap-link">
                                        <a>{{ 'provider_details.price_stars_desc_p' | translate }}</a>
                                    </div>
                                </div>
                                <div class="tile-content tile-indicator_bar">
                                    <div class="wrap-indicator">
                                <span>
                                    {{ 'provider_details.below_average' | translate }}
                                </span>
                                        <div [ngClass]="belowAveragePricesBarClasses"
                                             *ngIf="priceItem.numOfPricesBelowAverage"></div>
                                        <b>{{priceItem.numOfPricesBelowAverage}}</b>
                                    </div>
                                    <div class="wrap-indicator">
                                <span>
                                    {{ 'provider_details.about_average' | translate }}
                                </span>
                                        <div [ngClass]="aboutAveragePricesBarClasses"
                                             *ngIf="(priceItem.workItems.length - priceItem.numOfPricesBelowAverage - priceItem.numOfPricesAboveAverage)"></div>
                                        <b>{{priceItem.workItems.length - priceItem.numOfPricesBelowAverage - priceItem.numOfPricesAboveAverage}}</b>
                                    </div>
                                    <div class="wrap-indicator">
                                <span>
                                    {{ 'provider_details.above_average' | translate }}
                                </span>
                                        <div [ngClass]="aboveAveragePricesBarClasses"
                                             *ngIf="priceItem.numOfPricesAboveAverage"></div>
                                        <b>{{priceItem.numOfPricesAboveAverage}}</b>
                                    </div>
                                </div>
                            </div>
                            <div class="tile tile-on_time">
                                <div class="tile-title">
                                    <div class="wrap-line">
                                        <h5>
                                            {{ 'provider_details.guarantee_header_p1' | translate }}
                                            {{ 'provider_details.guarantee_header_p2' | translate }}
                                        </h5>
                                    </div>
                                    <div class="wrap-link">
                                        <a (click)="showGuaranteeDesc()">{{ 'provider_details.guarantee.show_details_link' | translate }}</a>
                                    </div>
                                </div>
                                <div class="tile-content tile-indicator_bar">
                                    <ul class="check-list" *ngIf="priceItem">
                                        <li *ngIf="priceItem.guarantee.datesInContract == ProjectGuaranteeDatesInContract.none">
                                            <span class="icon red">&#x2718;</span>
                                            {{ 'provider_details.guarantee.dates_in_contract.none' | translate }}
                                        </li>
                                        <li *ngIf="priceItem.guarantee.datesInContract == ProjectGuaranteeDatesInContract.bigProject">
                                            <span class="icon green">✓</span>
                                            {{ 'provider_details.guarantee.dates_in_contract.big_project' | translate }} {{priceItem.currencySign}}&nbsp;{{priceItem.guarantee.projectGuaranteeDatesInContractPriceThreshold | number:'1.0-0'}}
                                        </li>
                                        <li *ngIf="priceItem.guarantee.datesInContract == ProjectGuaranteeDatesInContract.always">
                                            <span class="icon green">✓</span>
                                            {{ 'provider_details.guarantee.dates_in_contract.always' | translate }}
                                        </li>
                                        <li *ngIf="!priceItem.guarantee.weeklyPenalty">
                                            <span class="icon red">&#x2718;</span>
                                            {{ 'provider_details.guarantee.weekly_penalty.no' | translate }}
                                        </li>
                                        <li *ngIf="priceItem.guarantee.weeklyPenalty">
                                            <span class="icon green">✓</span>
                                            {{ 'provider_details.guarantee.weekly_penalty.yes' | translate:{
                                            weeklyPenalty: priceItem.guarantee.weeklyPenalty * 100,
                                            maxPenalty: priceItem.guarantee.maxPenalty * 100
                                        } }} {{priceItem.currencySign}}&nbsp;{{priceItem.totalPrice | number:'1.0-0'}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tile tile-services">
                                <div class="tile-title">
                                    <div class="wrap-line">
                                        <h5>
                                            {{ 'provider_details.services' | translate }}
                                        </h5>
                                        <div stars [num]="priceItem.stats.services.starsNum"
                                             *ngIf="priceItem.stats.services.num" class="stars">
                                        </div>
                                    </div>
                                </div>
                                <div class="tile-content tile-indicator_bar" *ngIf="priceItem.stats.services.num">
                                    <ul class="check-list">
                                        <li *ngFor="let featuredService of hasServices">
                                            <span class="icon green">✓</span>
                                            <span>{{featuredService.featuredText}}</span> {{featuredService.additionalServiceName}}

                                        </li>
                                        <li *ngFor="let featuredService of hasNotServices">
                                            <span class="icon red">&#x2718;</span>
                                            {{featuredService.featuredText}} {{featuredService.additionalServiceName}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="tile-content tile-indicator_bar" *ngIf="!priceItem.stats.services.num">
                                    <ul class="check-list">
                                        <li>
                                            <span class="icon red">&#x2718;</span>
                                            {{ 'provider_details.no_services' | translate }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gallery-part">
                    <span class="d-sm-none">
                        {{ 'provider_details.pics_header' | translate }}
                    </span>
                    <div class="gallery" *ngIf="priceItem">
                        <div class="slider" *ngIf="!isMobile">
                            <gallery-light class="item-photo" providerId="{{priceItem.providerId}}" mode="details"
                                           style="width: 100%;">
                            </gallery-light>
                        </div>
                        <div class="slider" *ngIf="isMobile">
                            <gallery-light class="item-photo" providerId="{{priceItem.providerId}}" mode="details"
                                           height="160px" style="width: 100%;">
                            </gallery-light>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
