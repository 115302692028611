import { Component, OnInit, Inject, ViewChild, ElementRef, afterNextRender, inject, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {Location as GoogleMapsLocation, Appearance, MatGoogleMapsAutocompleteDirective} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { loadavg } from 'os';
import { Subject, takeUntil } from 'rxjs';

import { DisplayService } from '../../shared/display.service';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { MasterWorkAreaSelectDto } from '../../shared/workareas/MasterWorkAreaSelectDto';
import { WorkAreaSelectDto } from '../../shared/workareas/WorkAreaSelectDto';
import { LocationService } from '../../shared/locations/location.service';
import { Location } from '../../shared/locations/Location';
import { LocationSource } from '../../shared/locations/LocationSource';
import { WorkAreasSelectComponentBase } from './workareas-select-base';
import { ProjectService } from '../../shared/projects/project.service';
import { ProvidersSorting } from '../../shared/projects/ProvidersSorting';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { InitData } from '../../shared/initData';
import { ActionTracker } from '../../shared/projects/ActionTracker';
import { LandingParamsService } from '../../shared/landingParams/landing-params.service';
import { ProjectMeta } from '../../shared/projects/ProjectMeta';
import { AnalyticsService } from '../../shared/analytics.service';
import { GroupedMwaWithWorkItems } from '../../shared/projects/GroupedMwaWithWorkItems';
import { AbTestsService } from '../../shared/abTesting/abTestsService';
import { InitDataService } from '../../shared/init-data.service';
import { RenoRouter } from '../../shared/RenoRouter';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditRefAreaModalComponent } from './edit-refarea-modal.component';

@Component({
    selector: 'workareas-select-side',
    templateUrl: './workareas-select-side.component.html'
})
export class WorkAreasSelectSideComponent extends WorkAreasSelectComponentBase implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    LocationSource: typeof LocationSource = LocationSource;   //hack for enum to work
    ProvidersSorting: typeof ProvidersSorting = ProvidersSorting;   //hack for enum to be available in the template
    public masterWorkAreas: MasterWorkAreaSelectDto[] = [];
    public currentSortingName: string;
    sorting: ProvidersSorting = ProvidersSorting.Overall;
    private subToWorkAreasTry1: boolean = true;
    public workItemsCount: number = 0;
    public selectedMwaIcons: string[] = [];
    numOfSelectedOffers: number = 0;
    masterWorkAreasNum?: number = undefined;
    private readonly injector = inject(Injector);

    currentLocationName: string = '';
    @ViewChild('addressSearchControl', { static: false }) addressSearchControl!: MatGoogleMapsAutocompleteDirective;
    public appearance = Appearance;
    public zoom: number;
    center: google.maps.LatLngLiteral  = {
        lat: 0,
        lng: 0,
    };
    mapOptions = {
        zoomControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        maxZoom: 15,
        minZoom: 8,
      };
    public selectedAddress!: PlaceResult;

    loading: boolean = false;
    showSqmSelector: boolean = false;
    showWorkAreasSelector: boolean = false;
    showAddressSelector: boolean = false;
    showWholeWidget: boolean = true;
    showTitle: boolean = false;
    showTitleMobile: boolean = false;
    haveLandingText: boolean = false;
    pricesWereLoadedOnce: boolean = false;

    step1Completed: boolean = false;
    step2Completed: boolean = false;
    step3Completed: boolean = false;
    step4Completed: boolean = false;

    landingHeader: string | null = null;
    editsSinceCreation: number = 0;

    showCart: boolean = false;
    tabShown: boolean = false;

    workItem1Name: string = '';
    workItem1Desc: string = '';
    workItem2Name: string = '';
    workItem2Desc: string = '';

    showMap: boolean = false;

    editRefAreaModalRef!: BsModalRef;

    constructor(workAreasService: WorkAreasService, translate: TranslateService, locationService: LocationService,
        private renoRouter: RenoRouter, projectService: ProjectService, private displayService: DisplayService, galleryService: GalleryService,
        initDataService: InitDataService,
        private activatedRoute: ActivatedRoute, actionTracker: ActionTracker, private landingParamsService: LandingParamsService,
        private analyticsService: AnalyticsService, private abTestsService: AbTestsService, private modalService: BsModalService) {
        super(workAreasService, projectService, galleryService, translate, locationService, actionTracker);
        let initLocation = initDataService.getInitialData().location;
        this.center = {
            lat: initLocation.latitude,
            lng: initLocation.longitude,
        };
        this.currentSortingName = 'Top Picks';  //TODO: localize
        this.subscribeToProviderSorting();
        this.zoom = 11;
    }

    ngOnInit(): void {
        this.loadTranslations();
        this.subscribeToRenovationArea();
        this.subscribeToWorkAreas();
        this.subscribeToCurrentLocation();
        this.subscribeToPrices();
        this.subscribeToSelectedOffers();
        this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            let tab = params['tab'];
            this.tabShown = !!tab;
            switch (tab) {
                case 'area':
                    this.openSqmSelector();
                    break;
                case 'workareas':
                    this.openWorkAreasSelector();
                    break;
                case 'location':
                    this.openAddressSelector();
                    break;
            }
        });
        this.loadLandingParams();
    }

    private subscribeToSelectedOffers(): void {
        this.projectService.selectedOffers.pipe(takeUntil(this.ngUnsubscribe)).subscribe(offers => {
            this.showCart = offers && !!offers.length;
            this.numOfSelectedOffers = offers.length;
        });
    }

    private loadLandingParams() {
        let landingParams = this.landingParamsService.getLandingParams();
        this.haveLandingText = !!landingParams.text1;
        this.landingHeader = landingParams.text1;
    }

    private subscribeToWorkAreas(): void {
        if (!this.displayService.isBrowser()) {
            return;
        }

        this.workAreasService.workAreas.subscribe(workAreas => {
            this.masterWorkAreas = [];
            this.translate.get('DONE').subscribe((res: string) => {
                let i = 0;
                for (; i < workAreas.length; ++i) {
                    workAreas[i].collapseBtnText = workAreas[i].selected ? this.editText: this.doneText;
                    workAreas[i].collapsed = true;
                    this.masterWorkAreas.push(workAreas[i]);
                }
                if (this.masterWorkAreas.length === 0) {
                    if (this.subToWorkAreasTry1) {  //to be able to open /search-results from a GET request
                        this.subToWorkAreasTry1 = false;
                        this.workAreasService.updateWorkAreasToSelect().then(res => {
                            if (this.locationService.getCurrentLocation().serviceAvailable) {
                                this.projectService.updatePricesForCurrentProject().then(res => {
                                    if (res) {
                                        if (this.renoRouter.url.indexOf('/search-results') === -1) {
                                            this.renoRouter.navigateByUrl('/search-results');
                                        }
                                    } else {
                                        this.renoRouter.navigateByUrl('/comparison');
                                    }
                                });
                            } else {
                                this.renoRouter.navigateByUrl('/search');
                            }
                        });
                    } else {
                        this.renoRouter.navigateByUrl('/search');
                    }
                }
            });
        });
    }

    private subscribeToProviderSorting(): void {
        this.projectService.currentSorting.subscribe(sorting => {
            this.sorting = sorting;
            let sortingKey = 'search_results.';
            switch (sorting) {
                case ProvidersSorting.Price:
                    sortingKey += 'sort_by_price';
                    break;
                case ProvidersSorting.Overall:
                    sortingKey += 'sort_by_overall';
                    break;
                case ProvidersSorting.Reviews:
                    sortingKey += 'sort_by_reviews';
                    break;
            }
            this.translate.get(sortingKey).subscribe((res: string) => {
                this.currentSortingName = res;
            });
        });
    }

    private subscribeToPrices(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            if (dto.prices.length) {
                this.workItemsCount = dto.prices && dto.prices.length && dto.prices[0].workItems.length;
                if (!this.pricesWereLoadedOnce) {
                    this.pricesWereLoadedOnce = true;
                }
                let projectMeta = dto.projectMeta as ProjectMeta;
                this.showWholeWidget = this.shouldShowWidget(projectMeta.editsSinceCreation) && this.displayService.isBrowser();
                if (this.displayService.isBrowser()) {
                    this.showMap = true;    //hack for mobile browsers
                }
                let edits = this.editsSinceCreation = dto.projectMeta ? dto.projectMeta.editsSinceCreation : 0;
                this.loadCompletedSteps(projectMeta);
                this.showTitle = !this.displayService.isMobile() || (!this.haveLandingText && edits >= 5);
                this.showTitleMobile = this.displayService.isMobile() && !this.haveLandingText && edits >= 5;
                this.selectedMwaIcons = dto.topMwaIconsByPrices;
                this.masterWorkAreasNum = dto.masterWorkAreasNum;

                if (dto.topWorkItemsByPrices && dto.topWorkItemsByPrices.length) {  //TODO: refactor it, it's copypasted in OfferDetailsComponent
                    const wisSorted = dto.prices[0].workItems.sort((a, b) => {
                        return b.price - a.price;
                    });

                    let wi = dto.topWorkItemsByPrices[0];
                    this.workItem1Name = `${wi.workItemName}: ${wi.qty} ${wi.uomName}`;
                    this.workItem1Desc = wi.workItemDescription;
                    if (dto.topWorkItemsByPrices.length > 1) {
                        wi = dto.topWorkItemsByPrices[1];
                        this.workItem2Name = `${wi.workItemName}: ${wi.qty} ${wi.uomName}`;
                        this.workItem2Desc = wi.workItemDescription;
                    }
                }
            }
        });
    }

    private substr(str: string, num: number): string {
        let lngth = num > str.length ? str.length : num;
        return str.substring(lngth);
    }

    private loadCompletedSteps(projectMeta: ProjectMeta) {
        if (projectMeta && projectMeta.completedSteps && projectMeta.completedSteps.length) {
            if (projectMeta.completedSteps.indexOf(1) > -1) {
                this.step1Completed = true;
            }
            if (projectMeta.completedSteps.indexOf(2) > -1) {
                this.step2Completed = true;
            }
            if (projectMeta.completedSteps.indexOf(3) > -1) {
                this.step3Completed = true;
            }
            if (projectMeta.completedSteps.indexOf(4) > -1) {
                this.step4Completed = true;
            }
            //console.log('completedSteps:');
            //console.log(projectMeta.completedSteps);
        }
    }

    isContinueButtonEnabled() {
        return this.areaSqm !== undefined && (this.hasSmthSelected(this.masterWorkAreas));
    }

    async updatePrices() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        try {
            this.galleryService.unregisterGalleriesWithProviderId();
            await this.projectService.updateCurrentProjectAndPrices();
            this.analyticsService.pageView();
        } finally {
            this.loading = false;
        }
    }

    public sortProviders(sorting: ProvidersSorting): void {
        this.projectService.setProvidersSorting(sorting);
    }

    public showMobileWorkAreas() {
        this.workAreasService.setShowMobileWorkAreasSelect(true);
    }

    protected override onLocationChanged(newLocation: Location) {
        afterNextRender(() => {
            if (newLocation.address !== this.addressSearchControl.address) {
                this.addressSearchControl.address = newLocation.address;
            }
        }, { injector: this.injector });
        this.currentLocationName = newLocation.locationName || this.currentLocationName;
    }

    public getMasterWorkAreasDisplayList(): string {
        return this.workAreasService.getMasterWorkAreasDisplayList(60);
    }

    public setAreaSqmToTextBox(newArea: number) {
        this.areaSqm = newArea;
    }

    public doneAreaClick(gotoNextStep: boolean = false) {
        this.showSqmSelector = false;
        this.setAreaSqm(this.areaSqm);
        this.step2Completed = true;
        this.projectService.updateAreaAndPrices(this.areaSqm);
        if (gotoNextStep) {
            this.renoRouter.navigate([], { queryParams: { tab: 'workareas' } });
        } else {
            this.renoRouter.navigate([], { queryParams: { tab: null } });
            this.condense();
        }
    }

    public openSqmSelector() {
        this.showWholeWidget = true;
        this.showSqmSelector = true;
        this.showWorkAreasSelector = false;
        this.showAddressSelector = false;
        this.uncondense();
    }

    public openWorkAreasSelector() {
        this.showWholeWidget = true;
        this.showWorkAreasSelector = true;
        this.showSqmSelector = false;
        this.showAddressSelector = false;
        this.uncondense();
    }

    private openAddressSelector() {
        this.showWholeWidget = true;
        this.showAddressSelector = true;
        this.showSqmSelector = false;
        this.showWorkAreasSelector = false;
        this.step1Completed = true;
        this.uncondense();
        afterNextRender(() => {
            this.projectService.markStepCompleted(1);
        }, { injector: this.injector },    );
    }

    private shouldShowWidget(editsSinceCreation: number): boolean {
        return true;
    }

    scrollToProjectEdit(): void {
        this.renoRouter.navigateByUrl('/edit-project');
    }

    mapReady(/*map: google.maps.Map*/) {
        //this.map = map;
    }

    private refreshMap() {
        /*if (google) {
            google.maps.event.trigger(this.map, 'resize');
        }
        this.map.setCenter({ lat: this.latitude, lng: this.longitude });*/
    }

    public onAutocompleteSelected(place: PlaceResult) {
        function getLocationName(addressComponents: any[]): string | null {
            let types = ['locality', 'administrative_area_level_2', 'administrative_area_level_1'];
            for (let i = 0; i < types.length; ++i) {
                let components = addressComponents.filter(comp => comp.types.indexOf(types[i]) > -1);
                if (components.length > 0) {
                    return components[0].long_name;
                }
            }
            return null;
        }

        function getCountryId(addressComponents: any[]): string | null {
            let types = ['country'];
            for (let i = 0; i < types.length; ++i) {
                let components = addressComponents.filter(comp => comp.types.indexOf(types[i]) > -1);
                if (components.length > 0) {
                    return components[0].short_name;
                }
            }
            return null;
        }

        //verify result
        if (place.geometry === undefined || place.geometry === null || !place.address_components) {
            return;
        }

        let locationName = getLocationName(place.address_components);
        if (!locationName) {
            return;
        }
        let countryId = getCountryId(place.address_components);
        if (!countryId) {
            return;
        }

        this.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          }

        /*let autocomplete = new google.maps.places.Autocomplete(addressSearchNativeElement,
            {
                //types: ['address']
            });
        var circle = new google.maps.Circle({
            center: {
                lat: initData.location.latitude,
                lng: initData.location.longitude
            },
            radius: 1500000
        });
        autocomplete.setBounds(circle.getBounds());*/

        let newLocation = {
            locationName: locationName,
            address: place.formatted_address as string,
            country: countryId,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            serviceAvailable: false,
            source: LocationSource.SideWorkAreasSelector
        }
        this.locationService.setCurrentLocation(newLocation);
    }

    condense(): void {
        this.displayService.setCondensed(true);
    }

    uncondense(): void {
        this.displayService.setCondensed(false);
    }

    public doneWorkAreasClick(gotoNextStep: boolean = false): void {
        this.showWorkAreasSelector = false;
        this.updatePrices();
        if (gotoNextStep) {
            this.scrollToProjectEdit();
        } else {
            this.renoRouter.navigate([], { queryParams: { tab: null } });
            this.condense();
        }
    }

    public doneAddressClick(gotoNextStep: boolean = false): void {
        this.showAddressSelector = false;
        if (gotoNextStep) {
            this.renoRouter.navigate([], { queryParams: { tab: 'area' } });
        } else {
            this.renoRouter.navigate([], { queryParams: { tab: null } });
            this.condense();
        }
    }

    public openEditRefAreaModal(mwa: MasterWorkAreaSelectDto) {
        if (mwa.refArea) {
            this.editRefAreaModalRef = this.modalService.show(EditRefAreaModalComponent, { class: 'edit-projectworkitemoption-modal' });
            this.editRefAreaModalRef.content.mwaId = mwa.id;
            this.editRefAreaModalRef.content.mwaName = mwa.name;
            this.editRefAreaModalRef.content.area = Math.round(mwa.refArea);
            this.editRefAreaModalRef.content.areaIsLocked = mwa.refAreaIsLocked;
        }
    }
}
