$F_light: 300;
$F_regular: 400;
$F_medium: 500;
$F_bold: 700;
$F_black: 900;

$red: #ff0400;
$red-dark: #b92124;
$red-brown: #c55a10;
$orange: #ff7502;
$orange-light: #ff9600;
$yellow: #ffd929;
$yellow-dark: #ffbe00;
$green: #00b050;
$green-light: #a5e52b;
$green-light-extra: #f3fff9;
$green-dark: #159800;
$green-bright: #43df89;
$green-bright-medium: #55cb05;
$blue: #0177cc;
$blue-dark: #114989;
$blue-dark-gray: #3e5166;
$blue-light: #0077cc;
$blue-bright: #129fdd;
$blue-border: #cce1ff;
$blue-border-light: #e5edfc;
$blue-cream: #dbe9f7;
$blue-gray: #425366;
$blue-gray-light: #f0f4ff;
$blue-gray-dark: #899ab3;
$blue-bg-mobile: #b9cfe8;
$blue-border-mobile: #bfd4e7;
$blue-link: #8cb1cf;
$brown: #68501e;
$brown-dark: #4e3523;
$gray: #dde1e7;
$gray-light: #f7fbff;
$gray-medium: #bfbfbf;
$gray-dark: #7e808a;
/* later check $gray-dark/$dark-black, $gray-dark - old version, the another one - new. */
$gray-black: #595959;
$gray-blue: #696d75;
$black: #111a2f;
$black-dark: #000;
$black-blue: #0d2948;
$white: #fff;
$flesh: #fce7bf;
$bronze: #a37f2f;
$bronze-light: #f5c044;
