@import "../variables";

.top-banner_search {
  padding-bottom: 3rem;
  color: $white;
  background: url("../../../assets/img/home-banner-top.jpg") no-repeat center;
  background-size: cover;
  h1 {
    margin: 0;
    padding: 2.5rem 0;
    font-size: 2.6rem;
    background: rgba($black, 0.4);
    text-align: center;
  }
  .mobile-tabs {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0;
    li {
      height: 18px;
      width: 18px;
      margin: 0 0.4rem;
      a {
        display: inline-block;
        height: 100%;
        width: 100%;
        background: $white;
        border: 2px solid $green;
        border-radius: 50%;
        cursor: pointer;
      }
      &.active {
        a {
          background: $green;
        }
      }
    }
  }
  &-widget {
    align-items: stretch;
    margin: 6rem 0;
    color: $blue-dark;
    a {
      text-decoration: none;
    }
    .widget-block {
      padding: 0 5px;
      .wrap {
        height: 100%;
        padding: 0.7rem;
        background: rgba($white, 0.8);
        border: 1px solid $gray-dark;
        border-radius: 15px;
        .tiles {
          display: flex;
          &-block {
            display: flex;
            flex-direction: column;
            width: 30%;
            &:first-of-type {
              width: 64%;
              margin-right: 6%;
            }
            h6 {
              margin-bottom: .4rem;
              font-size: .85rem;
              font-weight: $F-regular;
            }
          }
          &-wrap {
            display: flex;
            flex-wrap: wrap;
          }
          .tile {
            flex: 1 1 auto;
            min-width: 30%;
            height: 26px;
            margin: 0.2rem 2%;
            padding: 0 1.2rem;
            background: $white;
            color: $green;
            font-weight: $F-bold;
            line-height: 26px;
            border: 1px solid $green;
            border-radius: 12px;
            cursor: pointer;
            &:hover,
            &.active {
              background: $green;
              color: $white;
            }
            &-full_width.tile {
              position: relative;
              display: flex;
              flex: 1 1 100%;
              max-width: 100%;
              align-items: center;
              justify-content: center;
              &:before {
                content: '';
                position: absolute;
                left: -22px;
                top: 18%;
                height: 64%;
                width: 1px;
                background-color: $gray-blue;
              }
              span {
                line-height: 1.2;
              }
            }
          }
        }
        h4 {
          margin-bottom: 1.4rem;
          font-size: 1.3rem;
          line-height: 1.3;
        }
        p {
          margin: 1rem 0.2rem;
          font-size: 0.9rem;
          line-height: 1.2;
          font-style: italic;
        }
        .btn-white-green {
          position: relative;
          width: 100%;
          height: 29px;
          font-weight: $F-medium;
          &:after {
            position: absolute;
            top: 10px;
            right: 10px;
          }
          &#dropdownMenuButton2 {
            text-align: left;
          }
          &:after {
            border-top-width: .4em;
            border-right-width: .25em;
            border-left-width: .25em;
          }
        }
        .dropdown-menu {
          width: 100%;
          padding: 0;
          overflow: hidden;
          font-size: .9rem;
          ul {
            margin: 0;
            padding: 0;
            strong {
              display: block;
              margin-top: .2rem;
              padding: .2rem .7rem;
              cursor: default;
            }
            &.list-countries {
              max-height: 200px;
              overflow-y: auto;
            }
          }
          .dropdown-item {
            padding: .2rem 1rem;
          }
        }
      }
      &.widget-button {
        .wrap {
          padding: 0;
          overflow: hidden;
          .btn {
            height: 100%;
            width: 100%;
            padding: 1.7rem .7rem;
            font-size: 1.8rem;
            line-height: 1.3;
            white-space: normal;
          }
        }
      }
    }
  }
}

.wrap-providers {
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    h2 {
      margin: 0;
    }
  }
}

.providers {
  h2 {
    margin-bottom: 1rem;
  }
  .form-control {
    height: 48px;
    background: $gray-light;
    color: $black;
    font-size: 1.1rem;
    border-left-width: 0;
    &:focus {
      border-color: $gray;
      box-shadow: none;
    }
    &::-webkit-input-placeholder {
      color: $black;
    }
    &::-moz-placeholder {
      color: $black;
    }
    &:-ms-input-placeholder {
      color: $black;
    }
    &:-moz-placeholder {
      color: $black;
    }
  }
  .map {
    margin: 0.1rem 0 0;
    img {
      width: 100%;
    }
  }
}

.projects {
  h2 {
    margin: 3rem 0;
    color: $green-bright-medium;
    text-align: center;
  }
}

@media (max-width: 1199px) {

  .top-banner_search {
    padding-bottom: 1rem;
    h1 {
      padding: 2rem 0;
      font-size: 2.2rem;
    }
    &-widget {
      max-width: 600px;
      margin: 3rem auto 2rem;
      .widget-block {
        margin-bottom: 1rem;
        .wrap {
          padding-bottom: 1rem;
          h4 {
            margin-bottom: 1rem;
          }
        }
        &.widget-button {
          .wrap .btn {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }
        .dropdown {
          width: 200px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .top-banner_search {
    h1 {
      padding: 1.5rem 0;
      font-size: 1.6rem;
    }
  }
}

@media (max-width: 767px) {
  .top-banner_search {
    padding-bottom: 0;
    background-image: url("../../../assets/img/home-banner-top-mobile.jpg");
    h1 {
      padding: 1rem 0;
      font-size: 1.3rem;
    }
    .mobile-tabs {
      display: flex;
      margin-bottom: 0.7rem;
    }
    &-widget {
      margin: 0;
      padding: 0.7rem 0;
      .widget-block {
        display: none;
        height: 149px;
        &.two-lines {
          height: 181px;
        }
        &.active,
        &.widget-button {
          display: block;
        }
        &.widget-button {
          height: auto;
          .wrap {
            border-radius: 7px;
            .btn {
              padding-top: .3rem;
              padding-bottom: .3rem;
              &:disabled,
              &[disabled] {
                opacity: .4;
              }
              &.btn-green {
                font-size: 1.5rem;
              }
            }
          }
        }
        &:nth-of-type(3) {
          .dropdown {
            max-width: 300px;
            width: 100%;
          }
        }
        .wrap {
          display: flex;
          flex-direction: column;
          padding: 0.7rem;
          h4 {
            margin-bottom: .4rem;
            font-size: 1.2rem;
          }
          .tiles {
            flex-direction: column;
            &-block {
              width: 100% !important;
              &:first-of-type {
                margin: 0 0 .8rem;
              }
              &:last-of-type {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                &:before {
                  content: '';
                  position: absolute;
                  top: -.4rem;
                  left: 43%;
                  width: 14%;
                  height: 1px;
                  background-color: #696d75;
                }
                h6 {
                  margin-bottom: 0;
                  margin-right: .5rem;
                }
                .tile-full_width.tile {
                  margin-right: 0;
                  &:before {
                    display: none;
                  }
                }
              }
              h6 {
                min-width: 30px;
                margin-bottom: .3rem;
                font-size: .8rem;
              }
            }
            .tile {
              flex-basis: 56%;
              max-width: 56%;
              margin: .2rem 2%;
              padding-left: .3rem;
              padding-right: .3rem;
              &:nth-of-type(odd) {
                flex-basis: 40%;
                margin-left: 0;
              }
              &:nth-of-type(even) {
                margin-right: 0;
              }
            }
          }
          p {
            min-height: 35px;
            margin: 0.3rem 0.2rem 1rem;
          }
        }
      }
    }
  }

  .projects {
    h2 {
      margin: 2rem 0;
    }
  }
}

@media (max-width: 575px) {
  .top-banner_search-widget .widget-block .wrap {
    .tiles .tile {
      font-size: 0.95rem;
    }

  }

  .providers {
    .form-control {
      order: 0;
      height: 40px;
      font-size: 0.9rem;
      border-color: $blue-border-mobile;
      border-right: none;
      border-left-width: 1px;
    }
    .input-group-btn {
      order: 1;
      .btn {
        padding: 0.2rem 0.7rem;
        border-color: $blue-border-mobile;
        border-left: none;
        border-right-width: 1px;
        .fa {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .top-banner_search-widget .widget-block {
    height: 144px;
    &.two-lines {
      height: 176px;
    }
    .wrap {
      .tiles .tile {
        font-size: 0.9rem;
      }

      h4 {
        font-size: 1rem;
      }

      p {
        min-height: 52px;
      }
    }

    .wrap-providers .title {
      flex-direction: column;
      h2 {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media (max-width: 340px) {
  .top-banner_search-widget .widget-block {
    .wrap .tiles {
      &-block {
        h6 {
          font-size: .75rem;
        }
      }
      .tile {
        font-size: 0.85rem;
      }
    }
  }
}
