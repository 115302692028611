/* There are pages/components, which used: How it works, Workarea select */

/* Variables */

$Roboto: Roboto, Helvetica, Arial, sans-serif;

@import "variables";
@import "flags";
@import "buttons";

$F_light: 300;
$F_regular: 400;
$F_medium: 500;
$F_bold: 700;
$F_black: 900;

/* General */

body {
  color: $black;
  font-family: $Roboto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $F_bold;
}

ul,
ol {
  list-style: none;
}

h1 {
  margin: 1.5rem 0;
}

h2 {
  margin-top: 1.5rem;
  font-size: 2.2rem;
}

input {
  border-radius: 3px;
}

input.form-control {
  border-color: $gray;
  &::-webkit-input-placeholder {
    color: $gray-medium;
  }
  &::-moz-placeholder {
    color: $gray-medium;
  }
  &:-ms-input-placeholder {
    color: $gray-medium;
  }
  &:-moz-placeholder {
    color: $gray-medium;
  }
}

a {
  cursor: pointer;
}

a,
a:not([href]):not([tabindex]) {
  color: initial;
  text-decoration: underline;
}

i.fa {
  font-size: 1.5rem;
}

.dev-hidden {
  display: none !important;
}

.wrap-switch {
  display: flex;
  flex-direction: row;

  &__small {
    position: relative;
    top: 2px;
    img {
      width: 8px;
    }
    .switch {
      height: 14px;
      width: 30px;
      .slider {
        height: 10px;
        width: 10px;
      }
    }
  }
}

.switch {
  position: relative;
  width: 50px;
  height: 23px;
  margin: 0 0.6rem;
  padding: 2px;
  background-color: $yellow-dark;
  border-radius: 25px;
  .slider {
    width: 19px;
    height: 19px;
    top: 0;
    left: 0;
    background-color: $white;
    border-radius: 50%;
  }
  &.green {
    background-color: $green;
  }
}

.loading {
  position: relative;
  overflow: hidden;
  height: 100%;
  &:before {
    content: "\f1ce";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
    color: $white;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 3em;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    z-index: 2002;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: $blue-dark;
    opacity: 0.8;
    z-index: 2001;
  }
  &-small {
    @extend .loading;
    &:before {
      margin-top: -12px;
      margin-left: -12px;
      font-size: 24px;
    }
  }
}

.green-block {
  background-color: #f2fbf4;
  border-top: 1px solid #cbefd8;
  border-bottom: 1px solid #cbefd8;
}

.hidden {
  display: none !important;
}

.min-height {
  height: 60em;
}

.disabled {
  cursor: not-allowed !important;
}

.pointable {
  cursor: pointer;
}

.edit-popup-link {
  text-decoration: none !important;
}

.form-check {
  margin-bottom: 0.5rem;
  padding-left: 0;
}

agm-map {
  height: 300px;
}

.dropdown a.dropdown-item {
  text-decoration: none;
}

.title-strip-yellow {
  padding: 0.3rem 0;
  background-color: $yellow-dark;
  color: $white;
  text-align: center;
  h3 {
    margin-bottom: 0;
  }
}

.feedback {
  padding: 1rem;
  background: $white;
  color: $black;
  font-size: 0.9rem;
  text-align: center;
  border: 2px solid $gray-black;
  border-radius: 1.5rem;
  p {
    font-weight: $F-bold;
    line-height: 1.3;
    img {
      margin-right: 0.5rem;
      vertical-align: baseline;
    }
  }
  .author {
    color: $gray-dark;
  }
}

.top-banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: $white;
  text-align: center;
  .top-part {
    padding: 3rem 0;
  }
}

.back-arrow {
  display: none;
  cursor: pointer;
  margin: 0 0.3rem;
  padding: 0.3rem 0.4rem;
  .fa {
    margin-top: 5px;
  }
}

.back-arrow {
  display: block !important;
}

.tooltip {
  &-arrow {
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.popover {
  text-align: center;
  .popover-arrow.arrow {
    margin-top: -0.5rem;
  }
  &.bs-popover-left {
    margin-right: 0.7rem;
  }
  .btn-success {
    @extend .btn-blue;
    border-color: transparent;
    &:hover {
      background-color: $blue;
    }
  }
  .btn-default {
    @extend .btn-transparent-blue;
  }
}

.icon-info {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url("../../assets/img/icon-info.svg") no-repeat center;
  background-size: contain;
  &.medium {
    width: 20px;
    height: 20px;
  }
}

.stars {
  display: inline-flex;
  .star {
    position: relative;
    font-size: 1rem;
    &:before {
      content: "★";
    }
  }
  .star-big {
    font-size: 1.65rem;
    margin: 0 0.1rem;
  }
  .star-medium {
    font-size: 1.25rem;
    margin: 0 0.05rem;
  }
  .star-small {
    font-size: 14px;
  }
  .star-small-extra {
    font-size: 0.75rem;
  }
  .star-orange {
    color: $yellow-dark;
  }
  .star-blue {
    color: $blue;
  }
  .star-blue-dark {
    color: #294379;
  }
  .star-blue-transparent {
    color: #c9dbf1;
  }
  .star-bronze {
    color: $bronze;
  }
}

.title-green,
.title-flesh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  margin: 2rem 0;
  padding: 0.7rem 0.7rem 0.3rem;
  text-align: center;
  border-radius: 7px;
  h3 {
    margin-bottom: 0.1rem;
    font-size: 1.7em;
  }
  .subtitle {
    font-size: 1.1em;
  }
}

.title-green {
  background-color: $green;
  color: $white;
}

.title-flesh {
  background-color: $flesh;
  color: $brown-dark;
}

.title-green.top {
  min-height: auto;
  margin: 0;
  padding: 1.4rem 1rem;
  font-size: 2.3rem;
  border-radius: 0;
  &.font-small {
    font-size: 1.4rem;
  }
}

.check-list {
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.2rem;
    line-height: 1.3;
    span {
      margin-right: 0.5rem;
      text-transform: uppercase;
    }
    .icon {
      position: relative;
      top: -3px;
      min-width: 17px;
      width: 17px;
      font-size: 1.3rem;
      line-height: 1;
      text-align: center;
    }
    .green {
      color: $green;
    }
    .red {
      color: $red;
    }
    .dot {
      font-weight: $F-bold;
    }
  }
}

.fa-check {
  color: $green;
}

.btn-like-dropdown {
  position: relative;
  width: 100%;
  padding: 3px 25px 3px 10px;
  font-size: 0.9em;
  font-weight: $F-medium;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid $bronze;
  }
  .label {
    padding-right: 0.2rem;
    font-weight: $F-regular;
  }
}

.icon-link {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-left: 0.5rem;
  text-decoration: none !important;
  span {
    position: relative;
    left: -1px;
    padding: 0.1rem 0.3rem;
    background-color: $green;
    color: $white;
    font-size: 0.8rem;
    line-height: 1.3;
    font-weight: $F-medium;
    text-decoration: underline;
    white-space: nowrap;
  }
  &:hover {
    opacity: 0.8;
  }
  &.white {
    span {
      background-color: $white;
      color: $green;
    }
  }
  &.blue {
    span {
      background-color: #29417a;
    }
  }
  &.brown {
    span {
      background-color: #a48223;
    }
  }
}

.text-badge {
  margin-left: 0.1rem;
  padding: 0 0.25rem;
  background-color: $green;
  color: $white !important;
  font-size: 0.9rem;
  border-radius: 3px;
  white-space: nowrap;
  &.gray {
    background-color: #e0e6ec;
    color: $blue-dark !important;
  }
  &.blue {
    background-color: #4e728d;
  }
  &.orange {
    background-color: $yellow-dark;
  }
}

a.text-badge {
  text-decoration: none !important;
  &:hover {
    opacity: 0.8;
  }
}

/* GPDR-fixed */

.gpdr-fixed {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #eeeff3;
  .container {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .text {
    p {
      margin-bottom: 0;
      padding-right: 2rem;
      color: #96a1ac;
      font-size: 0.8rem;
      a {
        color: #6ea0bc;
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-width: 170px;
    a {
      text-decoration: none;
      &.btn {
        flex-grow: 1;
      }
      &.close {
        margin-top: 5px;
        margin-left: 1.8rem;
        color: $gray-black;
        font-size: 1.2rem;
      }
    }
  }
}

/* -GPDR-fixed */

/* Modals */

.modal-open .modal {
  padding-right: 0 !important;
  z-index: 2001;
}

.modal-backdrop.show {
  opacity: 0.65;
  z-index: 2001;
}

.modal-blue,
.modal-green {
  color: $black-blue;
  .modal-content {
    padding: 0 15px;
  }
  .modal-header {
    flex-direction: column;
    align-items: center;
    border-bottom: none;
    h4 {
      margin-bottom: 0.7rem;
    }
    p {
      margin-bottom: 0;
      font-size: 1rem;
      line-height: 1.2;
    }
  }

  .modal-footer {
    justify-content: center;
    border-top: none;
  }
  .actions {
    .btn {
      margin: 0 0.6rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      width: 105px;
    }
    .wrap-link {
      margin: 1.5rem 0 0.5rem;
      text-align: center;
      a {
        font-size: 1rem;
        font-weight: $F_bold;
      }
    }
  }

  b {
    text-transform: uppercase;
  }

  textarea {
    height: 200px;
    width: 100%;
    margin-top: 1rem;
    resize: none;
    border: 1px solid $black-blue;
    border-radius: 3px;
  }
}

.modal-blue {
  .quantity-block {
    .wrap-label {
      display: flex;
      align-items: center;
    }
    label {
      margin-bottom: 0;
    }
    input {
      height: 46px;
      width: 80px;
      margin-right: 0.5rem;
      padding: 0 1rem;
      border-radius: 3px;
      border: 2px solid $blue-dark;
    }
  }
  .type-block {
    margin-top: 30px;
  }
  .radio-block {
    margin-top: 1rem;
    &-row {
      display: flex;
      margin-bottom: 0.8rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      .form-check {
        margin-bottom: 0;
      }
      .col-6 {
        line-height: 1.2;
      }
    }
    label {
      font-weight: $F_bold;
      input {
        margin-left: -1.5rem;
      }
    }
  }
}

.modal-green {
  .modal-content {
    background-color: #83d29b;
    color: $white;
    .modal-header {
      padding-bottom: 0;
      p {
        font-size: 1.2em;
      }
      h4 {
        margin-bottom: 0;
        font-size: 1.4em;
      }
    }
    .modal-body {
      padding-bottom: 0;
    }
    .form-group {
      align-items: center;
      .col-form-label {
        flex: 0 0 20%;
        max-width: 20%;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 0.9rem;
        line-height: 1.2;
      }
      .col-form-input {
        flex: 0 0 60%;
        max-width: 60%;
        padding-left: 15px;
        padding-right: 15px;
      }
      .form-control {
        padding: 0.55rem 0.7rem;
        font-size: 0.95rem;
      }
      .term-cond {
        font-size: 0.85rem;
      }
    }
    .modal-footer {
      padding-top: 0;
      padding-bottom: 0.2rem;
      .form-group {
        flex-grow: 1;
        margin-bottom: 0.7rem;
      }
    }
  }
  .actions {
    .wrap-link {
      margin: 0;
      a {
        color: $white;
      }
    }
  }
  .divider {
    width: 98%;
    height: 1px;
    margin: 0.5rem auto 1rem;
    background-color: rgba($white, 0.5);
  }
  a {
    color: $white;
  }
  .btn-large {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* -Modals */

/* Header */

header {
  color: $white;
  background: $blue-dark;
  .navbar {
    padding: 0.2rem 0;
    &-brand {
      padding: 0;
      font-size: 0;
    }
    .mobile-menu-title {
      display: none;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
  }
  #navbarNav {
    justify-content: space-between;
  }
  a {
    text-decoration: none;
  }
  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.6rem;
    .dropdown {
      display: flex;
    }
    .nav-link,
    .dropdown-toggle {
      color: #f1f1f1;
      line-height: 1;
      text-decoration: none;
      &:hover {
        color: $white;
      }
    }
    &.account {
      .dropdown-menu {
        margin-top: 5px;
      }
    }
  }
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
  .links {
    padding-left: 1rem;
    li {
      margin: 0 1.5rem;
    }
  }
  .profile {
    .btn {
      font-size: 1rem;
    }
  }
}

/* -Header */

/* Footer */

footer {
  .top-part {
    padding: 0.8rem 0;
    background: $blue-dark;
    .nav {
      .nav-item {
        .nav-link {
          padding: 0.5rem 1.5rem;
          color: $white;
          font-size: 0.9rem;
          font-weight: $F-bold;
          text-decoration: none;
        }
        &:first-of-type {
          .nav-link {
            padding-left: 0;
          }
        }
      }
    }
  }
  .bottom-part {
    padding: 1.5rem 0;
    background: $blue-gray-light;
    .bottom-nav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &-column {
        float: left;
        width: 20%;
        padding-right: 0.5rem;
        &:last-of-type {
          padding-right: 0;
        }
        &-list {
          padding: 0;
          li {
            padding-bottom: 6px;
            line-height: 1.2;
            a {
              font-size: 0.85rem;
              text-decoration: none;
              &:hover {
                color: $orange;
              }
            }
          }
        }
      }
    }
    .title {
      margin-top: 1rem;
      font-size: 0.9rem;
      line-height: 1.3;
      text-align: center;
    }
  }
}

/* -Footer */

/* Custom style for radio */

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  line-height: 1.2;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  border: 4px solid $blue-dark;
  border-radius: 100%;
  background: $white;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: $blue;
  position: absolute;
  top: 50%;
  left: 6px;
  margin-top: -6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* -Custom style for radio */

/* Custom style checkbox */

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

[type="checkbox"]:not(:checked) + .checkbox-icon,
[type="checkbox"]:checked + .checkbox-icon {
  position: relative;
  display: inline-block;
  height: 17px;
  width: 17px;
  min-width: 17px;
  margin-right: 0.5rem;
  vertical-align: text-top;
  cursor: pointer;
}

.authorization [type="checkbox"]:focus + .checkbox-icon {
  outline: -webkit-focus-ring-color auto 5px;
}

/* checkbox aspect */
[type="checkbox"] + .checkbox-icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 3px;
}

[type="checkbox"]:not(:checked) + .checkbox-icon:before {
  background: $flesh;
}

[type="checkbox"]:checked + .checkbox-icon:before {
  background: $white;
}

[type="checkbox"] + .checkbox-icon.green:before {
  background: $green-light-extra;
  border: 1px solid $green;
}

/* checked mark aspect */

[type="checkbox"]:checked + .checkbox-icon:after {
  content: "\f00c";
  position: absolute;
  top: 2px;
  left: 1.5px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 0.9rem;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $bronze;
  transition: all 0.2s;
}

[type="checkbox"]:checked + .checkbox-icon.green:after {
  color: $green;
}

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + .checkbox-icon:before,
[type="checkbox"]:disabled:checked + .checkbox-icon:before {
  box-shadow: none;
  border-color: $gray-dark;
  background-color: $gray;
}

[type="checkbox"]:disabled:checked + .checkbox-icon:after {
  color: $gray;
}

[type="checkbox"]:disabled + .checkbox-icon {
  color: $white;
}

/* -Custom style checkbox */

/* How it works, Workarea select */

.work-details {
  .wrap-form-check {
    margin-bottom: 0.8rem;
    .form-check {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 50px;
      margin: 0;
      padding: 0.3rem 0.625rem;
      z-index: 2;
      color: $black;
      background: $white;
      border-radius: 3px;
      &.active {
        background: $yellow-dark;
        .btn {
          visibility: visible;
        }
      }
      &.main {
        box-shadow: 0 3px 0 0 $gray;
        label {
          width: calc(100% - 70px);
          word-break: break-word;
        }
        &.active {
          box-shadow: none;
          & + .collapse {
            .card {
              box-shadow: 0 3px 0 0 $gray;
            }
          }
        }
      }
      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.2rem;
        cursor: pointer;
        font-size: 0.9rem;
        font-weight: $F_bold;
        line-height: 1.3;
        text-transform: uppercase;
        img {
          width: 35px;
          max-height: 30px;
          margin: 0 0.7rem 0 0.2rem;
        }
      }
      .btn {
        visibility: hidden;
        min-width: 65px;
        transition-duration: 0s;
        line-height: 1.6;
      }
      .checkbox-icon {
        $size: 14px;

        height: $size;
        width: $size;
        min-width: $size;
        &:before {
          height: $size;
          width: $size;
        }
        &:after {
          top: 1px;
          left: 1px;
          font-size: 0.75rem;
        }
      }
    }
  }
  .collapse,
  .collapsing {
    position: relative;
    top: -5px;
  }
  .card.card-block {
    padding: 1rem 0.625rem;
    color: $black;
    background: $white;
    border-radius: 3px;
    p {
      margin-bottom: 0.3rem;
      padding-left: 0.3rem;
      font-size: 0.875rem;
      line-height: 1.2;
    }
    .form-check {
      min-height: auto;
      margin-top: 0.1rem;
      padding: 0.4rem 0;
      background: transparent;
      &.active {
        background: none;
      }
      label {
        font-size: 1rem;
        font-weight: $F_regular;
      }
    }
  }
}

/* -How it works, Workarea select */

/* Workarea select */

.sqm- {
  &block {
    max-width: 300px;
    margin: 0 auto;
    padding: 0 1.2rem;
  }
  &form {
    position: relative;
    color: $black;
    font-size: 1.4rem;
    #sqm-input {
      width: 100%;
      height: 48px;
      padding: 0 5rem 0 1rem;
      border: 1px solid $blue-border-light;
      box-shadow: 0 1px 0 0 rgba(105, 106, 108, 0.2);
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    span {
      position: absolute;
      top: 11px;
      right: 1rem;
      font-size: 0.9em;
    }
  }
  &values {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    div {
      margin-top: 10px;
      a {
        display: flex;
        height: 48px;
        width: 48px;
        align-items: center;
        justify-content: center;
        color: $black !important;
        font-size: 1.1rem;
        text-decoration: none !important;
        border: 1px solid $blue-border-light;
        border-radius: 3px;
        box-shadow: 0 1px 0 0 rgba(105, 106, 108, 0.2);
        &:hover {
          background: $yellow-dark;
          border-color: $yellow;
        }
        span {
          display: flex;
          align-self: center;
        }
      }
      &.active {
        a {
          background: $yellow-dark;
          border-color: $white;
        }
      }
    }
  }
}

.wrap-icon-info {
  .tooltip {
    width: 180px;
    font-size: 0.7rem;
    line-height: 1.3;
  }
}

.card {
  display: flex;
  flex-direction: column;
}

/* -Workarea select */

/* Join, Search */

.features {
  .feature {
    padding: 1.6rem 15px;
    .wrap,
    .row {
      height: 100%;
    }
    .wrap-img {
      img {
        max-width: 150px;
      }
    }
    .wrap-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 0 1rem;
      h5 {
        margin-bottom: 1rem;
        color: $green-bright-medium;
        font-weight: $F_bold;
        line-height: 1.3;
      }
      .btn {
        padding: 0 1.3rem;
        background-color: $green-bright-medium;
      }
    }
  }
  &-points {
    padding: 0.5rem 0;
    background-color: $green;
    color: $white;
    ul {
      padding: 0;
      li {
        position: relative;
        margin: 0.8rem 0;
        padding: 0 1rem 0 2.5rem;
        font-size: 0.9rem;
        line-height: 1.3;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: -5px;
          width: 22px;
          height: 22px;
          background: url("../../assets/img/icon-check.svg") no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}

/* -Join, Search */

/* Search result, Join */

.search-result-list {
  margin: 1.2rem 0;
  .item {
    margin-bottom: 1rem;
    .paddings {
      position: relative;
      display: grid;
      grid-template-rows: 166px;
      grid-template-columns: 166px 0.7fr 170px 1.6fr;
      grid-gap: 0;
      padding: 0.5rem;
      background: linear-gradient(to top, #f6f7f8 60%, $white);
      color: $blue;
      border: 1px solid #e4e4e4;
      &.project-listing {
        .item-envelope {
          background-image: url("../../assets/img/envelope-orange.svg");
          .personalize-text {
            display: block;
            position: absolute;
            bottom: 37px;
            left: 0;
            width: 100%;
            text-align: left;
            a {
              margin-left: 28px;
              color: $white;
              font-size: 11px;
              font-weight: $F-bold;
              text-decoration: underline;
              &:lang(it) {
                font-size: 10px;
              }
            }
          }
        }
      }
      &.standard-listing {
        .standard-text {
          display: block;
          position: absolute;
          left: 0;
          top: -6px;
          width: 100%;
          font-size: 1.6rem;
          font-weight: $F-bold;
        }

        .price-less {
          .standard-text {
            display: none;
          }
          .price-less-text {
            bottom: 14px;
          }
        }
      }
      &.landing {
        .landing-text {
          display: block;
          position: absolute;
          bottom: 36px;
          left: -3px;
          width: 172px;
          span {
            display: inline-block;
            padding: 2px 5px;
            background-color: $green;
            color: $white;
            font-size: 13px;
            line-height: 1;
            font-weight: $F-bold;
            border-radius: 2px;
          }
        }

        .price-less {
          .part-top {
            display: flex;
            align-items: center;
          }
          .landing-text {
            position: static;
            margin-left: 20px;
            width: calc(100% - 40px);
            span {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              padding-top: 0;
              padding-bottom: 0;
              background-color: transparent;
              color: $green;
              font-size: 0.9rem;
              font-weight: $F-bold;
            }
          }
          .price-less-text {
            display: none;
          }
        }
      }
      & > * {
        display: flex;
        flex-direction: column;
        h6 {
          margin-bottom: 0;
        }
      }
    }
    &-envelope {
      justify-content: space-between;
      position: relative;
      background: url("../../assets/img/envelope.svg") no-repeat center center;
      background-size: contain;
      text-align: center;
      z-index: 3;
      &.green-part-top {
        background-image: url("../../assets/img/envelope-green.svg") !important;
        .part-top {
          color: $white;
          font-size: 0.7rem;
          .price {
            position: static;
            color: $white;
          }
          b {
            margin-right: 0.2rem;
            text-transform: uppercase;
          }
        }
      }
      .part {
        &-top {
          position: relative;
          height: 54px;
          margin-top: 18px;
          line-height: 1.2;
          .price,
          .price-less-text {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: inline-block;
            margin-top: 1px;
            color: $blue-light;
            font-size: 1.9rem;
          }
          .price-less-text {
            font-size: 1.6rem;
          }
        }
        &-middle {
          position: absolute;
          top: 72px;
          width: 100%;
          .btn {
            padding: 1px 0.8rem;
            font-size: 0.7rem;
            line-height: 1.2;
            text-transform: uppercase;
            text-decoration: underline !important;
            border-color: #cfecd6;
            border-radius: 15px;
          }
        }
        &-bottom {
          position: absolute;
          bottom: 2px;
          left: 0;
          width: 100%;
          a {
            color: $white;
            font-size: 1.7rem;
            line-height: 1.3;
            font-weight: $F_bold;
          }
        }
      }

      .landing-text,
      .personalize-text,
      .standard-text {
        display: none;
      }
    }
    &-stars {
      grid-column: 2/2;
      padding: 0 0.3rem 0 1rem;
      line-height: 1.1;
      .item {
        &-prices,
        &-services,
        &-reviews {
          margin: 0.75rem 0;
        }
      }
      h6 {
        font-size: 0.9rem;
        font-weight: $F-regular;
      }
      .stars .star {
        margin-right: 1px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    &-provider {
      grid-column: 3/3;
      padding: 0.6rem;
      background-color: $white;
      color: #104988;
      border-top: 3px solid #466ea3;
      .provider {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 0.1rem;
        a {
          vertical-align: middle;
          color: #124988;
          text-decoration: underline !important;
        }

        &-verifications {
          display: flex;
          flex-direction: row;
          img {
            width: 56px;
            height: 56px;
            border-radius: 5px;
          }
          ul {
            padding: 0 0 0 5px;
            li {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 0.2rem;
              font-size: 9px;
              line-height: 1;
            }
            .check {
              margin-right: 0.2rem;
              color: #124988;
              font-size: 0.9rem;
            }
            a {
              position: relative;
              top: -2px;
              line-height: 1.2;
              font-style: italic;
            }
          }
        }
      }
    }
    &-gallery {
      grid-column: 4/4;
      border-top: 3px solid #426ea3;
      .ngx-gallery-icon .ngx-gallery-icon-content {
        font-size: 2rem;
      }
    }
    &-photo {
      position: relative;
      width: 100%;
    }
  }
}

.provider {
  &-info {
    margin: 0.6rem 0;
    padding-left: 2px;
    line-height: 1.1;
    a {
      margin-right: 0.3rem;
      color: #294379;
      font-weight: $F-bold;
    }
  }
  &-verified {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;
    img {
      width: 10px;
      margin-right: 0.4rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
}

/* -Search result, Join */

/* How it works, Providers details, Search result view */

.advantages {
  span {
    vertical-align: middle;
    padding-right: 0.5rem;
  }
  i {
    color: $blue-dark;
    &.green,
    &.green + span {
      color: $green-dark !important;
    }
  }
}

/* -How it works, Providers details, Search result view */

/* Article, Search */

ngx-gallery {
  display: flex;
}

.images-rounded {
  .ngx-gallery-thumbnail {
    border: none !important;
    border-radius: 14px;
  }
}

.general-slider {
  margin-top: 2rem;
  margin-bottom: 3rem;
  text-align: center;
  .wrap {
    position: relative;
    .top-notification {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.2rem 0.8rem;
      background-color: rgba($black, 0.2);
      color: $white;
      font-size: 0.8rem;
      font-weight: $F-medium;
      text-align: left;
      z-index: 1001;
    }
  }
  .wrap-slider {
    margin: 0 1.5rem;
    border: 2px solid $gray-medium;
  }
  &:nth-of-type(2n) {
    .wrap-border {
      margin: 0 0 0 0.5rem;
    }
  }
  .top-part {
    padding: 0.6rem 1rem;
    h4 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $blue;
      text-align: center;
      a {
        color: $blue;
      }
    }
    &.over-content {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      z-index: 1001;
      padding: 0.9rem 1rem 1.5rem;
      color: $white;
      line-height: 1.2;
      background: linear-gradient(
        to bottom,
        rgba($black-blue, 0.9) 40%,
        rgba($black-blue, 0.5) 70%,
        rgba($black-blue, 0.1) 90%,
        transparent
      );
      a {
        color: $white;
        font-size: 1.2rem;
        font-weight: 700;
        text-decoration: none;
      }
      .price {
        flex-shrink: 0;
        padding-right: 0.5rem;
        padding-left: 0.7rem;
        font-size: 1.4rem;
        font-weight: 700;
      }
    }
  }

  .ngx-gallery-arrow {
    .fa {
      font-size: 3.5rem;
    }
  }
  .ngx-gallery-arrow-right {
    .ngx-gallery-icon.ngx-gallery-arrow {
      right: 7px;
    }
  }
  .ngx-gallery-arrow-left {
    .ngx-gallery-icon.ngx-gallery-arrow {
      left: 7px;
    }
  }
  .ngx-gallery-remaining-count {
    font-size: 25px;
    font-weight: $F-light;
  }

  .ngx-gallery-preview-top {
    display: none;
  }

  .ngx-gallery-active .ngx-gallery-preview-top {
    display: block;
  }

  .bottom-part {
    padding: 0 3rem 0.5rem;
    .rating-row {
      display: flex;
      justify-content: space-between;
      margin: 0.7rem 0;
    }
  }
}

/* -Article, Search */

/* Faq, How it works */

.steps-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 1.5rem auto;
  .step {
    padding: 0.35rem 1.5rem;
    background: $white;
    color: $green;
    font-weight: $F-bold;
    border: 1px solid $green;
    border-radius: 3rem;
    cursor: default;
    &-active {
      padding: 0.45rem 2.8rem;
      font-size: 1.7em;
      background-color: $green;
      color: $white;
    }
    &-completed {
      opacity: 0.4;
      color: $gray-dark;
      border-color: $gray-dark;
    }
  }
  .btn {
    padding: 0.4rem 2rem 0.1rem;
    font-size: 1.5rem;
    line-height: 1;
    border-radius: 3rem;
    i {
      font-size: 0.8em;
    }
  }
}

/* -Faq, How it works */

/* How it works, Provider details, Search result */

.edit-block {
  h4 {
    margin: 1rem 0;
    color: #a5a5a5;
  }
  .list-block {
    margin-bottom: 1.5rem;
    color: #86734b;
    h6 {
      padding: 0.4rem 0;
    }
    h6,
    .title {
      color: $brown;
    }
    .title {
      margin-right: 0.4rem;
    }
    .top-part {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .remove {
      margin-left: 0.4rem;
    }
    .edit-popup-link {
      margin-left: 0.5rem;
      color: $black-blue;
      .fa {
        font-size: 1.1rem;
      }
    }
    ul {
      padding-left: 1.1rem;
      li {
        margin-bottom: 0.5rem;
        padding-left: 0.7rem;
        a {
          color: #a27f2e;
          font-size: 0.8rem;
        }
        img {
          position: relative;
          top: -1px;
          width: 11px;
          margin-right: 4px;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .quantity {
    display: none;
  }
  .add-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
  }
  .hide-add {
    margin: 0 0.2rem 0 0.3rem;
  }
  .option-labels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 1.3;
    .label {
      position: relative;
      padding: 0;
      font-size: 0.8rem;
      &:after {
        content: "";
        position: relative;
        display: inline-block;
        top: 15%;
        height: 70%;
        width: 1px;
        margin: 0 7px 0 4px;
        background-color: $black-blue;
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
      b {
        color: #86734b;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 0 3rem;
    .btn {
      flex: 0 0 auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      text-transform: uppercase;
      border-radius: 3rem;
      &:last-of-type {
        margin: 0 0.7rem 0 3rem;
      }
    }
    span {
      padding-right: 1rem;
      font-size: 0.8rem;
      line-height: 1.4;
    }
  }
}

/* -How it works, Provider details, Search result */

/* How it works, Workitems-view */

.list-block {
  h6 {
    margin-bottom: 0;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  ul {
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    list-style: disc;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

/* -How it works, Workitems-view */

/* Workitems-view */

.edit-projectworkitemoption-modal {
  color: $black-blue;

  .modal-header {
    flex-direction: column;
    align-items: center;
    border-bottom: none;

    h4 {
      margin-bottom: 0.7rem;
    }

    p {
      margin-bottom: 0;
      font-size: 0.8rem;
      line-height: 1.2;
    }
  }

  .quantity-block {
    input {
      height: 40px;
      width: 80px;
      margin-right: 0.5rem;
      padding: 0 1rem;
      border-radius: 3px;
      border: 2px solid $blue-dark;
    }
  }

  .type-block {
    margin-top: 30px;
  }

  .actions {
    display: flex;
    justify-content: center;
    margin: 2rem 0 1rem;
    .btn {
      margin: 0 0.6rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      width: 105px;
    }
  }

  .modal-footer {
    justify-content: center;
    padding: 0.6rem 1rem;

    .locked-field {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 160px;
      b {
        margin-right: 0.6rem;
        color: $green;
        font-size: 0.75rem;
        text-transform: none;
      }

      &.unlocked {
        b {
          color: $yellow-dark;
        }
      }
    }

    p {
      flex: 1 1;
      margin-bottom: 0;
      padding: 0 1.5rem;
      color: #b2cade;
      font-size: 0.7rem;
      line-height: 1.3;
      text-align: center;
    }
  }

  .radio-block {
    margin-top: 1rem;

    &-row {
      display: flex;
      margin-bottom: 0.8rem;

      &:last-of-type {
        margin-bottom: 0;
      }
      .form-check {
        margin-bottom: 0;
      }
    }

    label {
      font-weight: $F_bold;

      input {
        margin-left: -1.5rem;
      }
    }
  }

  b {
    text-transform: uppercase;
  }
}

/* -Workitems-view */

/* Search result, Provider details */

.provider-docs {
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.6);
  color: $white;
  .modal-content {
    background: transparent;
    .smaller-italic {
      display: inline-block;
      padding-left: 0.3rem;
      font-weight: $F_light;
    }
    .modal-header {
      align-items: center;
      padding-bottom: 0;
      border: none;
      i {
        font-size: 1rem;
      }
      .close {
        margin-right: 0;
        padding: 0.5rem;
        color: $white;
        font-size: 2rem;
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

.offices-docs {
  h5 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: $F_regular;
    text-decoration: underline;
  }
  .content-block {
    margin-bottom: 2rem;
  }
  .location {
    margin-bottom: 0;
  }
  a {
    color: $white;
    text-decoration: none;
    img {
      padding-right: 0.5rem;
    }
    i {
      font-size: 0.9rem;
      @extend .smaller-italic;
    }
  }
  .services-row {
    .service-row {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.7rem 0;
      border-bottom: 1px solid $white;
      .free {
        margin-right: 0.7rem;
        color: $green;
        text-transform: uppercase;
      }
      div {
        &:first-of-type {
          min-width: 190px;
          font-size: 1.1rem;
          font-weight: $F_medium;
        }
        &:last-of-type {
          padding-right: 2rem;
        }
      }
    }
  }
}

/* -Search result, Provider details */

/* How it works, Provider details */

.indicator_bar {
  display: flex;
  height: 13px;
  margin-right: 0.5rem;
  background: $green;

  &.yellow {
    background: $yellow-dark;
  }
}

@for $i from 1 to 4 {
  .length-#{$i} {
    width: 25px * $i;
  }
}

/* -How it works, Provider details */

/* How it works, Provider details, Join, Search result */

.advantages.adv-column {
  flex-direction: column;
  &.adv-row {
    display: flex;
    flex-direction: row;
    .advantage {
      span {
        font-size: 0.75rem;
        line-height: 1.3;
      }
    }
  }
  .advantage {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.3rem;
    i {
      flex: 0 0 22px;
      margin-right: 0.2rem;
      text-align: center;
    }
    span,
    a {
      color: $blue-dark;
      font-size: 0.8rem;
      line-height: 1.2;
      text-align: left;
    }
    &-blue {
      align-items: inherit;
      span {
        padding-top: 4px;
        font-style: italic;
        a {
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
      span,
      i,
      a {
        color: $blue-light;
      }
    }
  }
  &.small {
    .advantage {
      i {
        flex-basis: 19px;
        font-size: 1.3rem;
      }
      span {
        padding-top: 2px;
      }
    }
  }
  &.small-extra {
    .advantage {
      i {
        flex-basis: 13px;
        padding-top: 2px;
        font-size: 0.9rem;
      }
      span {
        padding-top: 0;
        a {
          font-size: 0.6rem;
        }
      }
    }
  }
}

/* -How it works, Provider details, Join, Search result */

/* Comparison table, How it works, (.table-comparison) for feedback-project-status too */

.download-links {
  font-size: 1em;
  .download-link {
    text-decoration: none;
    &:first-of-type {
      margin-right: 2.5rem;
    }
    &_red {
      color: $red-dark;
    }
    &_green {
      color: $green-dark;
    }
    span {
      vertical-align: middle;
      padding-left: 0.3rem;
      font-size: 0.9rem;
      font-weight: $F-regular;
      white-space: normal;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

.table-comparison {
  color: $brown-dark;
  border: 1px solid fade_out($gray, 0.5);
  td,
  th {
    padding: 0.5rem 0.75rem;
    vertical-align: middle;
    text-align: center;
    border: none;
  }
  thead {
    th {
      color: $blue-dark;
      font-size: 1.2em;
      line-height: 1.3;
      border-bottom: none;
      a {
        display: inline-block;
        margin-bottom: 0.5rem;
        color: $blue-dark;
      }
    }
  }
  .price {
    color: $blue-dark;
    font-size: 1.2em;
    white-space: nowrap;
  }
  tbody {
    tr {
      background-image: linear-gradient(to right, $black 20%, $white 10%);
      background-position: bottom;
      background-size: 6px 1px;
      background-repeat: repeat-x;
    }
  }
  .download-links,
  .description {
    padding-left: 2rem;
    text-align: left;
  }
  .description {
    i {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
  .flesh-row {
    background: $flesh;
    td {
      padding: 0.3rem 0.75rem;
      text-align: center;
    }
    h5,
    h6 {
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
  .bg-none {
    background: none;
  }
  .size {
    width: 90px;
  }
}

/* -Comparison table, How it works, (.table-comparison) for feedback-project-status too */

/* Feedback provider score, My projects */

.Ftable {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  color: $blue-dark;
  line-height: 1.3;
  text-align: center;
  &-row {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &-cell {
    flex-grow: 1;
    padding: 0.8rem;
    overflow: hidden;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
    &.active {
      .cell-image {
        width: 50px;
        height: 50px;
        &:hover {
          filter: none;
        }
      }
    }
  }

  &.Ftable-stripped {
    .Ftable-row {
      &:not(.Ftable-header):nth-child(odd) {
        background-color: $green-light-extra;
      }
    }
  }
}

/* -Feedback provider score, My projects */

/* Search result, Registration */

.block-green {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 1.2rem;
  background-color: $green-light-extra;
  color: $green;
  text-align: center;
  ul {
    text-align: left;
    li {
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
      line-height: 1.2;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.shopping-cart {
  h4 {
    margin-bottom: 1rem;
  }
  ul {
    margin: 0.8rem 0;
    padding-left: 0;
    li {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      &.red {
        color: $red-brown;
        .price {
          background-color: $red-brown;
        }
      }
    }
    .price {
      margin-right: 0.4rem;
      min-width: 80px;
      padding: 0 4px;
      background-color: $green;
      color: $white;
      text-align: center;
      border-radius: 15px;
    }
    .wrap {
      flex: 1 1;
      span {
        margin-right: 0.5rem;
      }
    }
    a {
      display: inline-block;
      margin-right: 0.5rem;
      color: $green;
      font-size: 0.9em;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .btn {
    padding: 0.3rem 3rem;
    font-size: 1.3rem;
    line-height: 1.2;
    border-radius: 25px;
    text-decoration: underline !important;
  }
}

/* -Search result, Registration */

/* Registration, Comparison */

.advantages-check {
  margin: 0;
  li {
    text-align: left;
    .check {
      margin-right: 0.3rem;
    }
  }

  &_inline {
    padding-left: 0;
    li {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 0.5rem;
      .check {
        margin-right: 0.2rem;
      }
    }
  }
}

/* -Registration, Comparison */

/* Offer preview */

.title-green {
  display: none;
}

.navigation-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem;
  font-size: 0.9rem;
  a {
    color: $blue;
  }
}

.offer-preview {
  margin-bottom: 3rem;
  border: 1px solid $green;
  border-radius: 10px;
  overflow: hidden;
  .block-green {
    margin-bottom: 0;
    padding: 0.4rem 1rem;
    .advantages-check_inline {
      text-align: center;
      li {
        margin-bottom: 0;
      }
    }
  }
  .top-title {
    padding: 0.5rem;
    background-color: $green;
    color: $white;
    text-align: center;
    h5 {
      margin-bottom: 0;
    }
    a {
      color: $white;
    }
  }

  .table-part,
  .mail-part {
    margin: 1.5rem 0;
    padding: 0 0.8rem;
    color: #3f5265;
    font-size: 0.9rem;
  }

  .mail-part {
    h4 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 1.5rem;
    }
    ul {
      padding-left: 2rem;
      list-style: disc;
      li {
        padding-left: 0.4rem;
      }
    }
    a {
      color: #3f5265;
    }
    .alert-message {
      color: $red;
      font-size: 1rem;
    }
  }

  .table-part {
    margin-top: 2.5rem;
    h4 {
      img {
        margin-left: 0.4rem;
        vertical-align: bottom;
      }
    }
  }
}

.table-offer {
  margin-top: 1.5rem;
  color: #3f5265;
  text-align: left;
  .Ftable-row {
    position: relative;
    padding-left: 2rem;
    background-color: $white !important;
    &:before {
      content: "";
      position: absolute;
      top: -3px;
      left: 2rem;
      width: calc(100% - 2rem);
      height: 6px;
      background-color: #f2fdf7;
      z-index: 2;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    &.title-row {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-right: 0.75rem;
      background-color: #f2fdf7 !important;
      border: none;
      text-transform: uppercase;
      h6 {
        margin-bottom: 0;
      }
      &:before,
      & + .Ftable-row:before {
        display: none;
      }
    }
  }

  .Ftable-header {
    font-weight: $F-bold;
    text-transform: uppercase;
    span {
      display: none;
    }

    &:before {
      display: none;
    }
  }

  .Ftable-cell {
    width: 130px;
    padding: 0.5rem 0.75rem;
    &:first-of-type {
      width: calc(100% - 280px);
      padding-left: 0;
    }
    &.price {
      width: 150px;
    }
  }

  .price {
    line-height: 1.2;
    text-transform: uppercase;
    .red {
      color: $red;
      font-size: 0.85em;
      a {
        color: $red;
      }
    }
  }

  .description-mobile {
    display: none;
  }

  .text-badge {
    display: none;
  }
}

/* -Offer preview */
