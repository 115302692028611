@import "variables";

.nav-item.language {
  margin: 0.4rem;
  .flag {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  a {
    display: inline-block;
  }
  .dropdown {
    .dropdown-toggle {
      padding: 0.4rem;
    }
    &.show {
      .dropdown-toggle {
        background: $blue-light;
        span {
          color: $white;
        }
      }
    }
  }
  .dropdown-menu.languages-menu {
    min-width: auto;
    margin: 0;
    padding: 0.5rem 0 0.1rem;
    background: $blue-dark;
    border: none;
    border-radius: 0;
    div {
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 0.2rem;
        padding: 0 0.4rem;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .language {
    .dropdown {
      width: 100%;
      &.show {
        .dropdown-toggle {
          position: relative;
          z-index: 1001;
          background: $white;
          box-shadow: 0 2px 4px 0 rgba(13, 39, 50, 0.2);
        }
      }
      .dropdown-menu.languages-menu {
        position: absolute;
        width: 100%;
        padding: 0;
        background: $white;
        div {
          a {
            margin-bottom: 0;
            &:hover {
              opacity: 1;
              color: $white !important;
              background: $blue;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
