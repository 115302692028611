@import "../variables";

/* use for register too */

.authorization {
  max-width: 500px;
  margin: 0 auto;
  padding: 3rem 0;
  color: $blue-dark;
  background: $white;
  .top-part {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-bottom: 3px solid $yellow-dark;
    span {
      padding-left: 0.7rem;
      line-height: 1.2;
    }
  }
  @for $index from 3 through 5 {
    h#{$index} {
      margin-top: 0.8rem;
      text-align: center;
      font-weight: $F_regular;
    }
  }
  h3 {
    margin: 0 0 1.5rem;
  }
  p {
    padding: 0 0.5rem;
    font-size: 1.2rem;
    line-height: 1.2;
    text-align: center;
  }
  form {
    max-width: 320px;
    margin: 0 auto;
  }
  label {
    margin-bottom: 0.3rem;
    font-size: 1.2rem;
    cursor: pointer;
  }
  input {
    border-radius: 3px;
    border-bottom-color: $black-blue;
  }
  .form-group {
    .term-cond {
      font-size: 0.9rem;
    }
  }
  .form-check {
    label {
      font-size: 1rem;
    }
    a:not(.like) {
      color: $blue-dark;
    }
  }
  .input-padding {
    padding: 0 21px;
  }
  .more {
    margin-left: 33px;
  }
  .actions {
    text-align: center;
    a {
      color: $blue-dark;
    }
    .btn {
      width: 260px;
      margin-top: 1.5rem;
      padding: 0.5rem;
      white-space: normal;
      line-height: 1.2;
    }
  }
}

@media (max-width: 575px) {

  .authorization {
    padding: 2rem 0;
    .top-part {
      padding: 0.5rem 0;
    }
    p {
      padding: 0;
    }
    .actions {
      .btn {
        font-size: 1.2rem;
      }
    }
    .more {
      margin-left: 32px;
    }
    .form-check.inline {
      .wrap_img img {
        width: 130px;
      }
      ul {
        width: 145px;
        font-size: 0.7rem;
      }
      span {
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 340px) {

  .authorization {
    .form-check.inline .wrap_img img {
      width: 115px;
    }
  }
}
