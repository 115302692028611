@import "../variables";

.registration {
  margin: 1rem 0;
  &-block {
    border: 1px solid $green;
    border-radius: 8px;
    overflow: hidden;
  }

  .block-green {
    margin-bottom: 0;
    h4 {
      margin-bottom: 1rem;
    }
    .two-column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .shopping-cart {
      flex-basis: 55%;
    }
    .advantages-check {
      flex-basis: 42%;
    }
  }
  .shopping-cart ul {
    margin: 0;
    li {
      text-align: left;
      .check {
        margin-right: .3rem;
      }
    }
  }
  .info-row {
    padding: .35rem 1rem;
    background-color: #d5d9df;
    color: $white;
    font-weight: $F-bold;
    line-height: 1.2;
    text-align: center;
    &.red {
      background-color: #fbe4d4;
      color: $red-brown;
      a {
        color: $red-brown;
      }
    }
    a {
      color: $white;
      text-decoration: underline !important;
    }
  }
  form {
    padding: 2rem;
    color: $green;
    .form-group {
      align-items: center;
      margin-bottom: 1.2rem;
    }
    .col-form-label {
      font-size: .95rem;
      line-height: 1.2;
      font-weight: $F-medium;
      text-align: right;
    }
    .form-control {
      padding: .7rem;
    }
    .form-check {
      a {
        color: $green;
      }
    }
    .input-padding {
      margin-bottom: .6rem;
    }
    .actions {
      .btn {
        height: 80px;
        padding-left: 2rem;
        padding-right: 2rem;
        white-space: normal;
        font-size: 1.7rem;
        line-height: 1.2;
      }
      .second-step {
        display: none;
      }
    }
    .validation-block {
      display: none;
      flex-direction: row;
      align-items: center;
      .validation-image {
        margin-right: .9rem;
        img {
          width: 35px;
        }
        &-active {
          display: none;
        }
      }
      .form-control {
        flex-shrink: 0;
        flex-basis: 90px;
      }
      label {
        margin: 0 4rem 0 .8rem;
        line-height: 1.2;
        text-align: left;
        a {
          color: $green;
        }
      }
    }
    .validation-message {
      font-size: 1.6rem;
      line-height: 1.2;
      text-align: center;
      span {
        color: $red;
      }
    }
  }

  .mobile-preview,
  .mobile-name-extra-title {
    display: none;
  }

  .edit-block {
    margin-top: 2rem;
    .list-block {
      padding: .3rem;
    }
  }

  &.validation {
    form {
      .validation {
        &-block {
          display: flex;
        }
        &-message {
          display: block;
          &-error {
            display: none;
          }
        }
      }
      .actions {
        .first-step {
          display: none;
        }
        .second-step {
          display: flex;
        }
      }
    }
  }

  &.validation-result {
    form {
      .validation {
        &-image {
          &-active {
            display: inline;
          }
        }
        &-message {
          display: none;
          &-error {
            display: block;
          }
        }
      }
    }
  }
}

.go-back {
  margin: .3rem;
  a {
    color: $blue;
  }
}

@media (max-width: 1199px) {

  .registration form .validation-block label {
    margin-right: 1rem;
  }
}

@media (max-width: 991px) {

  .registration {
    .block-green .shopping-cart {
      flex-basis: 100%;
    }
    .advantages-check {
      padding: .6rem 2.5rem 1.5rem;
      color: $green;
      font-size: .8rem;
      li {
        margin-bottom: .3rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    form {
      padding: 0 1.5rem .5rem;
      .form-group {
        margin-bottom: 0;
      }
      .col-form-label {
        margin-top: 1rem;
        padding: 3px 15px;
        text-align: left;
        width: auto;
      }
      .validation-block {
        margin-top: 1rem;
      }
      .actions {
        p {
          padding-left: 0;
          text-align: center;
        }
        .validation-message {
          margin-bottom: .5rem;
        }
      }
    }
    &.validation {
      .form-group {
        &.edit-active {
          &.name-field {
            .surname {
              display: block;
            }
            .mobile-name-extra-title {
              display: none;
            }
          }
          .mobile-preview {
            display: none;
          }
          .form-control:not(.validation-input) {
            display: block;
          }
        }
      }
      .mobile-preview {
        display: block;
        margin-top: .3rem;
        span {
          color: $black-blue;
        }
        a {
          margin-left: .5rem;
          color: $gray;
          .fa {
            font-size: 1.2rem;
          }
        }
      }
      .form-control:not(.validation-input),
      .surname {
        display: none;
      }
      .mobile-name-extra-title {
        display: inline;
      }
    }
  }
}
@media (max-width: 575px) {

  .registration {
    margin: 0;
    &-block {
      border: none;
    }
    .block-green,
    .info-row {
      padding-left: .6rem;
      padding-right: .6rem;
    }
    .advantages-check {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .block-green h4 {
      margin-top: .5rem;
    }
    .info-row {
      font-size: .85rem;
    }
    .validation-block label {
      font-size: .8rem;
    }
    .edit-block {
      margin-top: 0;
    }
  }
}

@media (max-width: 450px) {

  .registration {
    form {
      padding: 0 1rem .5rem;
      .actions .btn {
        height: 74px;
        font-size: 1.5rem;
      }
    }
    .advantages-check {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
