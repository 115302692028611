@import "../variables";

.top-banner_faq {
  background-image: url("../../../assets/img/top-banner-faq.jpg");
  .top-part {
    padding: 15rem 0 0;
  }
}

.faq-content {
  padding: .5rem 0 5rem;
  h3, h6 {
    color: $green;
    text-align: center;
  }
  .steps-line {
    margin: 2.7rem auto 2rem;
    .step {
      display: flex;
      flex-direction: row;
      width: 17%;
      height: 45px;
      margin: 0 .5rem;
      padding: .15rem 1rem;
      font-size: .9rem;
      line-height: 1.4;
      overflow: hidden;
      &-active {
        flex-shrink: 0;
        width: 28%;
        height: auto;
        padding: .6rem .5rem;
        font-size: 1.4em;
        justify-content: center;
      }
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &_block {
    margin-top: 1.7rem;
    padding: 1rem 1.2rem;
    background-color: $gray-light;
    color: $gray-black;
    border: 1px solid #d0eddc;
    .block {
      margin-bottom: 1.8rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    h5 {
      font-size: 1.1rem;
    }
    ul {
      margin-bottom: 0;
      padding-left: 1.2rem;
      list-style-type: disc;
      li {
        margin-bottom: .3rem;
        padding-left: 2px;
        a {
          color: $blue !important;
        }
      }
      .green {
        float: left;
        color: $green;
        b {
          font-style: italic;
        }
      }
    }
  }
}

.service-use {
  @for $i from 1 to 7 {
    h#{$i} {
      color: $black;
      text-align: center;
    }
  }
  ul {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1199px) {

  .faq-content {
    .steps-line {
      .step {
        &-active {
          width: 32%;
        }
      }
    }
  }
}

@media (max-width: 991px) {

  .top-banner.top-banner_faq .top-part {
    padding: 12rem 0 0;
  }

  .faq-content {
    .steps-line {
      flex-direction: column;
      max-width: 350px;
      margin: 1.7rem auto 1.2rem;
      .step {
        width: 100%;
        height: auto;
        margin: 1px 0;
        padding: .1rem 1.2rem;
        font-size: .9rem;
        font-weight: $F-regular;
        border: none;
        span {
          text-decoration: underline;
          &.number {
            padding-right: .4rem;
            text-decoration: none;
          }
        }
        &-active {
          justify-content: flex-start;
          font-size: 1.3em;
          font-weight: $F-bold;
          span {
            text-decoration: none;
            &.number {
              position: relative;
              left: -1px;
              padding-right: 0;
            }
          }
        }
      }
    }
    &_block {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 575px) {

  .top-banner.top-banner_faq .top-part {
    padding: 8rem 0 0;
    .title-strip-yellow h3 {
      font-size: 1.2rem;
    }
  }

  .faq-content {
    .steps-line {
      margin-bottom: 1rem;
    }
    &_block {
      padding: 1.2rem 1.3rem;
      font-size: .9rem;
      .block {
        margin-bottom: 1.3rem;
      }
      h5 {
        font-size: .9rem;
      }
      ul {
        padding-left: .7rem;
      }
    }
  }
}

@media (max-width: 450px) {

  .faq-content {
    .steps-line {
      max-width: none;
      padding: 0 .8rem;
    }
  }
}
