import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { RoutingHelper } from './app-routing.module';
import { L10nService } from './shared/l10n/l10n.service';
import { RenoRouter } from './shared/RenoRouter';

@Component({
    selector: 'route-fallback-component',
    template: '',
})
export class RouteFallbackComponent implements OnInit {

    constructor(private renoRouter: RenoRouter, private activatedRoute: ActivatedRoute, private l10nService: L10nService) {
    }

    ngOnInit(): void {
        let curLang = this.l10nService.getCurrentLang();
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const currentRoute = this.activatedRoute.snapshot.url.map(segment => segment.path).join('/');
        //console.log(`RouteFallbackComponent.ngOnInit. url = ${this.activatedRoute.snapshot.url} curLang = ${curLang}. curRoute = ${currentRoute}`);
        if (this.isSupportedRoute(currentRoute)) {
            this.renoRouter.navigate([currentRoute], { queryParams });
        } else {
            this.renoRouter.navigate(['/search']);
        }
    }

    private isSupportedRoute(pathToCheck: string): boolean {
        if (!pathToCheck) {
            return false;
        }

        let baseRoutes = RoutingHelper.baseRoutes;
        const currentRouteSegments = pathToCheck.split('/');

        return baseRoutes.some(route => {
          if (!route.path) {
            return false;
          }
      
          const routeSegments = route.path.split('/');
      
          if (routeSegments.length !== currentRouteSegments.length) {
            return false;
          }
      
          return routeSegments.every((segment, index) => {
            return segment.startsWith(':') || segment === currentRouteSegments[index];
          });
        });
    }
}