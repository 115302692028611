<div class="modal-header text-center">
  <h3 class="modal-title mb-3 text-capitalize">{{ mwaName }}</h3>
  <p>
    {{ 'WORKAREAS_SELECTOR.edit_ref_area_modal.desc' | translate }}
  </p>
</div>
<div class="modal-body">
  <form>
    <div class="quantity-block">
      <div class="form-group d-flex justify-content-center align-items-center">
        <label for="quantity" class="text-uppercase mr-4 mb-0">
          <b>
            {{ 'edit_projectworkitemoption_modal.quantity' | translate }}
          </b>
        </label>
        <input type="number" class="form-control" min="1" [(ngModel)]="area" (change)="areaChanged()" name="fakename">
        <span>
          sqm
        </span>
      </div>
    </div>
  </form>
  <div class="actions" [ngClass]="{ 'loading':loading }" class="d-flex justify-content-center mt-4 mb-2">
    <button class="btn btn-transparent-blue btn-middle mr-3" (click)="close()">{{ 'edit_projectworkitemoption_modal.cancel' | translate }}</button>
    <button class="btn btn-blue btn-middle" (click)="save()">{{ 'edit_projectworkitemoption_modal.save' | translate }}</button>
  </div>
</div>
<div class="modal-footer modal-footer__switch" *ngIf="!areaIsLocked" (click)="areaIsLocked=true">
  <div class="locked-field unlocked d-flex align-items-center">
    <b class="text-capitalize">
      {{ 'edit_projectworkitemoption_modal.unlocked' | translate }}
    </b>
    <div class="wrap-switch">
      <img src="assets/img/unlock-orange.svg" width="12" alt="">
      <div class="switch">
        <div class="slider"></div>
      </div>
      <img src="assets/img/lock-blue.svg" width="12" alt="">
    </div>
  </div>
  <p>
    {{ 'WORKAREAS_SELECTOR.edit_ref_area_modal.unlocked_desc' | translate }}
  </p>
</div>
<div class="modal-footer modal-footer__switch" *ngIf="areaIsLocked" (click)="areaIsLocked=false">
  <div class="locked-field d-flex align-items-center">
    <b class="mr-4 text-capitalize">
      {{ 'edit_projectworkitemoption_modal.locked' | translate }}
    </b>
    <div class="wrap-switch">
      <img src="assets/img/unlock-blue.svg" width="12" alt="">
      <div class="switch green">
        <div class="slider pull-right"></div>
      </div>
      <img src="assets/img/lock-green.svg" width="12" alt="">
    </div>
  </div>
  <p class="text-center">
    {{ 'WORKAREAS_SELECTOR.edit_ref_area_modal.locked_desc' | translate }}
  </p>
</div>
