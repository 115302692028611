@import "../variables";

.prov-details {
  margin-top: .8rem;
  color: $black-blue;
  .title-strip-yellow {
    margin-bottom: .8rem;
    color: $black;
    font-size: 1.1rem;
    a {
      font-weight: $F-bold;
      &:hover {
        color: $black;
      }
    }
  }
  .block-green {
    padding: .8rem 0;
    background-color: $green;
    color: $white;
    a {
      color: $white;
    }
    .wrap-border {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: .7rem 1rem;
      font-size: 1.3rem;
      line-height: 1.1;
      border: 2px solid $white;
      border-radius: 7px;
      text-align: center;
      a {
        margin-bottom: .3rem;
      }
      small {
        font-size: 70%;
      }
    }
    .side-right {
      padding-left: 2rem;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: .2rem;
          .company {
            padding-left: .2rem;
            font-weight: $F-bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-decoration: none;
          }
          small {
            white-space: nowrap;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      p {
        margin-bottom: .5rem;
      }
      .icon-link {
        position: relative;
        top: -1px;
        margin-right: .3rem;
        span {
          padding-top: 1px;
          padding-bottom: 1px;
        }
      }
    }
  }
  .info {
    margin-top: .5rem;
    color: $blue-dark;
    border-top: 1px solid #d4f2e0;
    a {
      color: $blue-dark;
    }
    h4 {
      margin: 1rem 0;
      color: #a5a5a5;
    }
    .top-part.green-block {
      border: none;
    }
    .bottom-part {
      color: #4a5b6f;
    }
    .wrap {
      padding: .5rem 1rem;
    }
    .title {
      span {
        margin-right: .5rem;
      }
    }
    .advantages {
      .advantage {
        margin-right: 2.5rem;
      }
    }
    .offer {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      .price {
        color: $green;
        font-size: 2rem;
        font-weight: $F-bold;
      }
      .icon-link {
        top: 3px;
        span {
          text-transform: uppercase;
        }
      }
    }
    .characteristic {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      .tile {
        position: relative;
        width: 30%;
        padding: 0 3%;
        &:first-of-type {
          width: 27%;
          padding-left: 0;
        }
        &:last-of-type {
          width: 27%;
          padding-right: 0;
        }
        &:not(:last-of-type):after {
          content: '';
          position: absolute;
          right: 0;
          top: 10%;
          height: 70%;
          width: 1px;
          background: #acb6ca;
        }
        &-title {
          height: 65px;
          margin-bottom: 1rem;
          line-height: 1.4;
          h5 {
            display: inline-block;
            margin-bottom: 0;
            margin-right: .1rem;
            font-size: 1.25em;
            font-weight: $F-medium;
            line-height: 1;
          }
          .wrap-line {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: space-between;
            height: 44px;
          }
          .wrap-link {
            line-height: 1.2;
            text-align: left;
            a {
              color: #4a5b6f;
              font-size: .8em;
              font-weight: $F-regular;
            }
          }
        }
        &-reviews {
          .wrap-indicator {
            span {
              width: 70px;
              min-width: 70px;
            }
          }
        }
        &-prices {
          .wrap-indicator {
            span {
              width: 105px;
              min-width: 105px;
            }
          }
        }
        &-content {
          font-size: .8rem;
          p {
            margin-bottom: .5rem;
            line-height: 1.2;
          }
          b {
            font-size: 1.1em;
          }
          .wrap-indicator {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: .7rem;
            line-height: 1.2;
          }
          .check-list {
            margin-top: 19px;
          }
        }
      }
    }
    .edit-block .list-block,
    .check-list-group {
      padding-left: .3rem;
    }
  }
  .description {
    margin-bottom: 1rem;
    padding-left: .3rem;
    line-height: 1.4;
    .wrap-badge {
      display: inline-block;
      &:first-of-type {
        .text-badge {
          margin-left: 0;
        }
      }

      .text-badge {
        margin: 0 4px;
      }
    }
    .text-badge {
      color: $black-blue;
    }
  }

  .go-back {
    margin: 1.5rem 0;
    a {
      color: $blue;
    }
  }

  /* odp */

  .odp {
    &-top {
      position: relative;
      padding-bottom: 24px;
      background: linear-gradient(to top, #fdfdfd, #fefefe, $white);
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background: linear-gradient(to top, #dadde1 5%, #fdfdfd);
      }
      .wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: .8rem;
      }
      .left-part {
        display: flex;
        flex-direction: row;
        color: $green;
        text-align: center;
        .wrap-img {
          position: relative;
          margin-right: 2rem;
          img {
            width: 150px;
          }
          b {
            position: absolute;
            top: 14px;
            left: 0;
            width: 100%;
            font-size: 2.4rem;
          }

          &.selected {
            text-transform: uppercase;
            b {
              font-size: .6rem;
            }
            .wrap-btn {
              position: absolute;
              top: 110px;
              left: 0;
              width: 100%;
              text-align: center;
              .btn {
                padding: 0 .7rem;
                font-size: .7rem;
                line-height: 1.4;
                border-radius: 1rem;
                text-decoration: underline !important;
              }
            }
          }
        }
        .wrap-content {
          max-width: 200px;
          padding-top: .9rem;
          .btn {
            margin-bottom: .5rem;
            padding: .8rem .5rem;
            font-size: 20px;
            line-height: 1.2;
          }
          div {
            font-size: .9rem;
            line-height: 1.3;
            a {
              color: $green;
              font-size: .8em;
            }
          }
        }
      }
      .right-part {
        display: flex;
        flex-direction: row;
        .wrap-content {
          min-width: 230px;
          max-width: 250px;
          margin-right: 2rem;
          padding-top: 1rem;
          color: #294379;
          .provider-extra_info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-left: 2px;
          }
          .provider-info {
            line-height: 1.2;
            a {
              vertical-align: middle;
            }
          }
          .provider-rating {
            &_row {
              display: flex;
              flex-direction: row;
              align-items: center;
              b {
                font-size: 11px;
                margin-right: 3px;
              }
            }
            .stars {
              .star {
                font-size: 9px;
              }
            }
          }
          .advantages-check {
            padding-left: 0;
            font-size: 11px;
          }
        }
        .wrap-img {
          img {
            width: 130px;
            height: 130px;
            border-radius: 10px;
          }
        }
      }
    }

    &-tabs {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #fffaec;
      border-top: 2px solid #ffca4b;

      $top-block-height: 55px;
      $top-block-marginBottom: 12px;

      .part {
        &-left {
          width: 230px;
          margin-right: 1rem;
          text-align: center;
          .btn {
            width: 100%;
            height: $top-block-height;
            margin-bottom: $top-block-marginBottom;
            padding: 0 10px;
            font-size: 1.4rem;
            border-color: transparent;

            &.with-icon {
              text-align: left;
            }
            img {
              width: 42px;
              padding-top: 7px;
              padding-bottom: 7px;
              margin-right: 12px;
            }
          }
          .price {
            padding: 2px 0;
            background-color: $white;
            color: $green;
            font-size: 52px;
            font-weight: $F-bold;
            border-radius: 7px;
            white-space: nowrap;
          }
        }
        &-right {
          width: calc(100% - 230px);
          .preview-text {
            display: flex;
            align-items: center;
            height: $top-block-height;
            margin-bottom: $top-block-marginBottom;
            color: $brown;
            font-size: 1.4rem;
            font-weight: bold;
          }
          .project-details-list {
            margin-top: -1px;
            padding-top: 1rem;
            border-top: 1px solid $brown;
            .list-with-disc li,
            .list-with-disc li a {
              color: $brown;
            }
          }
        }
      }
    }
  }

  .provider-block {
    background-color: #fafbfd;
    border-top: 2px solid #416da2;
    .info {
      border: none;
    }
  }

  /* -odp */

  /* pep */

  $pep-space: 2rem;

  &.pep {
    .top-block {
      position: relative;
      margin-top: $pep-space;
      margin-bottom: $pep-space;
      padding-bottom: $pep-space;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background: linear-gradient(to top, $gray, $white);
      }
      .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
      .go-back {
        display: flex;
        flex-direction: column;
        margin: 0;
        .btn {
          min-width: 200px;
          margin-bottom: 5px;
          padding: 0 10px;
          font-size: 1.5rem;
        }
        span {
          color: $green;
        }
      }
      .benefits {
        position: relative;
        top: -3px;
        display: flex;
        flex-direction: row;
        margin-left: 24px;
        .wrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 30px;
          line-height: 1.2;
          &:first-of-type {
            color: #eaa700;
          }
          &:last-of-type {
            color: $green;
          }
          img {
            margin-right: .5rem;
          }
        }
      }
    }

    .info.info-new {
      position: relative;
      margin: $pep-space 0 2.4rem;
      color: #86734b;
      border: none;
      h4 {
        margin: 0 0 .5rem;
        color: $brown;
      }
      .edit-info {
        margin-bottom: $pep-space;
        .details {
          div {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-top: .2rem;
            line-height: 1.4;
          }
          b {
            margin-top: 1px;
            margin-right: .3rem;
            display: inline-block;
            width: 25px;
            min-width: 25px;
            color: $white;
            font-size: .85rem;
            text-align: center;
            border-radius: 3px;
            &.green {
              background-color: $green;
            }
            &.yellow {
              background-color: $yellow-dark;
            }
          }
        }
        .actions {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 1.3rem 0;
          .btn {
            margin: .2rem 1rem .2rem 0;
            padding: 2px 12px;
            font-size: .8rem;
            line-height: 1.4;
            text-transform: uppercase;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }

      .list-block {
        h6 {
          padding-top: 0;
        }
        p {
          font-size: 14px;
        }
      }

      .comments-uploads-part {
        .wrap-pictures,
        .wrap-textarea,
        .action {
          width: 80%;
        }
      }

      .block {
        &-pictures {
          .title-with-subtitle {
            margin-top: 0;
          }
        }
        &-comments {
          .wrap-textarea {
            textarea {
              width: 100%;
              min-height: 120px;
              padding: .7rem;
              border-color: #1f2e4d;
              border-radius: 3px;
              resize: none;
              vertical-align: bottom;
            }
          }
          .action {
            display: flex;
            flex-direction: row;
            padding: 1rem 0;
            border: 1px solid transparent;
            .btn {
              min-width: 100px;
              margin-right: 1.2rem;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }

      .comments-uploads-part {
        .project-uploads {
          border: 1px solid #1f2e4d;
          border-radius: 3px;
          overflow: hidden;
        }
      }
    }
  }

  .title-with-subtitle {
    margin: $pep-space 0 .5rem;
    color: $brown;
    line-height: 1.3;
    h4 {
      margin-bottom: .1rem;
    }
    span {
      font-size: 14px;
    }
  }

  /* -pep */

  /* pdp */

  &.pdp {
    .info.info-new {
      border: none;
    }
    .slider,
    .offices-docs {
      h5 {
        margin: 1rem 0;
        color: $blue-dark;
        font-size: 1.3rem;
      }
    }

    .offices-docs {
      color: $blue-dark;
      h5 {
        font-weight: $F-bold;
        text-decoration: none;
      }
      .content-block {
        margin-bottom: 3.5rem;
      }
      a {
        color: $blue-dark;
      }
    }
  }

  /* -pdp */

  /* tabs */

  $tab-color: #d2cbbc;
  $tab-color-dark: $brown;

  .tabs {
    .container {
      border-bottom: 1px solid $tab-color-dark;
    }
    &-menu {
      display: flex;
      flex-direction: row;

      .tab {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 21%;
        margin-right: 1%;
        padding: 14px 8px 4px;
        background-color: $white;
        color: $tab-color-dark;
        border: 1px solid $tab-color-dark;
        border-bottom-width: 0;
        border-radius: 14px 14px 0 0;
        text-decoration: none;
        opacity: .3;
        &:last-of-type {
          margin-right: 0;
        }
        &:hover,
        &-active {
          opacity: 1;
          &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $white;
          }
        }

        &-areas,
        &-comments {
          width: 29%;
        }

        .wrap-img {
          img {
            height: 27px;
            margin: 0 15px;
            filter: invert(0.5) sepia(1) saturate(2) hue-rotate(-10deg) brightness(0.5);
          }
        }
        .wrap-content {
          display: flex;
          flex-direction: column;
          margin-top: .3rem;
          line-height: 1.3;
          text-align: center;
        }

        &-action {
          font-size: 10px;
        }
      }
    }
  }

  &.odp-standard {
    $top-block-height: 35px;

    .odp-tabs {
      .part-left {
        .btn {
          height: $top-block-height;
        }
      }
      .part-right {
        .preview-text {
          height: $top-block-height;
        }
      }
    }
    .tabs {
      margin-bottom: .6rem;
      .container {
        border: none;
      }
      &-menu a.tab {
        border-bottom-width: 1px;
        border-radius: 14px;

        &:hover:after {
          display: none !important;
        }
      }
    }
  }

  /* -tabs */

  /* info new */

  .info.info-new {
    .info-part {
      margin-bottom: 3rem;
    }
    .gallery-part {
      margin-bottom: 3rem;
    }
    .info-verified {
      font-size: .9rem;
      img {
        height: 18px;
        margin-right: .3rem;
      }
      span {
        vertical-align: middle;
      }
    }
    .info-provider {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin: .3rem 0;
      .provider-name {
        margin-right: .3rem;
        font-size: 1.2rem;
        font-weight: $F-bold;
      }
    }
    .provider-line {
      margin-bottom: 1rem;
    }
    .description {
      margin-bottom: 2rem;
      padding-left: 0;
      font-size: .9rem;
    }
    .characteristic {
      .tile-title {
        height: 45px;
        .wrap-line {
          height: auto;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: .6rem;
          h5 {
            margin-right: .4rem;
            font-size: 1.1rem;
          }
        }
        .wrap-link {
          a {
            color: $blue-dark;
            font-size: .8rem;
          }
        }
      }
    }
    .advantages .advantage {
      margin-right: 0;
    }
  }

  /* -info new */

  h4 {
    margin: 1.5rem 0;
    color: #a5a5a5;
  }
}

.list-with-disc {
  padding-left: 1rem;
  list-style: disc;
  li {
    margin-bottom: .4rem;
    padding-left: .5rem;
    color: #3f5265;
    font-size: .9rem;
    p {
      margin-bottom: 0;
    }
    a {
      color: #3f5265;
    }
  }
}

.check-list-group {
  h6 {
    padding-top: .4rem;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  ul {
    margin-bottom: 1.5rem;
  }
  .check-list li .icon {
    margin-right: 12px;
    text-align: left;
  }
}

@media (max-width: 1199px) {

  .prov-details {

    /* odp */

    .odp-tabs {
      flex-direction: column;
      .part-left {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        width: auto;
        margin-right: 0;
        .btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto !important;
          margin: 0 0 0 1.5rem;
          padding: 0 2rem;

          &.with-icon {
            justify-content: flex-start;
            padding: 0 1.5rem 0 10px;
          }
          img {
            padding: 0;
          }

        }
        .price {
          padding: 0 2rem;
          font-size: 50px;
          line-height: 1.2;
        }
      }
      .part-right {
        width: 100%;
        margin-top: .6rem;
        .preview-text {
          justify-content: center;
          height: auto !important;
          margin: .3rem 0;
          font-size: 1.2rem;
        }
      }
    }

    /* -odp */

    /* tabs */

    .tabs {
      &-menu {
        .tab {
          width: 20%;
          margin-right: 1%;

          &-areas,
          &-comments {
            width: 29%;
          }
        }
      }
    }

    /* tabs */
  }
}

@media (max-width: 991px) {

  .prov-details {
    .info {
      .wrap {
        padding: .5rem 0;
      }
      .title {
        justify-content: space-between;
        h3 {
          font-size: 1.5rem;
        }
        .price {
          flex-shrink: 0;
          padding-left: 1rem;
          font-size: 2rem;
        }
      }
      .characteristic {
        flex-wrap: wrap;
        justify-content: flex-start;
        .tile {
          width: 50% !important;
          margin-bottom: 1rem;
          padding: 0 1.4rem 0 0;
          &:after {
            display: none;
          }
          .check-list {
            margin-top: 14px;
          }
        }
      }
    }
    .description {
      padding-left: 0;
    }

    /* odp */

    .odp-top {
      .wrap {
        flex-direction: column;
        align-items: flex-start;
        max-width: 400px;
        margin: 0 auto;
      }

      .left-part,
      .right-part {
        justify-content: space-between;
        width: 100%;
      }

      .left-part {
        margin-bottom: 1.2rem;
      }
      .right-part {
        .wrap-content {
          padding-top: 0;
        }
        .wrap-img img {
          width: 120px;
          height: 120px;
        }
      }
    }

    /* -odp */

    /* pep */

    &.pep .info.info-new .edit-info .actions {
      margin: .8rem 0;
    }

    /* -pep */

    /* pdp */

    &.pdp .info.info-new .characteristic .tile-services {
      padding-left: 1.4rem;
    }

    /* -pdp */

    /* tabs */

    .tabs {
      .container {
        border-bottom: none;
      }
    }

    .tabs-menu {
      flex-direction: column;
      max-width: 400px;
      margin: 0 auto;
      .tab {
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin: 0 0 .5rem;
        padding: .2rem .5rem;
        border-bottom-width: 1px;
        border-radius: 7px;
        &:hover:after,
        &-active:after {
          display: none;
        }
        .wrap-img img {
          margin: 0 .8rem 0 0;
        }
        .wrap-content {
          margin-top: 0;
          line-height: 1.3;
          text-align: left;
        }
        &-title {
          font-size: 1rem;
        }
      }
    }

    &.odp-standard {
      .tabs {
        &-menu {
          a.tab {
            border-radius: 8px;
          }
        }
      }
    }

    /* -tabs */
  }
}

@media (max-width: 767px) {
  .prov-details {
    margin: 0;
    .title-strip-yellow {
      margin: 0;
      padding: .5rem;
      line-height: 1.2;
    }
    .block-green {
      padding-top: .5rem;
      text-align: center;
      .wrap-border.side-left {
        padding: 0 0 .5rem;
        border: none;
      }
      .side-right {
        padding-left: 15px;
        ul li {
          justify-content: center;
          &:last-of-type {
            margin-bottom: .2rem;
          }
          .company {
            width: 250px;
            font-size: .9rem;
            text-align: left;
          }
        }
        .icon-link {
          margin-right: .5rem;
        }
      }
      .action {
        margin-top: .5rem;
        .btn {
          padding-top: .5rem;
          padding-bottom: .5rem;
        }
      }
    }
    .description {
      font-size: .9rem;
    }
    .info {
      border: none;
      h4 {
        margin: .5rem 0;
      }
      .top-part {
        background-color: transparent;
      }
      .characteristic .tile {
        &:nth-of-type(2n) {
          padding-left: 1.4rem;
          padding-right: 0;
        }

        &-reviews,
        &-prices {
          .tile-title {
            height: 40px;
            .wrap-line {
              height: auto;
            }
          }
        }
      }
      .offer {
        justify-content: center;
        padding: .7rem 0;
        background-color: #f5f9fe;
        .price {
          line-height: 1.2;
        }
      }
    }

    /* odp */

    .odp {
      &-top {
        .provider-line {
          .provider-name {
            color: $green;
            font-size: 1.2rem;
            font-weight: $F-regular;
            line-height: 1.2;
            a {
              color: $green;
              font-weight: $F-bold;
            }
          }
          .stars {
            margin-right: 0;
            .star {
              color: $green;
              font-size: .75rem;
            }
          }
        }
        .actions {
          margin-top: .6rem;
        }
      }
    }

    div.project-details-list {
      margin-bottom: 1.5rem;
      padding-top: 0;
      border: none;

      &:before {
        display: none;
      }
    }

    .provider-line {
      display: block;
      .stars {
        top: -1px;
        margin-right: 0;
        .star {
          font-size: .9rem;
        }
      }
    }

    /* -odp */

    /* pep */

    $pep-space-mob: 1.2rem;

    &.pep {
      .top-block {
        margin: $pep-space-mob 0;
        padding-bottom: 0;
        font-size: .9rem;
        &:after {
          display: none;
        }
        .container {
          flex-direction: row-reverse;
          flex-wrap: nowrap;
          justify-content: space-evenly;
        }
        .go-back {
          width: 130px;
          .btn {
            min-width: auto;
            font-size: 1.2rem;
            padding-left: 2px;
            padding-right: 2px;
          }
          span {
            line-height: 1.1;
          }
        }
        .benefits {
          top: 0;
          flex-direction: column;
          margin-left: 0;
          margin-right: 10px;
          .wrap {
            margin-left: 0;
            line-height: 1.4;
            &:first-of-type {
              color: $brown;
            }
            &:last-of-type {
              b {
                font-weight: $F_regular;
              }
            }
          }
          img {
            display: none;
          }
        }
      }
      .tabs {
        margin: $pep-space-mob 0;
      }
      .info.info-new {
        margin-top: 0;
        padding-top: $pep-space-mob;
        border: none;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 15px;
          background: linear-gradient(to bottom, $gray, $white);
        }
        .edit-info {
          margin-bottom: $pep-space-mob;
        }
      }
    }

    .title-with-subtitle {
      margin: $pep-space-mob 0 .3rem;
      span {
        font-size: .8rem;
      }
    }

    /* -pep */

    /* pdp */

    &.pdp {
      .provider-block {
        border-top: none;
      }
      .info.info-new {
        .description {
          margin: .8rem 0;
        }
        .characteristic .tile-services {
          padding-left: 0;
        }
      }
      .offices-docs {
        h5 {
          margin: .7rem 0;
        }
        .content-block {
          margin-bottom: 2.5rem;
        }
      }
    }

    /* -pdp */

    /* tabs */

    .tabs {
      margin-bottom: 1.2rem;
      &-menu {
        .tab {
          &:last-of-type {
            margin-bottom: 0;
          }
          .wrap-img img {
            margin-right: .6rem;
          }
          &-title {
            font-size: .9rem;
          }
          &-action {
            font-size: .6rem;
          }
        }
      }
    }

    &.odp-standard {
      .tabs {
        margin-bottom: .5rem;
      }
    }

    /* -tabs */

    /* info new */

    .info.info-new {
      .provider-line {
        margin-bottom: .8rem;
        .provider-name {
          margin-right: .2rem;
          font-size: 1.2rem;
        }
        .stars {
          .star {
            font-size: .9rem;
          }
        }
        .advantages {
          margin-top: .3rem;
          .advantage i {
            flex-basis: 13px;
            font-size: .9rem;
          }
        }
      }
      .info-part {
        margin-bottom: 1.5rem;
      }
      .gallery-part {
        margin-bottom: 1.5rem;
      }
      .characteristic {
        margin-top: 1.5rem;
        .tile {
          margin-bottom: 1.5rem;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .stars {
          top: 0;
        }
      }
    }

    /* -info new */
  }
}

@media (max-width: 575px) {

  .prov-details {
    .info {
      .wrap {
        padding: .2rem 0;
      }
      h3 {
        font-size: 1.5rem;
        .stars {
          position: relative;
          top: 2px;
        }
      }
      h4 {
        font-size: 1.3rem;
      }
      .block-green .side-right ul li {
        .company {
          font-size: .8rem;
        }
      }
      .advantages .advantage {
        margin-right: 1rem;
        &:last-of-type {
          margin-right: 0;
        }
        i {
          flex: 0 0 18px;
          font-size: 1.3rem;
        }
      }
      .characteristic {
        .tile {
          width: 100% !important;
          margin-bottom: 1.2rem;
          padding: 0 !important;
          &-title {
            height: auto !important;
            h5 {
              margin-right: 1rem;
              font-size: 1.1em;
            }
            .wrap-line {
              justify-content: flex-start;
              height: auto;
            }
          }
          &-content {
            font-size: .9em;
          }
          .wrap-indicator {
            margin-bottom: .2rem;
          }
          .check-list {
            margin-top: 0;
          }
        }
      }
      .description {
        margin-bottom: .5rem;
      }
      .edit-block {
        padding: .5rem 0 0;
      }
    }

    /* odp */

    &.odp .container .container {
      padding: 0;
    }

    .odp {
      &-top {
        &:after {
          display: none;
        }
        .left-part {
          .wrap-img {
            margin-right: 1rem;
          }
          .wrap-content div {
            font-size: .8rem;
            line-height: 1.2;
          }
        }
        .right-part {
          .wrap-content {
            min-width: auto;
            max-width: none;
            margin-right: 1rem;
            .provider-rating_row {
              line-height: 1.2;
            }
          }
        }
      }

      &-tabs {
        margin: 0;
        .part {
          &-left {
            .price {
              width: 50%;
              padding: 0 1rem;
              font-size: 34px;
            }
            .btn {
              width: 50%;
              margin-left: .8rem;
              padding: 1px 1rem 1px .4rem;
              font-size: 1rem;
              line-height: 1.2;

              img {
                width: 35px;
              }
            }
          }

          &-right {
            .preview-text {
              font-size: 1rem;
            }
            .tabs {
              margin-top: 0;
            }
          }
        }
      }
    }

    &.odp-standard {
      .odp-tabs .part-left .btn {
        padding: .5rem 1rem;
      }
    }

    /* -odp */

    /* pep */

    &.pep {
      .info.info-new {
        .edit-block {
          padding-top: 0;
        }
        .edit-info .details {
          div {
            font-size: .85rem;
          }
          b {
            margin-top: 0;
          }
        }

        .list-block {
          p {
            font-size: .85rem;
          }
          a {
            font-size: .7rem;
          }
        }

        .comments-uploads-part .project-uploads {
          .img-ul-file-upload {
            justify-content: center;
            padding: .8rem;
          }
          .img-ul-drag-box-msg {
            margin-top: .3rem;
            margin-left: 0;
          }
        }

        .block-comments .action {
          padding: .8rem;
          justify-content: center;
          border: none;
          .btn {
            min-width: 95px;
            margin-right: 1rem;
          }
        }
      }
    }

    /* -pep */
  }

  .check-list-group {
    font-size: .9rem;
    h6 {
      font-size: 1rem;
    }
    .check-list li .icon {
      top: 1px;
      margin-right: 4px;
    }
  }
}

@media (max-width: 450px) {

  .prov-details {
    .block-green .wrap-border.side-left {
      font-size: 1.2rem;
    }

    /* pep */

    &.pep {
      .top-block {
        font-size: .8rem;
        .benefits {
          flex: 1 1;
        }
        .go-back {
          .btn {
            font-size: 1rem;
          }
        }
      }

      .info.info-new {
        .title-with-subtitle h4 {
          margin-bottom: .2rem;
        }
        .edit-info .actions .btn {
          margin-right: .5rem;
          padding-right: .6rem;
          padding-left: .6rem;
          font-size: .7rem;
        }

        .comments-uploads-part {
          .wrap-pictures,
          .wrap-textarea,
          .action {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    /* -pep */
  }
}

@media (max-width: 375px) {
  .prov-details {

    .title-with-subtitle span {
      font-size: .7rem;
    }

    /* odp */

    .odp-top {
      .left-part {
        .wrap-img {
          img {
            width: 135px;
          }
          b {
            font-size: 2rem;
          }
          &.selected {
            b {
              top: 13px;
              font-size: 9px;
            }
            .wrap-btn {
              top: 98px;
              .btn {
                font-size: 10px;
              }
            }
          }
        }
        .wrap-content {
          padding-top: 9px;
          .btn {
            font-size: 18px;
          }
          div {
            font-size: .75rem;
          }
        }
      }
    }

    /* -odp */

    /* pep */

    &.pep {
      .top-block {
        .go-back {
          width: 120px;
        }
      }
    }

    /* tabs */

    .tabs-menu .tab {
      padding: .2rem .3rem;
      .wrap-img img {
        height: 23px;
        margin-right: .5rem;
      }
    }

    /* -tabs */
  }
}
