@import "../variables";

.article {
  .container {
    & > ul,
    & > ol {
      position: relative;
      left: 2rem;
      padding-left: 0;
    }
  }
  h1,
  h2,
  h3 {
    word-break: break-word;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  blockquote {
    padding: 1rem 1rem 0.5rem;
    border-left: 3px solid $blue-dark;
    p {
      margin-bottom: 0.5rem;
    }
  }
  .clearfix {
    margin-top: 2rem;
  }

  .general-slider,
  .image {
    display: flex;
    justify-content: center;
    margin: 0.5rem auto 1rem;
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
    .wrap {
      width: 50%;
    }
  }

  .image {
    img {
      width: 50%;
    }
  }
}

@media (max-width: 991px) {
  .article .general-slider .wrap,
  .article .image img {
    width: 75%;
  }
}

@media (max-width: 767px) {
  .article {
    .general-slider .wrap,
    .image img {
      width: 100%;
    }
  }
}
