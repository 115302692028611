.unsubscribe {
  margin: 8rem 0;
  text-align: center;
  .actions {
    margin: 1rem 0 2rem;
    a {
      width: 200px;
      margin: .5rem;
    }
  }
}

@media (max-width: 450px) {

  .unsubscribe {
    margin: 4rem 0;
  }
}