@import "../variables";

/* Compare providers */

.compare-providers {
  color: $blue-dark;
  .title {
    text-align: center;
  }

  .wrap_compare-prov-table {
    margin-bottom: 4rem;
  }

  .compare-prov-table {
    .Ftable-row {
      &.Ftable-header {
        .Ftable-cell {
          padding-bottom: 0;
        }
      }
    }
    .Ftable-cell {
      position: relative;
      width: 13%;
      padding: 1.5rem .8rem;
      .wrap-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
      }
      .price {
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 100%;
        font-size: .9em;
        text-align: center;
      }
    }
    .cell-company_name,
    .cell-tips {
      text-align: left;
      a {
        color: $blue-dark;
      }
    }
    .cell-company_name {
      width: 20%;
    }
    .cell-tips {
      width: 28%;
    }
    .cell-company_name {
      a {
        font-weight: $F-bold;
      }
    }
  }
}

.cell-image {
  height: 32px;
  width: 32px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    cursor: pointer;
    filter: invert(.2) sepia(1) saturate(30) hue-rotate(175deg);
  }
}

/* -Compare providers */

/* Icons */

.cell-online_offer {
  .cell-image {
    background-image: url("../../../assets/img/icon-file.svg");
  }
  &.active {
    .cell-image {
      background-image: url("../../../assets/img/icon-file-green.svg");
    }
  }
}

.cell-contact {
  .cell-image {
    background-image: url("../../../assets/img/icon-phone.svg");
  }
  &.active {
    .cell-image {
      background-image: url("../../../assets/img/icon-phone-green.svg");
    }
  }
}

.cell-inspect_property {
  .cell-image {
    background-image: url("../../../assets/img/icon-house.svg");
  }
  &.active {
    .cell-image {
      background-image: url("../../../assets/img/icon-house-green.svg");
    }
  }
}

.cell-final_offer {
  .cell-image {
    background-image: url("../../../assets/img/icon-offer.svg");
  }
  &.active {
    .cell-image {
      background-image: url("../../../assets/img/icon-offer-green.svg");
    }
  }
}

.cell-contract_signed {
  .cell-image {
    background-image: url("../../../assets/img/icon-contract.svg");
  }
  &.active {
    .cell-image {
      background-image: url("../../../assets/img/icon-contract-green.svg");
    }
  }
}

.cell-work_started {
  .cell-image {
    background-image: url("../../../assets/img/icon-work_started.svg");
  }
  &.active {
    .cell-image {
      background-image: url("../../../assets/img/icon-work_started-green.svg");
    }
  }
}

.cell-work_completed {
  .cell-image {
    background-image: url("../../../assets/img/icon-work_completed.svg");
  }
  &.active {
    .cell-image {
      background-image: url("../../../assets/img/icon-work_completed-green.svg");
    }
  }
}

.feedback-value.active {
  .cell-image {
    width: 38px;
    height: 38px;
  }
}

.feedback-value {
  .text {
    display: inline-block;
    padding: .2rem 0;
    cursor: pointer;
    &:hover {
      color: $blue-light;
    }
  }
  .cell-image {
    width: 38px;
    height: 38px;
  }
  &.active {
    .cell-image {
      filter: invert(0.5) sepia(1) saturate(40) hue-rotate(80deg);
      &:hover {
        filter: invert(0.5) sepia(1) saturate(40) hue-rotate(80deg);
      }
    }
    .text {
      color: #1bb700;
      font-weight: $F-bold;
    }
  }
  &.gray {
    .cell-image {
      opacity: .5;
      &:hover {
        filter: invert(0.5) sepia(1) saturate(40) hue-rotate(80deg);
      }
    }
    .text {
      opacity: .6;
    }
  }
  &_mobile {
    display: none;
  }
}

.cell-feedback-sad {
  .cell-image {
    background-image: url("../../../assets/img/icon-feedback-sad.svg");
  }
}

.cell-feedback-neutral {
  .cell-image {
    background-image: url("../../../assets/img/icon-feedback-neutral.svg");
  }
}

.cell-feedback-smile {
  .cell-image {
    background-image: url("../../../assets/img/icon-feedback-smile.svg");
  }
}

.cell-feedback-happy {
  .cell-image {
    background-image: url("../../../assets/img/icon-feedback-happy.svg");
  }
}

/* -Icons */

/* Score providers */

.score-providers {
  margin-bottom: 5rem;
  color: $black-blue;
  .wrap-feedback-content {
    max-width: 680px;
    margin: 0 auto;
  }
  .content-block {
    margin-bottom: 2.5rem;
    h5 {
      margin-bottom: 1.5rem;
    }
  }
  &.review {
    margin-top: 2.5rem;
    &.green {
      color: $green;
      font-size: .9rem;
      a {
        color: $green;
      }
    }
    .count-progress {
      padding-top: .5rem;
    }
    .wrap-text {
      margin: 2rem 0;
    }
    p {
      margin: 1.5rem 0;
      font-weight: $F-bold;
    }
    form {
      margin-top: 2rem;
      label {
        margin-bottom: 2px;
        font-size: .95em;
      }
      .form-control {
        height: 48px;
        padding: .7rem;
        font-size: 1rem;
        line-height: 48px;
      }
      .wrap-select {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          right: 12px;
          top: 50%;
          margin-top: -3px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid $gray-medium;
        }
      }
      .select-custom {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0 .7rem;
        line-height: 1;
        option {
          &:first-of-type {
            color: $green;
          }
        }
        &:not(:focus):invalid {
          color: #c2c2c2;
        }
      }
      .form-check {
        margin-top: 1rem;
      }
      .forgot-password-link {
        display: inline-block;
        margin-top: 5px;
        font-size: .85em;
      }
    }
    .btn-green {
      margin-top: 1.5rem;
    }
  }
}

.company-tabs {
  margin-bottom: 2.5rem;
  border-bottom-color: $green;
  .nav-item {
    width: 33.333%;
    text-align: center;
    .nav-link {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 1rem;
      color: $black-blue;
      font-weight: $F-bold;
      &.active {
        background-color: $green-light-extra;
        border-color: $green $green $white;
        text-decoration: none;
        &:after {
          display: none;
        }
        &:hover {
          border-color: $green $green $white;
        }
      }
      &:after {
        content: '';
        position: absolute;
        right: -2px;
        top: 27%;
        height: 46%;
        width: 1px;
        background-color: $blue-gray-dark;
      }
      &:hover {
        border-color: transparent;
      }
    }
    &:nth-of-type(3) {
      .nav-link {
        &:after {
          display: none;
        }
      }
    }
  }
}

.score-value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 310px;
  min-height: 53px;
  margin: 0 auto 1rem;
  background-color: $green;
  color: $white;
  line-height: 1.3;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  div {
    padding: .4rem 1.5rem;
  }
  .title {
    font-size: 1.2em;
    font-weight: $F-bold;
  }
  .value {
    font-size: 1.2em;
    font-weight: $F-bold;
    &.not-ready {
      min-height: 53px;
      background-color: $gray-medium;
      font-size: 1rem;
      font-weight: $F-regular;
    }
    .number {
      font-size: 1.6em;
    }
  }
}

.count-progress.content-block {
  margin-bottom: .5rem;
  .cells {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1.3;
    .cell {
      width: calc(100% / 7);
      min-width: 90px;
      margin-bottom: 1rem;
      padding: 0 .2rem;
      text-align: center;
      &.active {
        .cell-image {
          background-size: 50px 50px;
          &:hover {
            filter: none;
          }
        }
      }
      .cell-image {
        width: 50px;
        height: 50px;
        margin: 1rem auto;
        background-size: 32px 32px;
      }
    }
  }
}

.score-company-table {
  font-size: .9rem;
  .Ftable-row {
    flex-wrap: wrap;
    &.Ftable-header {
      .Ftable-cell {
        padding-top: .1rem;
        padding-bottom: .1rem;
        font-size: .95em;
      }
    }
    .Ftable-cell {
      width: 13%;
      padding: 1rem .2rem;
      &:first-of-type {
        width: 28%;
        padding-left: .6rem;
        text-align: left;
      }
      &:last-of-type {
        width: 15%;
        padding-right: .6rem;
        font-size: .9em;
      }
      .mobile-header {
        display: none;
      }
    }
  }
}

.more-feedback {
  .form-group {
    display: flex;
    margin-bottom: 1.5rem;
    font-size: .9rem;
    label {
      flex: 0 0 200px;
      margin-right: 1.5rem;
    }
    textarea {
      min-height: 70px;
      font-size: .9rem;
      resize: none;
    }
  }
}

/* -Score providers */

@media (max-width: 767px) {

  /* Compare providers */

  .compare-providers .compare-prov-table {
    width: 90%;
    margin: 0 auto;
    .Ftable-row {
      flex-wrap: wrap;
      background-image: linear-gradient(to right, $green 20%, $white 10%);
      background-position: bottom;
      background-size: 5px 1px;
      background-repeat: repeat-x;
      &.Ftable-header {
        .Ftable-cell {
          padding-bottom: .5rem;
        }
        span {
          font-weight: $F-bold;
        }
        .cell-tips {
          display: none;
        }
      }
    }
    .Ftable-cell {
      width: 18%;
      padding: .5rem;
      .price {
        display: none;
      }
    }
    .cell-company_name {
      width: 28%;
    }
    .cell-tips {
      width: 100%;
      text-align: center;
    }
  }

  /* -Compare providers */
}

@media (max-width: 767px) {

  /* Compare providers */

  .compare-providers {
    .title {
      padding: 0 1rem;
      p {
        line-height: 1.3;
      }
    }
    .wrap_compare-prov-table {
      margin-bottom: 3rem;
    }
    .compare-prov-table {
      .Ftable-header {
        .cell-company_name {
          display: none;
        }
      }
      .Ftable-cell {
        width: 25%;
        &.cell-company_name {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  /* -Compare providers */

  /* Score providers */

  .score-company-table .Ftable-row .Ftable-cell {
    width: 12%;
    &:last-of-type {
      width: 20%;
    }
  }

  /* -Score providers */

  /* Score providers */

  .score-providers {
    &.review {
      margin-top: 1.5rem;
    }
  }

  /* -Score providers */
}

@media (max-width: 575px) {

  /* Compare providers */

  .compare-providers {
    .title {
      p {
        font-size: .9rem;
      }
    }
    .wrap_compare-prov-table {
      margin-bottom: 2rem;
    }
    .compare-prov-table {
      width: 100%;
      .Ftable-header {
        font-size: .85em;
      }
      .cell-tips {
        font-size: .95em;
      }
    }
  }

  /* -Compare providers */

  /* Score providers */

  .score-providers {
    margin-bottom: 3rem;
    padding: 0;
    &.review {
      margin-top: 1rem;
      form {
        margin-top: 1rem;
        .form-group {
          margin-bottom: .5rem;
        }
        label {
          margin-bottom: 0;
        }
        .form-check {
          margin-top: .8rem;
        }
      }
      .wrap-text {
        margin: 0;
        text-align: center;
        p {
          margin: 1rem 0;
        }
      }
      .btn-green {
        margin-top: 1rem;
        font-size: 1.2rem;
      }
    }
    .nav-item {
      font-size: .9em;
      .nav-link {
        padding: .7rem .3rem;
      }
    }

    .content-block {
      margin-bottom: 1.8rem;
      padding: 0 .7rem;
      &.count-progress {
        margin-bottom: 1rem;
      }
      h5 {
        margin-bottom: 1rem;
        font-size: 1.15rem;
      }
    }

    .action {
      padding: 0 .7rem;
      .btn {
        padding: .5rem 1rem;
        font-size: 1.2rem;
      }
    }
  }

  .company-tabs {
    margin-bottom: 1.8rem;
  }

  .count-progress.content-block .cells .cell {
    margin-bottom: .8rem;
    width: calc(100% / 4);
    min-width: 80px;
    font-size: .9rem;
    .cell-image {
      margin: .6rem auto;
    }
  }

  .score-company-table .Ftable-row {
    &.Ftable-header {
      display: none;
    }
    .Ftable-cell {
      width: 25%;
      padding: .8rem .2rem;
      &:first-of-type {
        width: 100%;
        padding-bottom: 0;
        font-size: 1.1rem;
        font-weight: $F-bold;
        .text {
          margin-right: 1rem;
        }
      }
      &:last-of-type {
        display: none;
      }
      .mobile-header {
        display: inline-block;
        margin-bottom: .3rem;
        font-size: .9em;
      }
      .feedback-value_mobile {
        display: inline-flex;
        margin: 0;
        font-size: .7rem;
        font-weight: $F-regular;
        border-bottom: 2px solid $black-blue;
      }
      &.active {
        .feedback-value_mobile {
          border-bottom-color: #1bb700;
        }
      }
      &.gray {
        .feedback-value_mobile {
          border-bottom-color: rgba($black-blue, .6);
        }
      }
    }
  }

  .more-feedback .form-group {
    flex-direction: column;
    margin-bottom: 1rem;
    label {
      flex: 0 0 auto;
      font-weight: $F-bold;
    }
  }

  .continue-working .score-company-table {
    .Ftable-header {
      display: flex;
    }
    .Ftable-cell {
      padding: .5rem 0;
      &:first-of-type {
        display: none;
      }
    }
  }

  /* -Score providers */
}

@media (max-width: 340px) {

  /* Score providers */

  .score-value {
    width: 100%;
    div {
      padding: .4rem 1rem;
    }
  }

  /* -Score providers */
}
