@import "variables";

.btn {
  font-weight: $F_bold;
  border-radius: 7px;
  box-shadow: none;
  cursor: pointer;
  text-decoration: none !important;
  overflow: hidden;
  &-no_vert_paddings {
    padding: 0 .8rem;
  }
  &-small-extra {
    padding: 0.125rem 0.9rem;
  }
  &-small {
    padding: .3125rem 1rem;
  }
  &-middle {
    padding: .5rem 1.8rem;
    font-size: 1.2rem;
  }
  &-large {
    padding: .75rem 3.5rem;
    font-size: 1.3rem;
  }
  &-large-extra {
    padding: 1rem 5rem;
    font-size: 1.4rem;
  }
  &:not(:disabled):hover {
    opacity: 0.8;
  }
}

.btn-green {
  color: $white !important;
  background: $green;
  box-shadow: none;
  &:hover {
    color: $white;
  }
}

.btn-white {
  color: $blue-light !important;
  background: $white;
}

.btn-white-black {
  color: $black !important;
  background-color: $white;
  border-color: $black;
}

.btn-white-green {
  color: $green !important;
  background: $white;
  border-color: $green;
  box-shadow: none;
}

.btn-white-blue_dark {
  color: $black-blue !important;
  background: $white;
  border-color: $black-blue;
  box-shadow: none;
}

.btn-white-red_orange {
  color: #e5894b !important;
  background: $white;
  border-color: #f5d0b8 !important;
  box-shadow: none;
}

.btn-blue,
.btn-blue-light,
.btn-blue-dark {
  color: $white !important;
  background: $blue;
  &:hover,
  &:focus {
    color: $white !important;
    outline: none;
  }
}

.btn-blue-light {
  background: $blue-light;
}

.btn-blue-dark {
  background: $blue-dark;
}

.btn-bronze {
  color: $white !important;
  background: $bronze;
  border-color: $white;
  box-shadow: none !important;
  &:hover,
  &:focus {
    color: $white;
  }
}

.btn-yellow {
  color: $white !important;
  background: $yellow-dark;
  border-color: $white;
  box-shadow: none !important;
  &:hover,
  &:focus {
    color: $white;
  }
  &.font-dark {
    color: $blue-gray !important;
    &:hover,
    &:focus {
      color: $blue-gray !important;
    }
  }
}

.btn-yellow-bronze {
  color: $black !important;
  background: $yellow-dark;
  border-color: $bronze;
  box-shadow: none !important;
  &:hover,
  &:focus {
    color: $black;
  }
}

.btn[class*="btn-transparent-"] {
  background: transparent;
  box-shadow: none;
}

.btn-transparent-white {
  color: $white !important;
  border: 2px solid $white;
  &:hover {
    color: $white;
  }
}

.btn-transparent-blue {
  color: $blue !important;
  border: 2px solid $blue;
}

.btn-transparent-bronze {
  color: $brown !important;
  border-color: $bronze;
}

.btn-transparent-brown {
  color: $brown !important;
  border-color: $brown;
}

.btn-transparent-red {
  color: $red !important;
  border: 2px solid $red;
}
