@import "../variables";

.top-banner_renoidea {
  background-image: url("../../../assets/img/top-banner-renoideas.jpg");
  .bottom-part {
    background-color: $white;
  }
}

.search-field {
  max-width: 550px;
  margin: 5rem auto 3rem;
  text-align: center;
  label {
    margin-bottom: 0.1rem;
    font-size: 1.7em;
    font-weight: $F-bold;
  }
  input {
    margin-bottom: 0.7rem;
    font-size: 1.2em;
    border-radius: 3rem;
  }
}

.wrap-input-with-button {
  position: relative;
  input {
    height: 44px;
    padding-left: 1.2rem;
    padding-right: 5.5rem;
  }
  .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    height: 40px;
    padding: 0 1.7rem;
    font-size: 1.5em;
    border-radius: 0 3rem 3rem 0;
  }
}

.tile-search_tips {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 1rem;
  text-align: center;
  li {
    margin: 0 0.5rem;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0.3rem 1rem;
      color: $blue-dark !important;
      font-size: 1.1rem;
      line-height: 1.4;
      border: 2px solid $yellow-dark;
      text-decoration: none !important;
      &:hover {
        background-color: $gray-light;
      }
    }
  }
}

.articles {
  padding: 2rem 0;
  h4,
  p {
    color: $gray-blue;
  }
  h4 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 57px;
    font-weight: $F-medium;
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px;
    margin-bottom: 0.7rem;
    font-size: 0.9em;
    line-height: 1.4;
    text-align: justify;
  }

  %parent-centered-slider-image {
    text-align: center;
    overflow: hidden;
  }

  %centered-slider-image {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 100%;
  }

  .wrap-images {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 250px;
    .bigger-img,
    .smaller-img .wrap {
      @extend %parent-centered-slider-image;
      img {
        @extend %centered-slider-image;
      }
    }
    .bigger-img {
      flex: 1 1;
      margin-right: 4px;
      img {
        min-height: 250px;
      }
    }
    .smaller-img {
      width: 30%;
      .wrap:first-child:nth-last-child(1) {
        height: 250px;
        img {
          min-height: 250px;
        }
      }
      .wrap:first-child:nth-last-child(2),
      .wrap:first-child:nth-last-child(2) ~ .wrap {
        height: 125px;
        img {
          min-height: 125px;
        }
      }
      .wrap:first-of-type {
        padding-bottom: 4px;
      }
      .wrap:last-of-type {
        padding-top: 4px;
      }
    }
  }

  .images-block {
    margin-bottom: 2.5rem;
  }

  .action {
    position: absolute;
    bottom: 0.8rem;
    right: 0.8rem;
    .btn {
      background: rgba($black, 0.5);
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 1199px) {
  .search-field {
    margin-bottom: 0;
  }

  .top-banner_renoidea .bottom-part {
    background-color: transparent;
  }

  .tile-search_tips {
    flex-wrap: wrap;
    padding: 0.8rem;
    li {
      width: 32.5%;
      margin: 0 0 1.2%;
      a {
        font-size: 1rem;
        background-color: rgba($white, 0.9);
      }
    }
  }
}

@media (max-width: 991px) {
  .tile-search_tips li a {
    padding: 0.3rem 0.5rem;
  }
}

@media (max-width: 767px) {
  .search-field {
    margin-top: 3rem;
  }

  .articles {
    padding: 1rem 0;
    background-color: $blue-gray-light;
    h4,
    p {
      display: block;
      height: auto;
    }
  }
}

@media (max-width: 575px) {
  .top-banner_renoidea .top-part {
    padding-bottom: 1rem;
    .wrap {
      padding: 0.5rem 0;
      background-color: rgba($yellow-dark, 0.9);
      & > .container {
        padding: 0 0.5rem;
      }
      .title-strip-yellow {
        background-color: transparent;
        h3 {
          font-size: 1.3rem;
        }
      }
    }
  }

  .search-field {
    margin: 0.7rem 0 0;
    .form-group {
      margin-bottom: 0.5rem;
    }
    label {
      font-size: 1.2rem;
    }
    input {
      margin-bottom: 0.5rem;
    }
  }

  .wrap-input-with-button {
    input {
      height: 40px;
      padding-right: 4.5rem;
      font-size: 1rem;
    }
    .btn {
      height: 36px;
      padding: 0 1rem;
      font-size: 1.4rem;
    }
  }

  .tile-search_tips {
    padding: 0.5rem 0.5rem 1rem;
    li a {
      padding: 0.3rem;
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 375px) {
  .top-banner_renoidea .top-part .wrap .title-strip-yellow h3 {
    font-size: 1.2rem;
  }

  .search-field label {
    font-size: 1.1rem;
  }

  .articles {
    padding: 0;
    .container {
      padding: 0.5rem;
    }
  }

  .tile-search_tips li a {
    padding: 0.2rem 0.3rem;
    font-size: 0.8rem;
  }
}
